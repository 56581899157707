<template>
  <v-dialog max-width="600px" v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn depressed fab text small v-on="on" @click="showDialog">
        <v-icon medium color="info">mdi-plus-thick</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <h2>{{ title }}</h2>
          <v-btn
          small
          text
          fab
          color="grey darken-1"
          @click="dialog = false"
          class="close-btn"
        >
          <v-icon medium>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form class="px-0 mt-2" ref="form" enctype="multipart/form-data">
          <v-textarea
            v-model="note"
            outlined           
            rows="2"
            label="Note"
          ></v-textarea>
          <v-spacer></v-spacer>
          <v-btn
            text
            class="primary mx-0 mt-0"
            @click="submit()"
            :loading="loading"
            >{{ btnLabel }}</v-btn
          >
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import EventBus from "@/eventBus";
import axios from "axios";
const BASE_URL = process.env.VUE_APP_API_BASE_URL;
export default {
  props: ["projectId"],
  data() {
    return {
      note: "",
      desc: "",
      deadlineDate: "",
      userInfo: null,
      title: "Add New Note",
      btnLabel: "Add Note",
      attachmentName: "",
      id: 0,
      inputRules: [
        (v) => (v && v.length >= 3) || "Minimum length is 3 characters",
      ],
      loading: false,
      dialog: false,
      action: "get",
      categoryList: [],
      defaultCategory: {},
      startDate: new Date().toISOString().substr(0, 10),
      deadline: new Date().toISOString().substr(0, 10),
      startDateMenu: false,
      finishDateMenu: false,
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        const vm = this;

        var formData = new FormData();
        formData.append("note", vm.note);
        formData.append("managerId", vm.userInfo.id);
        formData.append("projectId", vm.projectId);

        if (vm.action == "update") {
          console.log("Language Id:" + vm.id);
          formData.append("id", vm.id);
        }
        axios
          .post(BASE_URL + "manager/note.php?action=" + vm.action, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function(response) {
            vm.loading = false;
            console.log(response);
            vm.message = response.data.message;
            EventBus.$emit("display-message", vm.message);
            if (!response.data.error) {
              if (vm.action == "update") {
                EventBus.$emit("note-update", vm.error);
              } else {
                EventBus.$emit("note-added", vm.error);
              }
            }
            vm.dialog = false;
            // vm.note = "";
            vm.$refs.form.reset();
          })
          .catch(function(error) {
            vm.loading = false;
            console.log(error);
            EventBus.$emit("display-message", "Some error occured!");
          });
      }
    },
    uploadFiles() {},
    showDialog() {
      console.log("Project Id:" + this.projectId);

      this.btnLabel = "Add Note";
      this.note = "";
      //this.dialog = true;
      this.action = "add";
      this.title = "Add New Note";
      this.$refs.form.reset();
    },
  },
  computed: {},
  mounted() {
    this.action = "add";
    EventBus.$on("edit-note", (data) => {
      this.note = data.note;
      this.id = data.id;
      this.btnLabel = "Update Note";
      this.dialog = true;
      this.action = "update";
      this.title = "Update Note";
    });
  },
  created() {
    this.userInfo = this.$store.state.userlogged;
    console.log("Project Id 2:" + this.projectId);
  },
};
</script>

<style scoped>
.close-btn {
  position: absolute;
  right: 10px;
}
</style>
