import Vue from 'vue'
import router from './router'
import store from './store'
export default new Vue({
    data() {
        return {
            auth: "login hai",
            authenticated: false,
            routePath: '',
            storedData: null
        }
    },
    mounted() {

    },
    created() {

        console.log("auth creatd");

    },
    methods: {
        isLoogedIn() {
            this.routePath = router.currentRoute.path;
            this.routeName = router.currentRoute.name;
            this.storedData = store.state;
            if (this.storedData.userlogged != null) {
                const today = new Date();
                const lastLogin = new Date(this.storedData.dateTime);
                var timeDiff = Math.abs(today.getTime() - lastLogin.getTime());
                var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
                console.log("DaysDiff:", diffDays, "userId:", this.storedData.userlogged);

                if (this.storedData.userlogged.id > 0 && diffDays == 1) {
                    if (
                        this.routePath.includes("/client") &&
                        this.storedData.userlogged.type == 3
                    ) {
                        this.authenticated = true;
                    } else if (
                        this.routePath.includes("/manager") &&
                        this.storedData.userlogged.type == 1
                    ) {
                        this.authenticated = true;
                    } else if (
                        this.routePath.includes("/team") &&
                        this.storedData.userlogged.type == 2
                    ) {
                        this.authenticated = true;
                    } else if (
                        this.routeName == "homePage"
                    ) {
                        this.authenticated = true;
                    }
                } else {
                    this.authenticated = false;
                }

                console.log(
                    "Is Authticated: ",
                    this.authenticated,
                    ",logged Id:",
                    this.storedData.userlogged.id,
                    ", Route path:",
                    this.routePath
                );
            }
            return this.authenticated;
        }
    },
});