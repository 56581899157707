<template>
  <v-dialog max-width="600px" v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn depressed fab text small v-on="on" @click="showDialog">
        <v-icon medium color="info">mdi-plus-thick</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <h2>{{ title }}</h2>
        <v-btn
          small
          text
          fab
          color="grey darken-1"
          @click="dialog = false"
          class="close-btn"
        >
          <v-icon medium>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form class="px-3" ref="form" enctype="multipart/form-data">
          <v-text-field
            label="Title"
            v-model="name"
            prepend-icon="mdi-format-title"
            :rules="inputRules"
          ></v-text-field>
          <v-textarea
            v-model="desc"
            prepend-icon="mdi-card-text-outline"
            rows="2"
            label="Description"
          ></v-textarea>
          <v-select
            :items="categoryList"
            v-model="defaultCategory"
            item-text="label"
            item-value="id"
            label="Select Category"
            prepend-icon="mdi-shape"
            return-object
          ></v-select>
          <v-row no-gutters>
            <v-col cols="12" lg="6">
              <v-menu
                ref="startDateMenu"
                v-model="startDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="startDate"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="startDate"
                    label="Start Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="startDate" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="startDateMenu = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.startDateMenu.save(startDate)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" lg="6">
              <v-menu
                ref="finishDateMenu"
                v-model="finishDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="deadline"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="deadline"
                    label="Deadline"
                    prepend-icon="mdi-calendar-check-outline"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="deadline" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="finishDateMenu = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.finishDateMenu.save(deadline)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-spacer></v-spacer>
          <v-btn
            text
            class="primary mx-0 mt-3"
            @click="submit()"
            :loading="loading"
            >{{ btnLabel }}</v-btn
          >
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import EventBus from "@/eventBus";
import axios from "axios";
const BASE_URL = process.env.VUE_APP_API_BASE_URL;
export default {
  props: ["projectId"],
  data() {
    return {
      name: "",
      desc: "",
      deadlineDate: "",
      userInfo: null,
      title: "Add New Plan",
      btnLabel: "Add Plan",
      attachmentName: "",
      id: 0,
      inputRules: [
        (v) => (v && v.length >= 3) || "Minimum length is 3 characters",
      ],
      loading: false,
      dialog: false,
      action: "get",
      categoryList: [],
      defaultCategory: {},
      startDate: new Date().toISOString().substr(0, 10),
      deadline: new Date().toISOString().substr(0, 10),
      startDateMenu: false,
      finishDateMenu: false,
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        const vm = this;
        console.log("Client Id " + vm.userInfo.id);
        var formData = new FormData();
        formData.append("title", vm.name);
        formData.append("detail", vm.desc);
        formData.append("wbsCatgId", vm.defaultCategory.id);
        formData.append("startDate", vm.startDate);
        formData.append("deadline", vm.deadline);
        formData.append("managerId", vm.userInfo.id);
        formData.append("projectId", vm.projectId);

        if (vm.action == "update") {
          console.log("Language Id:" + vm.id);
          formData.append("id", vm.id);
        }
        axios
          .post(BASE_URL + "manager/plan.php?action=" + vm.action, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function (response) {
            vm.loading = false;
            console.log(response);
            vm.message = response.data.message;
            EventBus.$emit("display-message", vm.message);
            if (!response.data.error) {
              if (vm.action == "update") {
                EventBus.$emit("plan-update", vm.error);
              } else {
                EventBus.$emit("plan-added", vm.error);
              }
            }
            vm.dialog = false;
            // vm.name = "";
            vm.$refs.form.reset();
          })
          .catch(function (error) {
            vm.loading = false;
            console.log(error);
            EventBus.$emit("display-message", "Some error occured!");
          });
      }
    },
    loadCategories(action) {
      console.log("Loading catgories");

      const vm = this;
      var formData = new FormData();
      // formData.append("ran", Math.random() * 129);
      if (action == "update") {
        formData.append("id", vm.id);
      }
      axios
        .post(BASE_URL + "manager/plan.php?action=" + action, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          vm.loading = false;
          console.log(response);
          vm.message = response.data.message;
          // EventBus.$emit("display-message", vm.message);
          if (!response.data.error) {
            if (vm.action == "update") {
              EventBus.$emit("plan-update", vm.error);
            } else {
              EventBus.$emit("plan-added", vm.error);
            }
            vm.categoryList = response.data.list;
            if (vm.categoryList.length > 0) {
              vm.defaultCategory = response.data.list[0];
            }
          }
          vm.dialog = false;
        })
        .catch(function (error) {
          vm.loading = false;
          console.log(error);
          EventBus.$emit("display-message", "Some error occured!");
        });
    },
    uploadFiles() {},
    showDialog() {
      console.log("Project Id:" + this.projectId);

      this.btnLabel = "Add Plan";
      this.name = "";
      //this.dialog = true;
      this.action = "add";
      this.title = "Add New Plan";
      this.$refs.form.reset();
    },
    pickFile() {
      this.$refs.insFiles.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      console.log(files[0].name);
      if (files[0] !== undefined) {
        this.attachmentName = files[0].name;
        if (this.attachmentName.lastIndexOf(".") <= 0) {
          return;
        }
      }
    },
  },
  computed: {},
  mounted() {
    this.action = "add";
    EventBus.$on("edit-plan", (data) => {
      this.name = data.title;
      this.desc = data.desc;
      this.startDate = data.startDate;
      this.deadline = data.dueDate;
      this.defaultCategory = data.category;
      this.id = data.id;
      this.btnLabel = "Update Plan";
      this.dialog = true;
      this.action = "update";
      this.title = "Update Plan";
    }); 
  },
  created() {
    this.userInfo = this.$store.state.userlogged;
    this.loadCategories("setting");
    console.log("Project Id 2:" + this.projectId);
  },
};
</script>
<style scoped>
.close-btn {
  position: absolute;
  right: 10px;
}
</style>
