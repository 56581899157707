<template>
  <v-dialog max-width="600px" v-model="dialog">
    <v-card>
      <v-card-title class="text-center">
        <h2>{{ title }}</h2>
        <v-btn small text fab color="grey darken-1" @click="closeDialog()" class="close-btn">
          <v-icon medium>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="text-center pt-3">
        <v-form class="px-1" ref="form" enctype="multipart/form-data">
          <v-rating v-model="rating" hover background-color="black lighten-3" color="orange" size="32"></v-rating>
          <v-textarea v-model="desc" outlined rows="2" label="Feedback"></v-textarea>
          <v-spacer></v-spacer>
          <v-btn text class="primary mx-0 mt-3" @click="submit()" :loading="loading">{{ btnLabel }}</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import EventBus from "@/eventBus";
import axios from "axios";
const BASE_URL = process.env.VUE_APP_API_BASE_URL;
export default {
  data() {
    return {
      name: "",
      desc: "",
      deadlineDate: "",
      userInfo: null,
      title: "Your Feedback",
      btnLabel: "Submit",
      attachmentName: "",
      id: 0,
      inputRules: [
        (v) => (v && v.length >= 3) || "Minimum length is 3 characters",
      ],
      loading: false,
      action: "get",
      rating: 3,
      dialog: false,
      isVisibleTextarea: false,
    };
  },
  methods: {
    closeDialog() {
      // EventBus.$emit("hide-client-feedback", true);
      this.dialog = false;
    },

    submit() {
      if (this.$refs.form.validate()) {
        const vm = this;
        console.log("Client Id " + vm.userInfo.id);
        var formData = new FormData();
        formData.append("feedback", vm.desc);
        formData.append("clientId", vm.userInfo.id);
        formData.append("rating", vm.rating);
        axios
          .post(
            BASE_URL + "client/feedback.php?action=" + vm.action,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(function (response) {
            vm.loading = false;
            console.log(response);
            vm.message = response.data.message;
            EventBus.$emit("display-message", vm.message);

            vm.$refs.form.reset();
            if (!response.data.error) {
              vm.dialog = false;
            }
          })
          .catch(function (error) {
            vm.loading = false;
            console.log(error);
            EventBus.$emit("display-message", "Some error occured!");
          });
      }

    },
    uploadFiles() { },
    showDialog() {
      console.log("Project Id:" + this.projectId);

      this.btnLabel = "Submit";
      this.name = "";
      //this.dialog = true;
      this.action = "add";
      this.title = "Your Feedback";
      this.$refs.form.reset();
    },
    showTextArea() {
      this.isVisibleTextarea = true;
    },
  },
  computed: {},
  mounted() {
    this.action = "add";
    var index = 0;
    EventBus.$on("show-client-feedback", (error) => {
      index++;
      console.log("Show Feedback:" + index);
      this.dialog = true;
      console.log(error);
    });
  },
  created() {
    this.userInfo = this.$store.state.userlogged;
    console.log("Project Id:" + this.projectId);
  },
};
</script>
<style scoped>
.close-btn {
  position: absolute;
  right: 10px;
}
</style>
