<template>
  <v-dialog max-width="600px" v-model="dialog">
    <v-card>
      <v-card-title>
        <h2>{{ title }}</h2>
      </v-card-title>
      <v-card-text>
        <v-form class="px-3" ref="form" enctype="multipart/form-data">
          <v-select
            :items="teamList"
            v-model="defaultTeamMember"
            item-text="name"
            item-value="id"
            label="Assign To"
            prepend-icon="mdi-clipboard-account"
            return-object
          ></v-select>
          <v-spacer></v-spacer>
          <v-btn text class="primary mx-0 mt-3" @click="submit()" :loading="loading">{{ btnLabel }}</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import EventBus from "@/eventBus";
import axios from "axios";
const BASE_URL = process.env.VUE_APP_API_BASE_URL;
export default {
  props: ["projectId"],
  data() {
    return {
      userInfo: null,
      title: "Assign Bug To",
      btnLabel: "Assign Bug",
      id: 0,
      inputRules: [
        v => (v && v.length >= 3) || "Minimum length is 3 characters"
      ],
      loading: false,
      dialog: false,
      action: "get",
      teamList: [],
      defaultTeamMember: {}
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        const vm = this;
        console.log("Client Id " + vm.userInfo.id);
        var formData = new FormData();
        formData.append("teamMemberId", vm.defaultTeamMember.id);
        formData.append("id", vm.id);

        axios
          .post(BASE_URL + "manager/bug.php?action=" + vm.action, formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(function(response) {
            vm.loading = false;
            console.log(response);
            vm.message = response.data.message;
            EventBus.$emit("display-message", vm.message);
            if (!response.data.error) {
              if (vm.action == "assignTo") {
                EventBus.$emit("bug-update", vm.error);
              } else {
                EventBus.$emit("bug-added", vm.error);
              }
            }
            vm.dialog = false;
            // vm.name = "";
            vm.$refs.form.reset();
          })
          .catch(function(error) {
            vm.loading = false;
            console.log(error);
            EventBus.$emit("display-message", "Some error occured!");
          });
      }
    },
    uploadFiles() {},
    showDialog() {
      console.log("Project Id:" + this.projectId);
      this.btnLabel = "Assign Bug";
      this.name = "";
      //this.dialog = true;
      this.action = "add";
      this.title = "Assign Bug To";
      this.$refs.form.reset();
    },
    pickFile() {
      this.$refs.insFiles.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      console.log(files[0].name);
      if (files[0] !== undefined) {
        this.attachmentName = files[0].name;
        if (this.attachmentName.lastIndexOf(".") <= 0) {
          return;
        }
      }
    },
    loadTeam(action) {
      console.log("Loading Team");

      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 129);
      if (action == "update") {
        formData.append("id", vm.id);
      }
      axios
        .post(BASE_URL + "manager/team.php?action=" + action, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(function(response) {
          vm.loading = false;
          console.log(response);
          vm.message = response.data.message;
          // EventBus.$emit("display-message", vm.message);
          if (!response.data.error) {
            vm.teamList = response.data.list;
            if (vm.teamList.length > 0) {
              vm.defaultTeamMember = response.data.list[0];
            }
          }
          vm.dialog = false;
        })
        .catch(function(error) {
          vm.loading = false;
          console.log(error);
          EventBus.$emit("display-message", "Some error occured 2!");
        });
    }
  },
  computed: {},
  mounted() {
    this.action = "add";
    EventBus.$on("view-assign-to", data => {
      this.dialog = true;
      this.action = "assignTo";
      this.id = data.id;
    });
  },
  created() {
    this.userInfo = this.$store.state.userlogged;
    this.loadTeam("get");
    console.log("Project Id:" + this.projectId);
  }
};
</script>