<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md12>
        <v-card color="white" flat class="text-lg-center text-center mx-auto" width="480">
          <h2 class="title pa-3">Profile</h2>
          <div class="pa-3 image-section">
            <v-avatar size="120" color="grey">
              <img :src="profileImage" alt v-if="profileImage != undefined" />
              <span
                v-else
                class="display-3 pa-2 white--text font-weight-bold"
                >{{ firstName.substring(0, 1) }}</span
              >
            </v-avatar>
            <p class="edit-profile-img mb-1">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    fab
                    depressed
                    color="success"
                    v-on="on"
                    @click="pickFile"
                  >
                    <v-icon medium>mdi-account-edit-outline</v-icon>
                  </v-btn>
                  <input
                    type="file"
                    style="display: none"
                    ref="profileImg"
                    accept="image/*"
                    @change="onFilePicked"
                  />
                </template>
                <span>Change Image</span>
              </v-tooltip>
            </p>
            <p class="title mb-1">{{ firstName }} {{ lastName }}</p>
            <p class="subheading mt-1">{{ email }}</p>
            <ChangePassword />
          </div>
          <div class="pa-3">
            <v-form class="px-3" ref="form" enctype="multipart/form-data">
              <v-text-field
                label="First Name"
                v-model="firstName"
                prepend-icon="mdi-format-title"
                :rules="inputRules"
              ></v-text-field>
              <v-text-field
                label="Last Name"
                v-model="lastName"
                prepend-icon="mdi-format-title"
                :rules="inputRules"
              ></v-text-field>
              <v-text-field
                label="Contact Number / Phone"
                v-model="contact"
                prepend-icon="mdi-card-account-phone-outline"
                :rules="inputRules"
              ></v-text-field>
              <v-text-field
                label="Company/ Business Name"
                v-model="company"
                prepend-icon="mdi-domain"
                :rules="inputRules"
              ></v-text-field>

              <v-spacer></v-spacer>
              <v-btn
                text
                class="primary mx-0 mt-3"
                @click="submit()"
                :loading="loading"
                >Update</v-btn
              >
            </v-form>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import EventBus from "@/eventBus";
import ChangePassword from "@/components/client/ChangePassword";
const BASE_URL = process.env.VUE_APP_API_BASE_URL;
export default {
  data() {
    return {
      message: "",
      profileImage: "",
      email: "",
      firstName: "",
      lastName: "",
      contact: "",
      company: "",
      fileName: "",
      loading: false,
      userInfo: {},
      inputRules: [
        (v) => (v && v.length >= 3) || "Minimum length is 3 characters",
      ],
    };
  },
  components: {
    ChangePassword,
  },
  methods: {
    submit() {
      const vm = this;
      vm.loading = true;
      var formData = new FormData();
      formData.append("firstName", vm.firstName);
      formData.append("lastName", vm.lastName);
      formData.append("phone", vm.contact);
      formData.append("company", vm.company);
      formData.append("id", vm.userInfo.id);
      console.log(vm.$refs.profileImg.files.length);

      if (vm.$refs.profileImg.files.length > 0) {
        formData.append("profileImage", vm.$refs.profileImg.files[0]);
      }
      axios
        .post(BASE_URL + "client/profile.php", formData)
        .then(function(response) {
          vm.loading = false;
          vm.message = response.data.message;
          EventBus.$emit("display-message", vm.message);
          if (response.data.error) {
            console.log(vm.message);
          } else {
            EventBus.$emit("pofileUpdated", response.data.auth);
            vm.$store.dispatch("saveUserLogged", response.data.auth);
          }
        })
        .catch(function(error) {
          vm.loading = false;
          console.log(error);
        });
    },

    pickFile() {
      this.$refs.profileImg.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      console.log(files[0].name);
      if (files[0] !== undefined) {
        this.fileName = files[0].name;
        this.profileImage = URL.createObjectURL(files[0]);
        this.changeProfileImage();
        if (this.fileName.lastIndexOf(".") <= 0) {
          return;
        }
      }
    },
    changeProfileImage() {
      const vm = this;
      vm.loading = true;
      var formData = new FormData();
      formData.append("id", vm.userInfo.id);
      if (vm.$refs.profileImg.files.length > 0) {
        formData.append("profileImage", vm.$refs.profileImg.files[0]);
      }
      axios
        .post(BASE_URL + "client/profile.php", formData)
        .then(function(response) {
          vm.loading = false;
          vm.message = response.data.message;
          EventBus.$emit("display-message", vm.message);
          if (response.data.error) {
            console.log(vm.message);
          } else {
            EventBus.$emit("pofileUpdated", response.data.auth);
            vm.$store.dispatch("saveUserLogged", response.data.auth);
          }
        })
        .catch(function(error) {
          vm.loading = false;
          console.log(error);
        });
    },
  },
  created() {
    this.userInfo = this.$store.state.userlogged;
    if (this.userInfo != null) {
      this.firstName = this.userInfo.fname;
      this.lastName = this.userInfo.lname;
      this.profileImage = this.userInfo.profileImage;
      this.company = this.userInfo.company;
      this.contact = this.userInfo.contact;
      this.email = this.userInfo.email;
    }
  },
};
</script>
<style>
.image-section {
  background-color: #f8f9fa;
}
.edit-profile-img {
  margin-top: -20px;
}
</style>
