import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userlogged: null,
    dateTime: null,
  },
  mutations: {
    saveUserLogged(state, loggedUser) {
      state.userlogged = loggedUser;
      if (loggedUser == null) {
        state.dateTime = null;
      } else {
        state.dateTime = new Date();
      }
    },
  },
  actions: {
    saveUserLogged(context, loggedUser) {
      context.commit("saveUserLogged", loggedUser);
    },
  },
  modules: {},
  plugins: [createPersistedState()],
});
