<template>
  <v-dialog max-width="300px" v-model="dialog">
    <v-card color="white" text class="text-lg-center mx-auto">
      <h2 class="title pa-3">Client Profile</h2>
      <div class="pa-3 image-section">
        <v-avatar size="120" color="grey">
          <img :src="clientInfo.image" v-if="clientInfo.image!=''" :alt="clientInfo.image" />
          <span v-else class="display-1 white--text">{{ clientInfo.name.substring(0,1) }}</span>
        </v-avatar>
        <p class="title mb-1 mt-3">{{ clientInfo.name }}</p>
        <p class="subheading mb-1">{{ clientInfo.email }}</p>
        <p class="subheading mb-1 grey--text">{{ clientInfo.contact }}</p>
        <p class="subheading mb-1 grey--text">{{ clientInfo.company }}</p>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import EventBus from "@/eventBus";
//import axios from "axios";
//const BASE_URL = process.env.VUE_APP_API_BASE_URL;
export default {
  data() {
    return {
      clientInfo: "",
      id: 0,
      loading: false,
      dialog: false,
      action: "get"
    };
  },
  methods: {
    showDialog() {
      console.log("Project Id:" + this.projectId);
      this.btnLabel = "Add Bug";
      this.name = "";
      //this.dialog = true;
      this.action = "add";
      this.title = "Add New Bug";
    }
  },

  computed: {},

  mounted() {
    this.action = "add";
    EventBus.$on("view-client", data => {
      this.dialog = true;
      this.action = "update";
      this.clientInfo = data;
    });
  },
  created() {}
};
</script>