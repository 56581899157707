var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","align-center":"","justify-center":""}},[_c('v-col',{attrs:{"md":"5","sm":"12","lg":"5","xl":"4","xs":"12"}},[_c('v-card',{staticClass:"form-card"},[_c('v-card-title',{staticClass:"primary white--text"},[_vm._v("Welcome Back")]),_c('v-card-subtitle',{staticClass:"caption primary white--text"},[_vm._v("Manager login page")]),_c('v-card-text',{staticClass:"pt-4"},[_c('div',[(!_vm.verifyError)?_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"label":"Enter your email","rules":_vm.usernameRules,"required":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.focusOnPassword.apply(null, arguments)}},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('v-text-field',{ref:"password",attrs:{"label":"Enter your password","min":"8","append-icon":_vm.e1 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.e1 ? 'password' : 'text',"rules":_vm.passwordRules,"counter":"","required":""},on:{"click:append":() => (_vm.e1 = !_vm.e1),"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submit.apply(null, arguments)}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-layout',{attrs:{"justify-space-between":""}},[_c('v-btn',{class:{
                  'primary darken-2 white--text': _vm.valid,
                  disabled: !_vm.valid,
                },attrs:{"loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v("Login")]),_c('span',{staticClass:"my-2 red--text"},[_vm._v(_vm._s(_vm.message))])],1)],1):_vm._e(),(_vm.verifyError)?_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"label":"Enter OTP","rules":_vm.usernameRules,"required":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.verifyCode.apply(null, arguments)}},model:{value:(_vm.otp),callback:function ($$v) {_vm.otp=$$v},expression:"otp"}}),_c('v-layout',{attrs:{"justify-space-between":""}},[_c('v-btn',{class:{
                  'primary darken-2 white--text': _vm.valid,
                  disabled: !_vm.valid,
                },attrs:{"loading":_vm.loading},on:{"click":_vm.verifyCode}},[_vm._v("Verify")]),_c('span',{staticClass:"my-2 red--text"},[_vm._v(_vm._s(_vm.message))])],1)],1):_vm._e()],1)])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }