<template>
  <v-container fill-height fluid grid-list-xl>
    <v-row justify-center wrap>
      <v-col cols="12">
        <v-card tile color="white" flat class="text-center mx-auto" width="480">
          <h2 class="title pa-3">Profile</h2>
          <div class="pa-3 pb-0 image-section">
            <v-avatar size="120" color="grey">
              <img :src="profileImage" alt="alt" v-if="profileImage != ''" />
              <span v-else class="white--text display-3 font-weight-bold">{{
                firstName.substring(0, 1)
              }}</span>
            </v-avatar>
            <p class="edit-profile-img mb-1">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    fab
                    depressed
                    color="success"
                    v-on="on"
                    @click="pickFile"
                  >
                    <v-icon medium>mdi-account-edit-outline</v-icon>
                  </v-btn>
                  <input
                    type="file"
                    style="display: none"
                    ref="profileImg"
                    accept="image/*"
                    @change="onFilePicked"
                  />
                </template>
                <span>Change Image</span>
              </v-tooltip>
            </p>
            <p class="title mb-1">{{ firstName }} {{ lastName }}</p>
            <p class="subheading mt-1">{{ email }}</p>
            <ChangePassword />
            <v-row class="tasks-row mt-3 border-bottom">
              <v-col cols="12">
                <h4>Task Performance</h4>
              </v-col>
            </v-row>
            <v-row class="tasks-row border-bottom">
              <v-col cols="4">
                <div class="title font-weight-bold green--text darken-5">10</div>
                <div class="caption font-weight-medium">
                  Ongoing
                </div>
              </v-col>
              <v-col cols="4" class="border-lr">
                <div class="title font-weight-bold orange--text darken-5">5</div>
                <div class="caption font-weight-medium gray--text">
                  Upcoming
                </div>
              </v-col>
              <v-col cols="4">
                <div class="title font-weight-bold grey--text">20</div>
                <div class="caption font-weight-medium">
                  Completed
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="pa-3">
            <v-form class="px-3" ref="form" enctype="multipart/form-data">
              <v-text-field
                label="First Name"
                v-model="firstName"
                prepend-icon="mdi-format-title"
                :rules="inputRules"
              ></v-text-field>
              <v-text-field
                label="Last Name"
                v-model="lastName"
                prepend-icon="mdi-format-title"
                :rules="inputRules"
              ></v-text-field>
              <v-text-field
                label="Contact Number / Phone"
                v-model="contact"
                prepend-icon="mdi-card-account-phone-outline"
                :rules="inputRules"
              ></v-text-field>

              <v-select
                :items="skillList"
                v-model="defaultSkill"
                item-text="name"
                item-value="id"
                label="Skill(s)"
                prepend-icon="mdi-account-details-outline"
                multiple
                chips
                deletable-chips
                hint="Pick your Skill(s)"
                return-object
              ></v-select>
              <v-spacer></v-spacer>
              <v-btn
                flat
                class="primary mx-0 mt-3"
                @click="submit()"
                :loading="loading"
                >Update</v-btn
              >
            </v-form>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import EventBus from "@/eventBus";
import ChangePassword from "@/components/team/ChangePassword";
const BASE_URL = process.env.VUE_APP_API_BASE_URL;
export default {
  data() {
    return {
      message: "",
      profileImage: "",
      email: "",
      firstName: "",
      lastName: "",
      contact: "",

      fileName: "",
      loading: false,
      userInfo: {},
      inputRules: [
        (v) => (v && v.length >= 3) || "Minimum length is 3 characters",
      ],
      skillList: [],
      defaultSkill: [],
    };
  },
  components: {
    ChangePassword,
  },
  methods: {
    submit() {
      const vm = this;
      vm.loading = true;
      var formData = new FormData();
      formData.append("firstName", vm.firstName);
      formData.append("lastName", vm.lastName);
      formData.append("phone", vm.contact);
      formData.append("id", vm.userInfo.id);
      formData.append("skills", vm.getSelectedSkillsIds());
      console.log(vm.$refs.profileImg.files.length);

      if (vm.$refs.profileImg.files.length > 0) {
        formData.append("profileImage", vm.$refs.profileImg.files[0]);
      }
      axios
        .post(BASE_URL + "team/profile.php", formData)
        .then(function(response) {
          vm.loading = false;
          vm.message = response.data.message;
          EventBus.$emit("display-message", vm.message);
          if (response.data.error) {
            console.log(vm.message);
          } else {
            EventBus.$emit("pofileUpdated", response.data.auth);
            vm.$store.dispatch("saveUserLogged", response.data.auth);
          }
        })
        .catch(function(error) {
          vm.loading = false;
          console.log(error);
        });
    },
    loadSkills(action) {
      console.log("Loading Team");

      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 129);
      if (action == "update") {
        formData.append("id", vm.id);
      }
      axios
        .post(BASE_URL + "team/profile.php?action=" + action, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function(response) {
          vm.loading = false;
          console.log(response);
          vm.message = response.data.message;
          // EventBus.$emit("display-message", vm.message);
          if (!response.data.error) {
            vm.skillList = response.data.list;
            if (vm.userInfo.skillIds) {
              vm.getSelectedSkills(vm.userInfo);
            }
          }
          vm.dialog = false;
        })
        .catch(function(error) {
          vm.loading = false;
          console.log(error);
          EventBus.$emit("display-message", "Some error occured 2!");
        });
    },
    getSelectedSkillsIds() {
      var ids = [];
      for (let index = 0; index < this.defaultSkill.length; index++) {
        const element = this.defaultSkill[index];
        ids.push(element.id);
      }
      return JSON.stringify(ids);
    },
    getSelectedSkills(item) {
      console.log(item);
      this.defaultSkill = [];
      var skills = item.skillIds.split(",");
      for (let index = 0; index < this.skillList.length; index++) {
        const element = this.skillList[index];
        if (skills.includes(element.id)) {
          this.defaultSkill.push(element);
        }
      }
    },
    pickFile() {
      this.$refs.profileImg.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      console.log(files[0].name);
      if (files[0] !== undefined) {
        this.fileName = files[0].name;
        this.profileImage = URL.createObjectURL(files[0]);
        this.changeProfileImage();
        if (this.fileName.lastIndexOf(".") <= 0) {
          return;
        }
      }
    },
    changeProfileImage() {
      const vm = this;
      vm.loading = true;
      var formData = new FormData();
      formData.append("id", vm.userInfo.id);
      if (vm.$refs.profileImg.files.length > 0) {
        formData.append("profileImage", vm.$refs.profileImg.files[0]);
      }
      axios
        .post(BASE_URL + "team/profile.php", formData)
        .then(function(response) {
          vm.loading = false;
          vm.message = response.data.message;
          EventBus.$emit("display-message", vm.message);
          if (response.data.error) {
            console.log(vm.message);
          } else {
            EventBus.$emit("pofileUpdated", response.data.auth);
            vm.$store.dispatch("saveUserLogged", response.data.auth);
          }
        })
        .catch(function(error) {
          vm.loading = false;
          console.log(error);
        });
    },
  },
  created() {
    this.userInfo = this.$store.state.userlogged;
    this.firstName = this.userInfo.fname;
    this.lastName = this.userInfo.lname;
    this.profileImage = this.userInfo.profileImage;
    this.contact = this.userInfo.contact;
    this.email = this.userInfo.email;
    this.loadSkills("getSkill");
    this.defaultSkill = this.getSelectedSkills(this.userInfo);
  },
};
</script>
<style>
.image-section {
  background-color: #f8f9fa;
}
.edit-profile-img {
  margin-top: -20px;
}
.border-lr {
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
}
.border-bottom {
  border-bottom: 1px solid lightgrey;
}
.tasks-row {
  background-color: white;
}
</style>
