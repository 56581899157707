<template>
  <v-dialog max-width="600px" v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn depressed fab text small v-on="on" @click="showDialog">
        <v-icon medium color="info">mdi-plus-thick</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <h2>{{ title }}</h2>
      </v-card-title>
      <v-card-text>
        <v-form class="px-3" ref="form" enctype="multipart/form-data">
          <v-text-field label="Title" v-model="name" prepend-icon="mdi-format-title" :rules="inputRules"></v-text-field>
          <v-textarea v-model="desc" prepend-icon="mdi-card-text-outline" rows="2" label="Description"></v-textarea>
          <v-text-field label="Rough Amount" v-model="amount" prepend-icon="mdi-currency-usd"
            :rules="amountRules"></v-text-field>
          <v-text-field label="Upload Files (zip)" @click="pickFile" v-model="attachmentName"
            prepend-icon="mdi-paperclip"></v-text-field>
          <input type="file" style="display: none" ref="insFiles" accept=".zip" @change="onFilePicked" />
          <v-select :items="teamList" v-model="defaultTeamMember" item-text="name" item-value="id" label="Assign To"
            prepend-icon="mdi-account" return-object></v-select>
          <v-select :items="planList" v-model="defaultPlan" item-text="title" item-value="id" label="Project Plan Stage"
            prepend-icon="mdi-timeline-text-outline" return-object></v-select>
          <v-row no-gutters>
            <v-col cols="12" lg="6">
              <v-menu ref="startDateMenu" v-model="startDateMenu" :close-on-content-click="false" :nudge-right="40"
                :return-value.sync="startDate" transition="scale-transition" offset-y min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field v-model="startDate" label="Start Date" prepend-icon="mdi-calendar" readonly
                    v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="startDate" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="startDateMenu = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.startDateMenu.save(startDate)">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" lg="6">
              <v-menu ref="finishDateMenu" v-model="finishDateMenu" :close-on-content-click="false" :nudge-right="40"
                :return-value.sync="deadline" transition="scale-transition" offset-y min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field v-model="deadline" label="Deadline" prepend-icon="mdi-calendar-check-outline" readonly
                    v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="deadline" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="finishDateMenu = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.finishDateMenu.save(deadline)">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
          <v-btn text class="primary mx-0 mt-3" @click="submit()" :loading="loading">{{ btnLabel }}</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import EventBus from "@/eventBus";
import axios from "axios";
const BASE_URL = process.env.VUE_APP_API_BASE_URL;
export default {
  props: ["projectId", "clientId"],
  data() {
    return {
      name: "",
      desc: "",
      amount: "0.00",
      deadlineDate: "",
      userInfo: null,
      title: "Add New Task",
      btnLabel: "Add Task",
      attachmentName: "",
      id: 0,
      inputRules: [
        (v) => (v && v.length >= 3) || "Minimum length is 3 characters",
      ],
      amountRules: [v => (/[0-9]/.test(v) && v.length > 0) || "Numbers only"],
      loading: false,
      dialog: false,
      action: "get",
      teamList: [],
      defaultTeamMember: {},
      startDate: new Date().toISOString().substr(0, 10),
      deadline: new Date().toISOString().substr(0, 10),
      startDateMenu: false,
      finishDateMenu: false,
      planList: [],
      defaultPlan: {},
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        const vm = this;
        console.log("Client Id " + vm.clientId);
        var formData = new FormData();
        formData.append("managerId", vm.userInfo.id);
        formData.append("projectId", vm.projectId);
        formData.append("clientId", vm.clientId);
        formData.append("title", vm.name);
        formData.append("detail", vm.desc);
        formData.append("assignTo", vm.defaultTeamMember.id);
        formData.append("wbsId", vm.defaultPlan.id);
        formData.append("startDate", vm.startDate);
        formData.append("deadline", vm.deadline);
        formData.append("amount", vm.amount.toString().trim());
        if (vm.$refs.insFiles.files.length > 0) {
          formData.append("attachment", vm.$refs.insFiles.files[0]);
        }
        if (vm.action == "update") {
          console.log("Language Id:" + vm.id);
          formData.append("id", vm.id);
        }
        axios
          .post(BASE_URL + "manager/task.php?action=" + vm.action, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function (response) {
            vm.loading = false;
            console.log(response);
            vm.message = response.data.message;
            EventBus.$emit("display-message", vm.message);
            if (!response.data.error) {
              if (vm.action == "update") {
                EventBus.$emit("task-update", vm.error);
              } else {
                EventBus.$emit("task-added", vm.error);
              }
            }
            vm.dialog = false;
            // vm.name = "";
            vm.$refs.form.reset();
          })
          .catch(function (error) {
            vm.loading = false;
            console.log(error);
            EventBus.$emit("display-message", "Some error occured!");
          });
      }
    },
    uploadFiles() { },
    showDialog() {
      console.log("Project Id:" + this.projectId);
      this.btnLabel = "Add Task";
      this.name = "";
      //this.dialog = true;
      this.action = "add";
      this.title = "Add New Task";
      this.$refs.form.reset();
    },
    pickFile() {
      this.$refs.insFiles.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      console.log(files[0].name);
      if (files[0] !== undefined) {
        this.attachmentName = files[0].name;
        if (this.attachmentName.lastIndexOf(".") <= 0) {
          return;
        }
      }
    },
    loadSettings(action) {
      console.log("Loading Team");

      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 129);
      formData.append("projectId", vm.projectId);
      axios
        .post(BASE_URL + "manager/task.php?action=" + action, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          vm.loading = false;
          console.log(response);
          vm.message = response.data.message;
          // EventBus.$emit("display-message", vm.message);
          if (!response.data.error) {
            vm.teamList = response.data.teamList;
            if (vm.teamList.length > 0) {
              vm.defaultTeamMember = response.data.teamList[0];
            }
            vm.planList = response.data.planList;
            if (vm.planList.length > 0) {
              vm.defaultPlan = response.data.planList[0];
            }
          }
          vm.dialog = false;
        })
        .catch(function (error) {
          vm.loading = false;
          console.log(error);
          EventBus.$emit("display-message", "Some error occured 2!");
        });
    },
    getAssignedTo(memberId) {
      for (let index = 0; index < this.teamList.length; index++) {
        const teamMember = this.teamList[index];
        if (teamMember.id == memberId) {
          return teamMember;
        }
      }
    },
    getDefaultPlan(planId) {
      for (let index = 0; index < this.planList.length; index++) {
        const plan = this.planList[index];
        if (plan.id == planId) {
          return plan;
        }
      }
    },
  },
  computed: {},
  mounted() {
    this.action = "add";
    EventBus.$on("edit-task", (data) => {
      this.name = data.title;
      this.desc = data.desc;
      this.id = data.id;
      console.log(this.startDate);
      this.startDate = data.startDate;
      this.deadline = data.dueDate;
      this.defaultTeamMember = this.getAssignedTo(data.assignTo.id);
      this.defaultPlan = this.getDefaultPlan(data.planId);
      this.btnLabel = "Update Task";
      this.dialog = true;
      this.action = "update";
      this.title = "Update Task";
    });
    EventBus.$on("plan-added", (error) => {
      console.log(error);
      this.loadSettings("getPref");
    });
  },
  created() {
    this.userInfo = this.$store.state.userlogged;
    this.loadSettings("getPref");
    console.log("Project Id:" + this.projectId);
  },
};
</script>
