import Vue from "vue";
import VueRouter from "vue-router";
import goTo from "vuetify/es5/services/goto";
import Login from "../views/client/Login.vue";
import Dashboard from "../views/client/Dashboard.vue";
import Project from "../views/client/Project.vue";
import Profile from "../views/client/Profile.vue";
import Feedback from "../views/client/Feedback.vue";

import ManagerLogin from "../views/manager/ManagerLogin.vue";
import ManagerDashboard from "../views/manager/ManagerDashboard.vue";
import ManageClient from "../views/manager/ManageClient.vue";
import ManageTeam from "../views/manager/ManageTeam.vue";
import ManagePayment from "../views/manager/ManagePayment.vue";
import ManageProject from "../views/manager/ManageProject.vue";
import ManageProjects from "../views/manager/ManageProjects.vue";
import ManagerProfile from "../views/manager/ManagerProfile.vue";
import ManagerTask from "../views/manager/ManagerTask.vue";

import TeamLogin from "../views/team/TeamLogin.vue";
import TeamDashboard from "../views/team/TeamDashboard.vue";
import TeamTodayTask from "../views/team/TeamTodayTask.vue";
import TeamUpcomingTask from "../views/team/TeamUpcomingTask.vue";
import TeamTask from "../views/team/TeamTask.vue";
import TeamProfile from "../views/team/TeamProfile.vue";
import Home from "../views/Home.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [{
        path: "/",
        name: "homePage",
        component: Home,
        meta: { requiresAuth: false },
    },
    {
        path: "/client",
        name: "clientLogin",
        component: Login,
        meta: { requiresAuth: false },
    },
    {
        path: "/client/dashboard",
        name: "clientDashboard",
        component: Dashboard,
        meta: { requiresAuth: true },
    },
    {
        path: "/client/project/:id",
        name: "clientProject",
        component: Project,
        meta: { requiresAuth: true },
    },
    {
        path: "/client/profile",
        name: "clientProfile",
        component: Profile,
        meta: { requiresAuth: true },
    },
    {
        path: "/client/feedback",
        name: "clientFeedback",
        component: Feedback,
        meta: { requiresAuth: true },
    },
    {
        path: "/manager",
        name: "managerLogin",
        component: ManagerLogin,
        meta: { requiresAuth: false },
    },
    {
        path: "/manager/dashboard",
        name: "managerDashboard",
        component: ManagerDashboard,
        meta: { requiresAuth: true },
    },
    {
        path: "/manager/client",
        name: "manageClient",
        component: ManageClient,
        meta: { requiresAuth: true },
    },
    {
        path: "/manager/team",
        name: "manageTeam",
        component: ManageTeam,
        meta: { requiresAuth: true },
    },
    {
        path: "/manager/payment",
        name: "managePayment",
        component: ManagePayment,
        meta: { requiresAuth: true },
    },
    {
        path: "/manager/task/:id",
        name: "managerTask",
        component: ManagerTask,
        meta: { requiresAuth: true },
    },
    {
        path: "/manager/project/:id",
        name: "manageProject",
        component: ManageProject,
        meta: { requiresAuth: true },
    },
    {
        path: "/manager/projects",
        name: "manageProjects",
        component: ManageProjects,
        meta: { requiresAuth: true },
    },
    {
        path: "/manager/profile",
        name: "managerProfile",
        component: ManagerProfile,
        meta: { requiresAuth: true },
    },
    {
        path: "/team/",
        name: "teamLogin",
        component: TeamLogin,
        meta: { requiresAuth: false },
    },
    {
        path: "/team/dashboard",
        name: "teamDashboard",
        component: TeamDashboard,
        meta: { requiresAuth: true },
    },
    {
        path: "/team/task/today",
        name: "teamTodayTask",
        component: TeamTodayTask,
        meta: { requiresAuth: true },
    },
    {
        path: "/team/task/upcoming",
        name: "teamUpcomingTask",
        component: TeamUpcomingTask,
        meta: { requiresAuth: true },
    },
    {
        path: "/team/task/:id",
        name: "teamTask",
        component: TeamTask,
        meta: { requiresAuth: true },
    },
    {
        path: "/team/profile",
        name: "teamProfile",
        component: TeamProfile,
        meta: { requiresAuth: true },
    },
];
const scrollBehavior = (to, from, savedPosition) => {
    if (to.hash) {
        return goTo(to.hash);
    } else if (savedPosition) {
        return goTo(savedPosition.y);
    } else {
        // check for mobileTo meta property
        to.matched.some((record) => {
            if (record.meta.mobileTo) {
                // scroll to that location if screen size is mobile
                if (document.documentElement.clientWidth <= 600) {
                    return goTo(record.meta.mobileTo);
                }
            }
        });
    }
    // default to top of page
    // or return false if you don't want any behavior
    return { x: 0, y: 0 };
};

const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior,
});

router.beforeEach((to, from, next) => {
    console.log("print beforeEnter " + store.state.userlogged);
    if (
        store.state.userlogged ||
        to.name == "clientLogin" ||
        to.name == "managerLogin" ||
        to.name == "teamLogin"
    ) {
        next();
    } else {
        //  next({ replace: true, name: "clientLogin" });
        next();

        // next({ name: "clientLogin" });
    }
});

export default router;