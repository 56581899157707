<template>
  <v-card flat>
    <v-card-title class="text-center">
      <h2>{{ title }}</h2>
    </v-card-title>
    <v-card-text class="text-center pt-3">
      <v-form class="px-1" ref="form" enctype="multipart/form-data">
        <v-textarea
          v-model="desc"
          outlined
          rows="5"
          label="Feedback"
        ></v-textarea>
        <v-spacer></v-spacer>
        <div>{{ message }}</div>
        <v-btn
          text
          class="primary mx-0 mt-3"
          @click="submit()"
          :loading="loading"
          >{{ btnLabel }}</v-btn
        >
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import EventBus from "@/eventBus";
import axios from "axios";
const BASE_URL = process.env.VUE_APP_API_BASE_URL;
export default {
  props: ["projectId"],
  data() {
    return {
      name: "",
      desc: "",
      deadlineDate: "",
      userInfo: null,
      title: "Add Feedback",
      btnLabel: "Submit",
      attachmentName: "",
      id: 0,
      inputRules: [
        (v) => (v && v.length >= 3) || "Minimum length is 3 characters",
      ],
      loading: false,
      action: "get",
      dialog: false,
      rating: 3,
      isVisibleTextarea: false,
      message: "",
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        const vm = this;
        console.log("Client Id " + vm.userInfo.id);
        var formData = new FormData();
        formData.append("feedback", vm.desc);
        formData.append("clientId", vm.userInfo.id);
        formData.append("rating", vm.rating);
        axios
          .post(
            BASE_URL + "client/feedback.php?action=" + vm.action,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(function(response) {
            vm.loading = false;
            console.log(response);
            vm.message = response.data.message;
            EventBus.$emit("display-message", vm.message);
            vm.$refs.form.reset();
          })
          .catch(function(error) {
            vm.loading = false;
            console.log(error);
            EventBus.$emit("display-message", "Some error occured!");
          });
      }
    },
    uploadFiles() {},
    showDialog() {
      console.log("Project Id:" + this.projectId);

      this.btnLabel = "Submit";
      this.name = "";
      //this.dialog = true;
      this.action = "add";
      this.title = "Add Feedback";
      this.$refs.form.reset();
    },
    showTextArea() {
      this.isVisibleTextarea = true;
    },
  },
  computed: {},
  mounted() {
    this.action = "add";
  },
  created() {
    this.userInfo = this.$store.state.userlogged;
    console.log("Project Id:" + this.projectId);
  },
};
</script>
<style scoped>
.close-btn {
  position: absolute;
  right: 10px;
}
</style>
