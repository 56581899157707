<template>
  <v-dialog max-width="600px" v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn depressed fab text small v-on="on" @click="showDialog">
        <v-icon medium color="info">mdi-plus-thick</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <h2>{{ title }}</h2>
        <v-btn
          small
          text
          fab
          color="grey darken-1"
          @click="dialog = false"
          class="close-btn"
        >
          <v-icon medium>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form class="px-1" ref="form" enctype="multipart/form-data">
          <v-text-field
            label="Title"
            v-model="name"
            prepend-icon="mdi-format-title"
            :rules="inputRules"
          ></v-text-field>
          <v-textarea
            v-model="desc"
            prepend-icon="mdi-text"
            rows="2"
            label="Description"
          ></v-textarea>
          <v-text-field
            label="Upload Files (zip)"
            @click="pickFile"
            v-model="attachmentName"
            prepend-icon="mdi-attachment"
          ></v-text-field>
          <input
            type="file"
            style="display: none"
            ref="insFiles"
            accept=".zip"
            @change="onFilePicked"
          />

          <v-spacer></v-spacer>
          <v-btn
            text
            class="primary mx-0 mt-3"
            @click="submit()"
            :loading="loading"
            >{{ btnLabel }}</v-btn
          >
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import EventBus from "@/eventBus";
import axios from "axios";
const BASE_URL = process.env.VUE_APP_API_BASE_URL;
export default {
  props: ["projectId"],
  data() {
    return {
      name: "",
      desc: "",
      deadlineDate: "",
      userInfo: null,
      title: "Add New Bug",
      btnLabel: "Add Bug",
      attachmentName: "",
      id: 0,
      inputRules: [
        (v) => (v && v.length >= 3) || "Minimum length is 3 characters",
      ],
      loading: false,
      dialog: false,
      action: "get",
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        const vm = this;
        vm.loading = true;
        console.log("Client Id " + vm.userInfo.id);
        var formData = new FormData();
        formData.append("title", vm.name);
        formData.append("desc", vm.desc);
        formData.append("clientId", vm.userInfo.id);
        formData.append("projectId", vm.projectId);
        if (vm.$refs.insFiles.files.length > 0) {
          formData.append("attachment", vm.$refs.insFiles.files[0]);
        }
        if (vm.action == "update") {
          console.log("Language Id:" + vm.id);
          formData.append("id", vm.id);
        }
        axios
          .post(BASE_URL + "client/bug.php?action=" + vm.action, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function (response) {
            vm.loading = false;
            console.log(response);
            vm.message = response.data.message;
            EventBus.$emit("display-message", vm.message);
            if (!response.data.error) {
              if (vm.action == "update") {
                EventBus.$emit("bug-update", vm.error);
              } else {
                EventBus.$emit("bug-added", vm.error);
              }
            }
            vm.dialog = false;
            // vm.name = "";
            vm.$refs.form.reset();
          })
          .catch(function (error) {
            vm.loading = false;
            console.log(error);
            EventBus.$emit("display-message", "Some error occured!");
          });
      }
    },
    uploadFiles() {},
    showDialog() {
      console.log("Project Id:" + this.projectId);

      this.btnLabel = "Add Bug";
      this.name = "";
      //this.dialog = true;
      this.action = "add";
      this.title = "Add New Bug";
      setTimeout(() => {
        this.$refs.form.reset();
      }, 1000);
    },
    pickFile() {
      this.$refs.insFiles.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      console.log(files[0].name);
      if (files[0] !== undefined) {
        this.attachmentName = files[0].name;
        if (this.attachmentName.lastIndexOf(".") <= 0) {
          return;
        }
      }
    },
  },
  computed: {},
  mounted() {
    this.action = "add";
    EventBus.$on("edit-bug", (data) => {
      this.name = data.name;
      this.namePun = data.nameNative;
      this.id = data.id;
      this.btnLabel = "Update Bug";
      this.dialog = true;
      this.action = "update";
      this.title = "Update Bug";
    });
  },
  created() {
    this.userInfo = this.$store.state.userlogged;
    console.log("Project Id:" + this.projectId);
  },
};
</script>
<style scoped>
.close-btn {
  position: absolute;
  right: 10px;
}
</style>
