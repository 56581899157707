import { render, staticRenderFns } from "./TaskChatBox.vue?vue&type=template&id=9f125974&scoped=true"
import script from "./TaskChatBox.vue?vue&type=script&lang=js"
export * from "./TaskChatBox.vue?vue&type=script&lang=js"
import style0 from "./TaskChatBox.vue?vue&type=style&index=0&id=9f125974&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f125974",
  null
  
)

export default component.exports