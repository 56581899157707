<template>
  <v-row row wrap>
    <v-col xs12 md3 lg3>
      <v-btn color="pink" dark small fab class="float-btn" @click="showDialog">
        <v-icon>mdi-chat-outline</v-icon>
      </v-btn>
      <!-- for comment  list -->
      <v-card
        class="ml-0 pa-2 float-position chat-window"
        v-show="dialog"
        v-scroll-lock="dialog"
      >
        <div class="pa-2 border-bottom align-content-end">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-avatar size="28" v-on="on" color="grey" class="img-border">
                <img
                  :src="taskDetail.assignTo.profileImage"
                  v-if="taskDetail.assignTo.profileImage != ''"
                  :alt="taskDetail.assignTo.name"
                />
                <span v-else class="white--text headline">{{
                  taskDetail.assignTo.name.substring(0, 1)
                }}</span>
              </v-avatar>
            </template>
            <span>{{ taskDetail.assignTo.name }}</span>
          </v-tooltip>
          <span class="title pt-3 ml-2">Discussion With Team</span>
          <v-btn
            small
            text
            fab
            color="grey darken-1"
            @click="dialog = false"
            class="close-btn"
          >
            <v-icon medium>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="msg-con" :style="{ height: commentsHeight + 'px' }" v-bar>
          <div class="messages" ref="commentBoxIns">
            <div class="pa-2" id="chat-messages">
              <v-row v-if="commentList.length < 1">
                <v-col cols="12" class="text-center">
                  <v-card flat tile color="grey--text">
                    There is no message in list. start now</v-card
                  >
                </v-col>
              </v-row>
              <template v-for="(comment, index) in commentList">
                <div
                  v-if="comment.header"
                  :key="comment.dateTime"
                  class="label"
                >
                  <div
                    class="header mt-1 d-block w-100 text-center heading grey--text"
                    v-if="comment.header"
                  >
                    <v-chip small class="mb-3">{{ comment.header }}</v-chip>
                  </div>
                </div>
                <div
                  :class="comment.isOdd ? 'message right' : 'message'"
                  :key="`${index}-comment`"
                >
                  <v-avatar color="grey" class="img" size="30">
                    <img
                      :src="comment.profileImage"
                      v-if="comment.profileImage != ''"
                      :alt="comment.name"
                    />
                    <span v-else class="white--text headline">{{
                      comment.name.substring(0, 1)
                    }}</span>
                  </v-avatar>

                  <div class="bubble" v-html="comment.message"></div>
                  <div class="date-time grey--text caption mt-2 mb-4">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-if="comment.attachment != ''"
                          icon
                          ripple
                          v-on="on"
                          @click="downloadAttachment(comment.attachment)"
                        >
                          <v-icon color="grey lighten-1"
                            >mdi-file-download-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Download</span>
                    </v-tooltip>
                    <span v-if="!comment.isOdd">
                      {{ comment.name }} &mdash;
                    </span>
                    {{ comment.dateTime }}
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="comment-section">
          <v-form>
            <v-row no-gutters>
              <v-col cols="10">
                <v-textarea
                  hide-details
                  rows="2"
                  label="Your Message"
                  name="name"
                  outlined
                  v-model="comment"
                  v-on:keyup.enter="postComment('add')"
                  @focus="onFocusCmtBox()"
                ></v-textarea>
              </v-col>
              <v-col cols="2">
                <v-btn
                  fab
                  text
                  color="info"
                  @click="postComment('add')"
                  class="mt-2"
                >
                  <v-icon large>mdi-send</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-btn @click="pickFile" text small>
                  <v-icon>mdi-attachment</v-icon>
                  {{ attachmentName }}
                </v-btn>

                <input
                  type="file"
                  style="display: none"
                  ref="cmtFile"
                  accept=".zip, .jpeg, .jpg, .png"
                  @change="onFilePicked"
                />
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
//import EventBus from "@/eventBus";
import axios from "axios";
import Pusher from "pusher-js";
const BASE_URL = process.env.VUE_APP_API_BASE_URL;
const IMAGE_URL = process.env.VUE_APP_API_IMAGE_URL;
const SOURCE_URL = process.env.VUE_APP_API_SOURCE_URL;
const PUSHER_KEY = process.env.VUE_APP_PUSHER_API_KEY;
const PUSHER_CLUSTER = process.env.VUE_APP_PUSHER_API_CLUSTER;
const PUSHER_CHAT_CHNL = process.env.VUE_APP_PUSHER_TEAM_CHAT_CHANNEL;
const PUSHER_MESSAGE_EVENT = process.env.VUE_APP_PUSHER_MESSAGE_EVENT;

export default {
  props: {
    taskDetail: Object,
  },
  data() {
    return {
      loading: false,
      dialog: false,
      userInfo: {},
      action: "get",
      commentList: [],
      totalComments: 0,
      comment: "",
      commentBox: null,
      isFocusedCmtBox: false,
      attachmentName: "Upload Files",
      isUploadedNewFile: false,
      commentsHeight: 0,
      usernameWidth: 0,
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  methods: {
    initPuserApi() {
      console.log(
        "PUSHER_API: ",
        PUSHER_KEY,
        ", Pusher Chat Channel: ",
        PUSHER_CHAT_CHNL,
        ", Pusher Message Event: ",
        PUSHER_MESSAGE_EVENT
      );

      const vm = this;
      // Enable pusher logging - don't include this in production
      Pusher.logToConsole = true;
      var pusher = new Pusher(PUSHER_KEY, {
        cluster: PUSHER_CLUSTER,
      });
      var channel = pusher.subscribe(PUSHER_CHAT_CHNL);
      channel.bind(PUSHER_MESSAGE_EVENT, function(data) {
        vm.pusherMessage = data;
        const routeName = vm.$router.currentRoute.name;
        console.log(routeName);
        console.log("Load Manager side team chat message outisde");
        if (vm.pusherMessage.notification.for == vm.userInfo.type) {
          if (vm.pusherMessage.notification.type == "task") {
            if (vm.dialog) {
              vm.loadComments("get");
            } else {
              vm.showNotification(
                vm.pusherMessage.notification.message,
                vm.pusherMessage.notification.icon
              );
            }
          }
          //EventBus.$emit("new-notification", true);
          console.log("Load Manager side team chat message");
          // vm.notifyData(vm.pusherMessage);
        }
        console.log(vm.pusherMessage);
      });
    },
    showNotification(message, profileImage) {
      const notification = new Notification("New message from team", {
        body: message,
        icon: IMAGE_URL + profileImage,
      });
      console.log(notification);
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      this.commentsHeight = this.window.height - 170;
    },
    downloadAttachment(filePath) {
      axios({
        method: "get",
        url: SOURCE_URL + filePath,
        responseType: "arraybuffer",
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const filename = filePath.split("/").pop();
          link.setAttribute("download", filename); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => console.log("error occured"));
    },
    loadComments(action) {
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("taskId", vm.taskDetail.id);
      formData.append("teamId", vm.taskDetail.assignTo.id);
      formData.append("managerId", vm.userInfo.id);

      axios
        .post(BASE_URL + "manager/taskComment.php?action=" + action, formData)
        .then(function(response) {
          vm.loading = false;
          console.log(response);
          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            if (vm.totalComments !== response.data.count) {
              vm.commentList = response.data.list;
              vm.totalComments = response.data.count;
              vm.scrollToBottom();
            }
          }
        })
        .catch(function(error) {
          vm.loading = false;
          console.log(error);
        });
    },
    postComment(action) {
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("taskId", vm.taskDetail.id);
      formData.append("teamId", vm.taskDetail.assignTo.id);
      formData.append("managerId", vm.userInfo.id);
      formData.append("comment", vm.comment);
      if (vm.$refs.cmtFile.files.length > 0) {
        vm.isUploadedNewFile = true;
        formData.append("attachment", vm.$refs.cmtFile.files[0]);
      }
      vm.comment = "";
      axios
        .post(BASE_URL + "manager/taskComment.php?action=" + action, formData)
        .then(function(response) {
          vm.loading = false;
          console.log(response);
          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            if (vm.totalComments !== response.data.count) {
              vm.commentList = response.data.list;
              vm.totalComments = response.data.count;
              vm.scrollToBottom();
              vm.attachmentName = "Upload Files";
              if (vm.isUploadedNewFile) {
                vm.loadFiles("get");
                vm.isUploadedNewFile = false;
              }
            }
          }
        })
        .catch(function(error) {
          vm.loading = false;
          console.log(error);
        });
    },
    scrollToBottom() {
      console.log("Scroll Height:", this.commentBox.scrollHeight);

      this.commentBox.scrollTop = this.commentBox.scrollHeight;
    },
    pickFile() {
      this.$refs.cmtFile.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      console.log(files[0].name);
      if (files[0] !== undefined) {
        this.attachmentName = files[0].name;
        if (this.attachmentName.lastIndexOf(".") <= 0) {
          return;
        }
      }
    },
    onWindowFocus() {
      console.log("window focus");
      /* this.updateTimer = setInterval(() => {
        this.updateComments();
      }, 2000);*/
    },
    onWindowBlur() {
      console.log("window Blur");
      // clearInterval(this.updateTimer);
    },
    updateComments() {
      console.log("Comment Updates");
      this.loadComments("get");
    },
    onFocusCmtBox() {
      console.log("Comment Box in focus", this.isFocusedCmtBox);
    },
    showDialog() {
      if (this.dialog) {
        this.dialog = false;
      } else {
        this.dialog = true;
        this.loadComments("get");
      }
    },
  },
  computed: {},
  mounted() {
    this.action = "get";
    this.commentBox = this.$refs.commentBoxIns;
  },
  created() {
    this.userInfo = this.$store.state.userlogged;
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.initPuserApi();
  },
  beforeDestroy() {
    window.removeEventListener("focus", this.onWindowFocus);
    window.removeEventListener("blur", this.onWindowBlur);
    //clearInterval(this.updateTimer);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    commentList() {
      console.log("comment list has changed!");
      // Auto Scroll to bottom for first time
      setTimeout(() => {
        this.scrollToBottom();
      }, 500);
    },
  },
};
</script>
<style scoped>
.float-position {
  position: fixed;
  right: 0px;
  bottom: 0px;
  z-index: 9999;
}
.float-btn {
  z-index: 8888;
  position: fixed !important;
  right: 10px;
  bottom: 65px;
}

.msg-con {
  min-height: 300px;
  max-height: min-content;
  display: block;
  width: 100%;
}
#chat-messages {
  display: block;
  transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
}
#chat-messages .label {
  font-size: 12px;
  clear: both;
}
#chat-messages div.message {
  padding: 0 0 30px 40px;
  clear: both;
  margin-bottom: 20px;
}
#chat-messages .message .img {
  float: left;
  margin-left: -38px;
  border-radius: 50%;
  width: 30px !important;
  height: 30px !important;
  margin-top: 12px;
}
.message .bubble {
  background: #f0f4f7;
  font-size: 13px;
  font-weight: 600;
  padding: 12px 13px;
  border-radius: 5px 5px 5px 0px;
  color: #8495a3;
  position: relative;
  float: left;
}
.message .date-time {
  font-size: 12px;
  display: block;
  clear: both;
  float: left;
}

#chat-messages div.message.right {
  padding: 0 58px 30px 0;
  margin-right: -19px;
  margin-left: 19px;
}
#chat-messages div.message.right .img {
  float: right;
  margin-left: 0;
  margin-right: -38px;
}
#chat-messages div.message.right .bubble {
  float: right;
  border-radius: 5px 5px 0px 5px;
  background: #dcffff;
}
#chat-messages div.message.right .date-time {
  float: right;
}

.vb > .vb-dragger {
  z-index: 5;
  width: 12px;
  right: 0;
}

.vb > .vb-dragger > .vb-dragger-styler {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: rotate3d(0, 0, 0, 0);
  transform: rotate3d(0, 0, 0, 0);
  -webkit-transition: background-color 100ms ease-out, margin 100ms ease-out,
    height 100ms ease-out;
  transition: background-color 100ms ease-out, margin 100ms ease-out,
    height 100ms ease-out;
  background-color: rgba(48, 121, 244, 0.1);
  margin: 5px 5px 5px 0;
  border-radius: 20px;
  height: calc(100% - 10px);
  display: block;
}

.vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.3);
}

.vb > .vb-dragger:hover > .vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.5);
  margin: 0px;
  height: 100%;
}

.vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.5);
  margin: 0px;
  height: 100%;
}

.vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.5);
}
.close-btn {
  position: absolute;
  right: 10px;
}
.chat-window {
  max-width: 415px;
}
</style>
