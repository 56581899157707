<template>
  <div class="task">
    <h1
      v-if="taskInfo.projectInfo"
      class="title ml-4 pointer d-inline-flex"
      @click="routeTo('manageProject', taskInfo.projectInfo.id)"
    >
      <v-icon color="grey">mdi-arrow-left</v-icon>
      {{ taskInfo.projectInfo.shortName }}
    </h1>
    <v-container class="my-1">
      <ViewBug />

      <v-row no-gutters class="mb-3">
        <v-col cols="12">
          <!-- for Project Detial -->
          <v-card flat tile>
            <v-row no-gutters :class="`pa-3 task ${taskInfo.priority}`">
              <v-col cols="10" class="mb-2">
                <div class="headline">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-avatar
                        size="32"
                        v-on="on"
                        color="grey"
                        class="img-border"
                      >
                        <img
                          :src="taskInfo.assignTo.profileImage"
                          v-if="taskInfo.assignTo.profileImage != ''"
                          :alt="taskInfo.assignTo.name"
                        />
                        <span v-else class="white--text headline">{{
                          taskInfo.assignTo.name.substring(0, 1)
                        }}</span>
                      </v-avatar>
                    </template>
                    <span>{{ taskInfo.assignTo.name }}</span>
                  </v-tooltip>
                  {{ taskInfo.title }}
                  <v-chip
                    small
                    :class="`${taskInfo.status} white--text caption my-2 ml-2 `"
                    >{{ taskInfo.status }}</v-chip
                  >
                </div>
              </v-col>

              <v-col cols="2">
                <div class="float-right">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn text icon v-on="on" color="grey">
                        <v-icon color="grey darken-1">mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="link in taskStatusOptions"
                        :key="link.title"
                        @click="setTaskStaus(taskInfo.id, link.action)"
                      >
                        <v-list-item-title>{{ link.title }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </v-col>
              <v-col cols="6" sm="3" md="2" class="mb-2">
                <div class="caption grey--text">Start Date</div>
                <div class="subheading">
                  {{ formattedDate(taskInfo.startDate) }}
                </div>
              </v-col>

              <v-col cols="6" sm="3" md="3" class="mb-2">
                <div class="caption grey--text">Remaining Days</div>
                <div class="subheading">{{ taskInfo.daysLeft }}</div>
              </v-col>
              <v-col cols="6" sm="3" md="3" class="mb-2">
                <div class="caption grey--text">Attachment</div>
                <div class="subheading" v-if="taskInfo.attachment != ''">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        color="grey lighten-1"
                        class="pointer"
                        @click="downloadAttachment(taskInfo.attachment)"
                        v-on="on"
                        >mdi-file-download-outline</v-icon
                      >
                    </template>
                    <span>Download</span>
                  </v-tooltip>
                </div>
                <div v-else class="mt-1">None</div>
              </v-col>
              <v-col cols="6" sm="3" md="3" class="mb-2">
                <div class="caption grey--text">Priority</div>
                <div class="subheading">
                  <v-chip
                    small
                    label
                    :class="`${taskInfo.priority} white--text`"
                    >{{ taskInfo.priority }}</v-chip
                  >
                </div>
              </v-col>

              <v-col cols="12" class="mb-2">
                <div class="caption grey--text">Detail</div>
                <div v-html="taskInfo.desc"></div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-card>

          <v-row no-gutters>
            <v-col cols="12" lg="6">
              <v-card flat tile class="pa-2 mt-1">
                <div class="title pl-2 my-3">
                  <div class="d-inline">
                    Bugs
                    <AddBug
                      v-if="taskInfo.projectInfo"
                      :projectId="taskInfo.projectInfo.id"
                      :taskInfo="taskInfo"
                    />
                  </div>
                  <div class="d-inline float-right">
                    <v-btn-toggle v-model="toggle_bugs">
                      <v-btn text small @click="bugsFilterBy = 'pending'"
                        >Pending</v-btn
                      >
                      <v-btn text small @click="bugsFilterBy = 'in-review'"
                        >In Review</v-btn
                      >
                      <v-btn text small @click="bugsFilterBy = 'fixed'"
                        >Fixed</v-btn
                      >
                    </v-btn-toggle>
                  </div>
                </div>
                <div class="bug-list" v-bar>
                  <div class="el2">
                    <v-list two-line>
                      <v-row v-if="filteredBugs.length < 1">
                        <v-col cols="12" class="text-center">
                          <v-card flat tile color="grey--text">
                            There is no item in list.</v-card
                          >
                        </v-col>
                      </v-row>
                      <template v-for="(bug, index) in filteredBugs">
                        <v-subheader :key="bug.id">
                          {{ bug.dateLabel }}
                          <v-chip
                            small
                            label
                            :class="`${bug.status}`"
                            class="mx-1"
                            >{{ bug.status }}</v-chip
                          >
                          <v-chip
                            small
                            v-if="bug.attachment != ''"
                            class="attachment"
                            @click="downloadAttachment(bug.attachment)"
                          >
                            <v-icon>mdi-attachment</v-icon>
                          </v-chip>
                        </v-subheader>
                        <v-list-item :key="`${bug.id}-bug-avatar`" avatar>
                          <v-list-item-content
                            @click="viewBug(bug)"
                            class="pointer"
                          >
                            <div :class="`status-${bug.status}`">
                              <v-list-item-title
                                v-html="bug.title"
                              ></v-list-item-title>
                              <v-list-item-subtitle
                                v-html="bug.desc"
                              ></v-list-item-subtitle>
                            </div>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-menu offset-y>
                              <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" color="grey">
                                  <v-icon color="grey darken-1"
                                    >mdi-dots-vertical</v-icon
                                  >
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="link in bugStatusOptions"
                                  :key="`${link.title}-bug-options`"
                                  @click="setBugStatus(link.action, bug.id)"
                                >
                                  <v-list-item-title>{{
                                    link.title
                                  }}</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider
                          :key="`${bug.id}-bug-desc`"
                          v-if="index < filteredBugs.length - 1"
                        ></v-divider>
                      </template>
                    </v-list>
                  </div>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" lg="6">
              <v-card flat tile class="ml-md-1 pa-2 mt-1">
                <div class="title pl-2 my-3">Attachments</div>
                <div class="bug-list" v-bar>
                  <div class="el2">
                    <v-list two-line>
                      <template v-for="(file, index) in filesList">
                        <v-subheader :key="file.index">{{
                          file.date
                        }}</v-subheader>

                        <v-list-item :key="file.id">
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="file.title"
                            ></v-list-item-title>
                            <v-list-item-subtitle
                              v-html="file.uploadedBy"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  icon
                                  ripple
                                  v-on="on"
                                  @click="downloadAttachment(file.attachment)"
                                >
                                  <v-icon color="grey lighten-1"
                                    >mdi-file-download-outline</v-icon
                                  >
                                </v-btn>
                              </template>
                              <span>Download</span>
                            </v-tooltip>
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider
                          v-if="index < filesList.length - 1"
                          :key="file.id"
                        ></v-divider>
                      </template>
                    </v-list>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <div class="text-xs-center mt-4" v-if="totalItems > 1">
        <v-pagination
          v-model="page"
          :length="totalItems"
          :total-visible="7"
          @input="getPageData"
        ></v-pagination>
      </div>
      <TaskChatBox v-bind:taskDetail="taskInfo" />
    </v-container>
  </div>
</template>
<script>
import axios from "axios";
import EventBus from "@/eventBus";
import ViewBug from "@/components/manager/ViewBug";
import AddBug from "@/components/manager/AddBug";
import TaskChatBox from "@/components/manager/TaskChatBox";
import { format } from "date-fns";
const BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
  data() {
    return {
      id: 0,
      title: "Project Title",
      loading: false,
      message: "",
      taskInfo: {},
      userInfo: {},
      avatarSize: 60,
      tile: false,
      page: 1,
      bugList: [],
      action: "getSingle",
      totalItems: 0,
      optionLinks: [
        { title: "Hide", action: "hide" },
        { title: "Show", action: "show" },
      ],
      toggle_bugs: 0,
      bugsFilterBy: "pending",
      filesList: [],
      attachmentName: "Upload Files",
      isUploadedNewFile: false,
      taskStatusOptions: [
        { title: "Idle", action: "1" },
        { title: "In Progress", action: "2" },
        { title: "Done", action: "3" },
        { title: "In Review", action: "7" },
        { title: "Tested", action: "8" },
        { title: "ReOpen", action: "9" },
      ],
      bugStatusOptions: [
        { title: "In Progress", action: "2" },
        { title: "Mark as fixed", action: "3" },
        { title: "Reopen", action: "9" },
      ],
    };
  },
  components: { ViewBug, AddBug, TaskChatBox },

  computed: {
    filteredBugs() {
      const filterBy = this.bugsFilterBy.toLowerCase().trim();
      if (!filterBy) return this.bugList;
      if (filterBy == "pending") {
        return this.bugList.filter(
          (c) =>
            c.status.toLowerCase() == "working" ||
            c.status.toLowerCase() == "idle" ||
            c.status.toLowerCase() == "reopen"
        );
      } else if (filterBy == "in-review") {
        return this.bugList.filter(
          (c) =>
            c.status.toLowerCase() == "unrecognized" ||
            c.status.toLowerCase() == "in-review"
        );
      } else {
        return this.bugList.filter((c) => c.status.toLowerCase() == "fixed");
      }
    },
  },
  created() {
    this.id = this.$route.params.id;
    console.log("task Id:" + this.id);
    this.userInfo = this.$store.state.userlogged;
    this.loadTaskInfo(this.action);
    this.loadBugList("getByTask", true);
    this.loadFiles("get");
  },
  mounted() {
    EventBus.$on("project-added", (error) => {
      console.log(error);
      this.page = 1;
      this.loadTaskInfo("getSingle");
    });
    EventBus.$on("project-update", (error) => {
      console.log(error);
      this.loadTaskInfo("getSingle");
    });
    EventBus.$on("bug-added", (error) => {
      console.log(error);
      this.loadBugList("getByTask", true);
    });
    EventBus.$on("bug-update", (error) => {
      console.log(error);
      this.loadBugList("getByTask", true);
    });
    console.log("mounted");
  },
  methods: {
    loadTaskInfo(action) {
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("taskId", this.id);
      console.log(this.userInfo.fname);
      axios
        .post(BASE_URL + "manager/task.php?action=" + action, formData)
        .then(function (response) {
          vm.loading = false;
          console.log(response);
          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            vm.taskInfo = response.data.list;
            vm.title = vm.taskInfo.title;
          }
        })
        .catch(function (error) {
          vm.loading = false;
          console.log(error);
        });
    },
    loadBugList(action, reset) {
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("taskId", this.id);

      axios
        .post(BASE_URL + "manager/bug.php?action=" + action, formData)
        .then(function (response) {
          vm.loading = false;
          console.log(response);
          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            vm.bugList = response.data.list;
            if (reset) {
              vm.bugsFilterBy = "pending";
              vm.toggle_bugs = 0;
            }
          }
        })
        .catch(function (error) {
          vm.loading = false;
          console.log(error);
        });
    },
    setBugStatus(status, bugId) {
      console.log(status, bugId);
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("bugId", bugId);
      formData.append("status", status);
      formData.append("managerId", this.userInfo.id);
      axios
        .post(BASE_URL + "manager/bug.php?action=update", formData)
        .then(function (response) {
          vm.loading = false;
          console.log(response);
          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            vm.loadBugList("getByTask", false);
          }
        })
        .catch(function (error) {
          vm.loading = false;
          console.log(error);
        });
    },
    loadFiles(action) {
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("taskId", this.id);
      formData.append("managerId", this.userInfo.id);

      axios
        .post(BASE_URL + "manager/file.php?action=" + action, formData)
        .then(function (response) {
          vm.loading = false;
          console.log(response);
          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            vm.filesList = response.data.list;
          }
        })
        .catch(function (error) {
          vm.loading = false;
          console.log(error);
        });
    },
    getPageData(page) {
      console.log(page);
      this.loadTaskInfo(page, this.action);
    },
    sortBy(prop) {
      this.taskInfo.sort((a, b) => (a[prop] < b[prop] ? -1 : 1));
    },
    trackAction(action, trackId) {
      console.log(action, trackId);
    },
    routeTo(route, param) {
      console.log("Hi", param);
      this.$router.back();
    },
    formattedDate(date) {
      if (date == "0000-00-00") {
        return "Not assigned yet";
      } else {
        return format(new Date(date), "dd MMM, yyyy");
      }
    },
    viewBug(data) {
      console.log(data.id);
      EventBus.$emit("view-bug", data);
    },
    updateBug(bugId, bugStatus) {
      const vm = this;
      var formData = new FormData();
      formData.append("bugId", bugId);
      formData.append("projectId", vm.id);
      formData.append("status", "3");
      var action = "update";
      if (bugStatus == "idle") {
        action = "delete";
      }
      axios
        .post(BASE_URL + "client/bug.php?action=" + action, formData)
        .then(function (response) {
          vm.loading = false;
          console.log(response);
          vm.message = response.data.message;
          if (!response.data.error) {
            vm.loadBugList("getByTask", false);
          }
          EventBus.$emit("display-message", vm.message);
        })
        .catch(function (error) {
          vm.loading = false;
          console.log(error);
        });
    },
    downloadAttachment(filePath) {
      //window.location = BASE_URL + "manager/download.php?action=download&file=" + filePath;
      /*window.open(
        BASE_URL + "client/download.php?action=download&file=" + filePath
      );*/

      axios({
        method: "get",
        url: "http://webapp.digitalguys.ca/source/" + filePath,
        responseType: "arraybuffer",
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const filename = filePath.split("/").pop();
          link.setAttribute("download", filename); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => console.log("error occured"));
    },
    getFileExtension(filename) {
      return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
    },
    isValidStatus(status) {
      if (status == "idle" || status == "tested") {
        return false;
      } else {
        return true;
      }
    },
    setTaskStaus(taskId, status) {
      const vm = this;
      var formData = new FormData();
      formData.append("taskId", taskId);
      formData.append("status", status);
      formData.append("managerId", this.userInfo.id);
      axios
        .post(BASE_URL + "manager/task.php?action=update", formData)
        .then(function (response) {
          vm.loading = false;
          console.log(response);
          vm.message = response.data.message;
          if (!response.data.error) {
            vm.loadTaskInfo("getSingle");
          }
          EventBus.$emit("display-message", vm.message);
        })
        .catch(function (error) {
          vm.loading = false;
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.task.high {
  border-top: 4px solid red;
  border-left: none;
}
.task.normal {
  border-top: 4px solid lightseagreen;
  border-left: none;
}
.task.low {
  border-top: 4px solid orange;
  border-left: none;
}
.v-chip.Idle {
  background: orange;
}
.v-chip.active {
  background: #3dc1c2;
}
.v-chip.inactive {
  background: tomato;
}
.v-chip.done {
  background: green;
  color: white;
}
.v-chip.working {
  background: #3dc1c2;
}
.v-chip.inReview {
  background: #26c6da;
}
.v-chip.inProgress {
  background: yellowgreen;
}
.v-chip.tested {
  background: #90a4ae;
}
.v-chip.idle {
  background: orange;
}
.v-chip.fixed {
  background: lightgray;
}

.v-chip.high {
  background: red;
  color: white;
}
.v-chip.normal {
  background: lightseagreen;
}
.v-chip.low {
  background: orange;
}
.msg-con {
  height: 100ovh;
  min-height: 300px;
  display: block;
}
.bug-list {
  max-height: 500px;
  min-height: 100px;
  width: 100%;
}
.bug-list .status-fixed {
  text-decoration: line-through;
  opacity: 0.8;
}
.v-chip.working {
  background: #3dc1c2;
}
.v-chip.idle {
  background: orange;
}
.v-chip.fixed {
  background: lightgray;
}
.v-chip.reopen {
  background: red;
  color: white;
}
.msg-con {
  height: 500px;
  width: 100%;
}
.attachment {
  cursor: pointer;
}
.pointer {
  cursor: pointer;
}
/*.oddClass {
  text-align: -moz-right;
  text-align: -webkit-right;
}
.oddClass .message {
  width: fit-content;
  padding: 0.8rem;
  margin-bottom: 0.4rem;
  background-color: #3dc1c2;
}

.evenClass {
  text-align: -moz-left;
  text-align: -webkit-left;
}
*/
#chat-messages {
  display: block;
  transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
}
#chat-messages .label {
  clear: both;
}
#chat-messages div.message {
  padding: 0 0 30px 58px;
  clear: both;
  margin-bottom: 20px;
}
#chat-messages .message .img {
  float: left;
  margin-left: -38px;
  border-radius: 50%;
  width: 30px !important;
  height: 30px !important;
  margin-top: 12px;
}
.message .bubble {
  background: #f0f4f7;
  font-size: 13px;
  font-weight: 600;
  padding: 12px 13px;
  border-radius: 5px 5px 5px 0px;
  color: #8495a3;
  position: relative;
  float: left;
}
.message .date-time {
  font-size: 12px;
  display: block;
  clear: both;
  float: left;
}

#chat-messages div.message.right {
  padding: 0 58px 30px 0;
  margin-right: -19px;
  margin-left: 19px;
}
#chat-messages div.message.right .img {
  float: right;
  margin-left: 0;
  margin-right: -38px;
}
#chat-messages div.message.right .bubble {
  float: right;
  border-radius: 5px 5px 0px 5px;
  background: #dcffff;
}
#chat-messages div.message.right .date-time {
  float: right;
}

.vb > .vb-dragger {
  z-index: 5;
  width: 12px;
  right: 0;
}

.vb > .vb-dragger > .vb-dragger-styler {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: rotate3d(0, 0, 0, 0);
  transform: rotate3d(0, 0, 0, 0);
  -webkit-transition: background-color 100ms ease-out, margin 100ms ease-out,
    height 100ms ease-out;
  transition: background-color 100ms ease-out, margin 100ms ease-out,
    height 100ms ease-out;
  background-color: rgba(48, 121, 244, 0.1);
  margin: 5px 5px 5px 0;
  border-radius: 20px;
  height: calc(100% - 10px);
  display: block;
}

.vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.3);
}

.vb > .vb-dragger:hover > .vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.5);
  margin: 0px;
  height: 100%;
}

.vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.5);
  margin: 0px;
  height: 100%;
}

.vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.5);
}
.img-border {
  border: 2px solid #1976d2;
}
</style>
