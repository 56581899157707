<template>
  <div class="managerProject">
    <h1 class="title ml-4 pointer d-inline-flex" @click="routeTo('manageProjects', null)">
      <v-icon color="grey">mdi-arrow-left</v-icon>Projects
    </h1>
    <v-container class="my-0" id="project-parent-container">
      <ViewBug />
      <ViewTask />
      <ViewPlan />
      <AssignBug />
      <ViewNote />

      <v-row no-gutters class="mb-3">
        <v-col cols="12">
          <!-- for Project Detial -->
          <v-card flat tile>
            <v-row no-gutters :class="`pa-2 project ${projectInfo.status}`">
              <v-col cols="10" class="mb-2">
                <div class="headline">
                  {{ projectInfo.title }}
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-chip small v-on="on" :class="`${projectInfo.status} white--text caption my-2 `">{{
                        projectInfo.status }}</v-chip>
                    </template>
                    <v-list>
                      <v-list-item v-for="link in projectLinks" :key="link.title"
                        @click="setProjectStatus(link.action, projectInfo.id)">
                        <v-list-item-title>{{ link.title }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </v-col>

              <v-col cols="2">
                <div class="float-right">
                  <v-btn text icon color="grey" @click="editProject(projectInfo)">
                    <v-icon color="grey darken-1">mdi-pencil-outline</v-icon>
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="6" md="2" class="mb-2">
                <div class="caption grey--text">Start Date</div>
                <div class="subheading">
                  {{ formattedDate(projectInfo.startDate) }}
                </div>
              </v-col>

              <v-col cols="6" md="2" class="mb-2">
                <div class="caption grey--text">Remaining Days</div>
                <div class="subheading">{{ projectInfo.remainingDays }}</div>
              </v-col>
              <v-col cols="4" sm="6" md="2" class="mb-2">
                <div class="caption grey--text">Bugs</div>
                <div class="subheading pointer" @click="showBugs()">
                  {{ projectInfo.bugs }}
                </div>
              </v-col>

              <v-col cols="4" sm="6" md="2" class="mb-2">
                <div class="caption grey--text">Files</div>
                <div class="subheading pointer" @click="showFiles()">
                  Show Files
                </div>
              </v-col>
              <v-col cols="4" sm="6" md="2" class="mb-2">
                <div class="caption grey--text">Planer</div>
                <div class="subheading pointer" @click="showPlaner()">
                  {{ projectInfo.planer }}
                </div>
              </v-col>
              <v-col cols="4" sm="6" md="1" class="mb-2">
                <div class="caption grey--text">Notes</div>
                <div class="subheading pointer" @click="showNotes()">
                  <v-icon>mdi-note-outline</v-icon>
                </div>
              </v-col>

              <v-col cols="3" sm="2" md="1">
                <div>
                  <v-progress-circular :rotate="360" :size="56" :width="4" :value="projectInfo.progress" color="teal">{{
                    projectInfo.progress }}%</v-progress-circular>
                </div>
              </v-col>

              <v-col cols="12" class="mb-2">
                <div class="caption grey--text">
                  Detail
                  <a @click="showDetail = !showDetail"><v-icon>{{
                    showDetail ? "mdi-chevron-up" : "mdi-chevron-down"
                  }}</v-icon></a>
                </div>
                <div v-html="projectInfo.detail" v-if="showDetail"></div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-card>

          <v-row no-gutters class="mt-1">
            <v-col cols="12">
              <v-card flat tile class="pa-2">
                <div class="title pl-2">
                  <div class="d-inline">
                    Task
                    <AddTask :projectId="id" :clientId="projectInfo.client.id" />
                  </div>
                  <div class="d-inline task-filter-by-team pa-2">
                    <v-select :items="teamList" label="Team Filter" item-text="name" item-value="id"
                      @change="filterByTeam" return-object single-line dense hide-details="true" outlined></v-select>
                  </div>
                  <div class="d-inline float-right pa-2">
                    <v-btn-toggle v-model="toggle_task">
                      <v-btn text small @click="taskFilterBy = ''">All</v-btn>
                      <v-btn text small @click="taskFilterBy = 'pending'">Pending</v-btn>
                      <v-btn text small @click="taskFilterBy = 'done'">Done</v-btn>
                      <v-btn text small @click="taskFilterBy = 'inreview'">inReview</v-btn>
                      <v-btn text small @click="taskFilterBy = 'tested'">Tested</v-btn>
                      <v-btn text small @click="taskFilterBy = 'reopen'">Reopen</v-btn>
                    </v-btn-toggle>
                  </div>
                </div>
                <div class="vue-bar-class task-list" v-bar>
                  <div class="task-list-wrapper">
                    <template>
                      <v-row v-if="filteredTask.length < 1">
                        <v-col cols="12" class="text-center">
                          <v-card flat tile color="grey--text">
                            There is not item in list.</v-card>
                        </v-col>
                      </v-row>
                      <v-row no-gutters v-for="(task, index) in filteredTask"
                        :class="`pa-2 task-item ${task.priority} ${task.status} mb-1`" :key="`${index}-task`">
                        <v-col cols="10" md="4" lg="3" @click="routePushTo('managerTask', task.id)">
                          <div class="caption grey--text">Task</div>
                          <div class="pointer mb-2">{{ task.title }}</div>
                        </v-col>
                        <v-col cols="2" sm="3" md="1" lg="1" class="text-xs-left">
                          <div class="caption grey--text">Assign</div>
                          <div>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-avatar size="38" v-on="on" color="grey">
                                  <img :src="task.assignTo.profileImage" v-if="task.assignTo.profileImage != ''"
                                    :alt="task.assignTo.name" />
                                  <span v-else class="white--text headline">{{
                                    task.assignTo.name.substring(0, 1)
                                  }}</span>
                                </v-avatar>
                              </template>
                              <span>{{ task.assignTo.name }}</span>
                            </v-tooltip>
                          </div>
                        </v-col>
                        <v-col cols="2" sm="3" md="1" lg="1" class="text-xs-left">
                          <div class="caption grey--text">Chat</div>
                          <div>
                            <v-icon @click="routePushTo('managerTask', task.id)"
                              class="mt-2 pointer">mdi-chat-outline</v-icon>
                          </div>
                        </v-col>

                        <v-col cols="10" sm="6" md="2" lg="2" class="text-xs-left text-md-center">
                          <div class="caption grey--text">Timeline</div>
                          <div class="mb-2">
                            <v-chip label small>{{ formattedDate(task.startDate) }} -
                              {{ formattedDate(task.dueDate) }}</v-chip>
                          </div>
                        </v-col>
                        <v-col cols="4" sm="6" md="1" lg="2" class="text-xs-left text-md-center">
                          <div class="caption grey--text">Day(s) left</div>
                          <div>
                            <v-chip small label class="ml-0" :color="task.daysLeft < 5
                                ? 'red lighten-1 white--text'
                                : 'grey black--text'
                              ">{{ task.daysLeft }} day(s)</v-chip>
                          </div>
                        </v-col>

                        <v-col cols="3" md="2" lg="1" class="text-xs-center">
                          <div class="caption grey--text">Priority</div>
                          <div>
                            <v-menu offset-y>
                              <template v-slot:activator="{ on }">
                                <v-chip v-on="on" small :class="`${task.priority} white--text`">{{ task.priority
                                  }}</v-chip>
                              </template>
                              <v-list>
                                <v-list-item v-for="link in priorityOptions" :key="link.title"
                                  @click="setTaskPriority(link.action, task.id)">
                                  <v-list-item-title>{{
                                    link.title
                                  }}</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </div>
                        </v-col>
                        <v-col cols="3" sm="3" md="1" lg="1" class="text-center">
                          <div class="caption grey--text">Status</div>
                          <div>
                            <v-menu offset-y>
                              <template v-slot:activator="{ on }">
                                <v-chip small v-on="on" :class="`${task.status} white--text`">{{ task.status }}</v-chip>
                              </template>
                              <v-list>
                                <v-list-item v-for="link in taskStatusOptions" :key="link.title"
                                  @click="setTaskStaus(link.action, task.id)">
                                  <v-list-item-title>{{
                                    link.title
                                  }}</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </div>
                        </v-col>
                        <v-col cols="2" md="1" lg="1" class="text-xs-center">
                          <div class="float-right mt-2">
                            <v-menu offset-y>
                              <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" color="grey">
                                  <v-icon color="grey darken-1">mdi-dots-vertical</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item v-for="link in taskMoreOption" :key="link.title"
                                  @click="taskAction(link.action, task)">
                                  <v-list-item-title>{{
                                    link.title
                                  }}</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </div>
                        </v-col>
                      </v-row>
                    </template>
                  </div>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" md="12" lg="4" v-if="displayPlaner" id="planerSection">
              <v-card flat tile class="pa-2 mt-1 mr-1 highlight-item">
                <div class="title pl-2">
                  Project Planner
                  <AddWBS :projectId="id" />
                </div>
                <div class="vue-bar-class" v-bar>
                  <div class="planer-list">
                    <v-row v-if="planList.length < 1">
                      <v-col cols="12" class="text-center">
                        <v-card flat tile color="grey--text">
                          There is not item in list.</v-card>
                      </v-col>
                    </v-row>
                    <v-list two-line>
                      <template v-for="(plan, index) in planList">
                        <v-list-item :key="`${plan.id}-plan`">
                          <v-list-item-content>
                            <v-list-item-title v-html="plan.title" @click="viewPlan(plan)"></v-list-item-title>
                            <v-list-item-subtitle>
                              <v-chip small label class="mr-1">
                                Tasks:
                                <span class="font-weight-bold ml-1">{{ plan.doneTask }}/{{
                                  plan.totalTask
                                }}</span>
                              </v-chip>
                              <v-chip small label class="mr-1">
                                Due:
                                <span class="font-weight-bold ml-1">{{
                                  formattedDate(plan.dueDate)
                                }}</span>
                              </v-chip>
                              <v-menu offset-y>
                                <template v-slot:activator="{ on }">
                                  <v-chip label small :class="plan.status" v-on="on">{{ plan.status }}</v-chip>
                                </template>
                                <v-list>
                                  <v-list-item v-for="link in planLinks" :key="`${link.title}-plan-options`"
                                    @click="setPlanStatus(link.action, plan.id)">
                                    <v-list-item-title>{{
                                      link.title
                                    }}</v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn text icon color="grey" @click="editPlan(plan)">
                              <v-icon color="grey darken-1">mdi-pencil-outline</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider v-if="index < planList.length - 1" :key="`${plan.id}-plan-divider`"></v-divider>
                      </template>
                    </v-list>
                  </div>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" md="12" lg="4" v-if="displayBugs" id="bugsSection">
              <v-card flat tile class="pa-2 mt-1 mr-1 highlight-item">
                <div class="title pl-2">
                  <div class="d-inline">
                    Bugs
                    <AddBug :projectId="id" />
                  </div>
                  <div class="d-inline float-right pa-2">
                    <v-btn-toggle v-model="toggle_bugs">
                      <v-btn text small @click="bugsFilterBy = 'pending'">Pending</v-btn>
                      <v-btn text small @click="bugsFilterBy = 'in-review'">In Review</v-btn>
                      <v-btn text small @click="bugsFilterBy = 'fixed'">Fixed</v-btn>
                    </v-btn-toggle>
                  </div>
                </div>
                <div class="vue-bar-class" v-bar>
                  <div class="bugs-list-wrapper">
                    <v-row v-if="filteredBugs.length < 1">
                      <v-col cols="12" class="text-center">
                        <v-card flat tile color="grey--text">
                          There is not item in list.</v-card>
                      </v-col>
                    </v-row>
                    <v-list two-line>
                      <template v-for="bug in filteredBugs">
                        <v-subheader :key="`${bug.id}-bug`">
                          {{ bug.dateLabel }}
                          <v-chip small label :class="`${bug.status}`" class="mx-1">{{ bug.status }}</v-chip>
                          <v-chip small label v-if="bug.attachment != ''" class="attachment mr-1"
                            @click="downloadAttachment(bug.attachment)">
                            <v-icon>mdi-attachment</v-icon>
                          </v-chip>
                          <v-chip small label v-if="!bug.assignTo" @click="displayAssignTo(bug)">Assign</v-chip>

                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-chip small label v-on="on" class="ml-1 info">{{
                                bug.addedBy
                              }}</v-chip>
                            </template>
                            <span>Added By {{ bug.addedBy }}</span>
                          </v-tooltip>
                        </v-subheader>
                        <v-list-item :key="`${bug.title}-bug`">
                          <v-list-item-avatar v-if="bug.assignTo" color="grey">
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-img :src="bug.assignTo.profileImage" v-if="bug.assignTo.profileImage"
                                  v-on="on"></v-img>
                                <span v-else class="white--text headline">{{
                                  bug.assignTo.name.substring(0, 1)
                                }}</span>
                              </template>
                              <span>Assign to : {{ bug.assignTo.name }}</span>
                            </v-tooltip>
                          </v-list-item-avatar>
                          <v-list-item-content @click="viewBug(bug)" class="pointer">
                            <div :class="`status-${bug.status}`">
                              <v-list-item-title v-html="bug.title"></v-list-item-title>
                              <v-list-item-subtitle v-html="bug.desc"></v-list-item-subtitle>
                            </div>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-menu offset-y>
                              <template v-slot:activator="{ on }">
                                <v-btn text icon v-on="on" color="grey">
                                  <v-icon color="grey darken-1">mdi-dots-vertical</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item v-for="link in bugLinks" :key="`${link.title}-bug-options`"
                                  @click="setBugStatus(link.action, bug.id)">
                                  <v-list-item-title>{{
                                    link.title
                                  }}</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider :key="`${bug.id}-bug-desc`"></v-divider>
                      </template>
                    </v-list>
                  </div>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" md="12" lg="4" v-if="displayFiles" id="filesSection">
              <v-card flat tile class="pa-2 mt-1 mr-1 highlight-item">
                <div class="title pl-2">Attachments</div>
                <div class="vue-bar-class" v-bar>
                  <div class="attachment-list-wrapper">
                    <v-row v-if="filesList.length < 1">
                      <v-col cols="12" class="text-center">
                        <v-card flat tile color="grey--text">
                          There is not item in list.</v-card>
                      </v-col>
                    </v-row>
                    <v-list two-line>
                      <template v-for="(file, index) in filesList">
                        <v-subheader :key="`${index}-file`">{{
                          file.date
                        }}</v-subheader>

                        <v-list-item :key="`${index}-file-avatar`">
                          <v-list-item-content>
                            <v-list-item-title v-html="file.title"></v-list-item-title>
                            <v-list-item-subtitle v-html="file.uploadedBy"></v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn icon ripple v-on="on" @click="downloadAttachment(file.attachment)">
                                  <v-icon color="grey lighten-1">mdi-file-download-outline</v-icon>
                                </v-btn>
                              </template>
                              <span>Download</span>
                            </v-tooltip>
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider v-if="index < filesList.length - 1" :key="`${index}-file-divider`"></v-divider>
                      </template>
                    </v-list>
                  </div>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" md="12" lg="4" v-if="displayNotes" id="notesSection">
              <v-card flat tile class="pa-2 mt-1 highlight-item">
                <div class="title pl-2">
                  Notes
                  <AddNote :projectId="id" />
                </div>
                <div class="vue-bar-class" v-bar>
                  <div class="notes-wrapper">
                    <v-row v-if="noteList.length < 1">
                      <v-col cols="12" class="text-center">
                        <v-card flat tile color="grey--text">
                          There is not item in list.</v-card>
                      </v-col>
                    </v-row>
                    <v-list two-line>
                      <template v-for="(note, index) in noteList">
                        <v-list-item :key="`${index}-file-avatar`" class="tile">
                          <v-list-item-content @click="viewNote(note)" class="pointer">
                            <v-list-item-title v-html="note.note"></v-list-item-title>
                            <v-list-item-subtitle v-html="note.addedOn"></v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn icon ripple v-on="on" @click="deleteNote(note.id)">
                                  <v-icon color="grey lighten-1">mdi-delete-outline</v-icon>
                                </v-btn>
                              </template>
                              <span>Delete</span>
                            </v-tooltip>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                    </v-list>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div class="text-xs-center mt-4" v-if="totalItems > 1">
        <v-pagination v-model="page" :length="totalItems" :total-visible="7" @input="getPageData"></v-pagination>
      </div>
      <ChatBox :projectId="id" />
    </v-container>
  </div>
</template>
<script>
import axios from "axios";
import EventBus from "@/eventBus";
import AddBug from "@/components/manager/AddBug";
import AddNote from "@/components/manager/AddNote";
import AddWBS from "@/components/manager/AddWBS";
import AddTask from "@/components/manager/AddTask";
import ViewBug from "@/components/manager/ViewBug";
import ViewNote from "@/components/manager/ViewNote";
import ViewTask from "@/components/manager/ViewTask";
import ViewPlan from "@/components/manager/ViewPlan";
import AssignBug from "@/components/manager/AssignBug";
import ChatBox from "@/components/manager/ChatBox";
import { format } from "date-fns";
const BASE_URL = process.env.VUE_APP_API_BASE_URL;
export default {
  data() {
    return {
      id: 0,
      showDetail: false,
      title: "Project Title",
      loading: false,
      message: "",
      projectInfo: {},
      userInfo: {},
      avatarSize: 60,
      tile: false,
      page: 1,
      bugList: [],
      action: "getSingle",
      totalItems: 0,
      projectLinks: [
        { title: "Idel", action: "1" },
        { title: "In Progress", action: "2" },
        { title: "Done", action: "3" },
        { title: "Cancel", action: "4" },
        { title: "On Hold", action: "5" },
      ],
      planLinks: [
        { title: "In Progress", action: "2" },
        { title: "Mark as done", action: "3" },
      ],

      bugLinks: [
        { title: "In Progress", action: "2" },
        { title: "Mark as fixed", action: "3" },
        { title: "Reopen", action: "9" },
      ],
      priorityOptions: [
        { title: "High", action: "1" },
        { title: "Normal", action: "2" },
        { title: "Low", action: "3" },
      ],
      taskStatusOptions: [
        { title: "Idle", action: "1" },
        { title: "In Progress", action: "2" },
        { title: "Done", action: "3" },
        { title: "In Review", action: "7" },
        { title: "Tested", action: "8" },
        { title: "ReOpen", action: "9" },
      ],
      taskMoreOption: [
        { title: "Edit", action: "edit" },
        { title: "Delete", action: "delete" },
      ],
      toggle_bugs: 0,
      bugsFilterBy: "pending",
      filesList: [],
      attachmentName: "Upload Files",
      updateTimer: null,
      taskList: [],
      planList: [],
      displayBugs: false,
      displayPlaner: false,
      displayFiles: false,
      displayNotes: false,
      toggle_task: 1,
      toggle_task_by_team: 1,
      taskFilterBy: "pending",
      noteList: [],
      teamList: [{ id: "-1", name: "All" }],
    };
  },
  components: {
    AddBug,
    ViewBug,
    ViewNote,
    AddWBS,
    AssignBug,
    AddTask,
    ViewTask,
    ViewPlan,
    ChatBox,
    AddNote,
  },

  computed: {
    filteredBugs() {
      const filterBy = this.bugsFilterBy.toLowerCase().trim();

      if (!filterBy) return this.bugList;
      if (filterBy == "pending") {
        return this.bugList.filter(
          (c) =>
            c.status.toLowerCase() == "working" ||
            c.status.toLowerCase() == "idle" ||
            c.status.toLowerCase() == "reopen"
        );
      } else if (filterBy == "in-review") {
        return this.bugList.filter(
          (c) =>
            c.status.toLowerCase() == "unrecognized" ||
            c.status.toLowerCase() == "in-review"
        );
      } else {
        return this.bugList.filter((c) => c.status.toLowerCase() == "fixed");
      }
    },
    filteredTask() {
      var tempTaskList = this.taskList;
      const filterBy = this.taskFilterBy.toLowerCase().trim();
      if (filterBy) if (!filterBy) return this.tempTaskList;
      if (this.isNumeric(filterBy)) {
        if (filterBy == -1) {
          return tempTaskList;
        } else {
          tempTaskList = this.taskList.filter((c) => c.assignTo.id == filterBy);
        }
      }
      if (filterBy == "pending") {
        return tempTaskList.filter(
          (c) =>
            c.status.toLowerCase() == "idle" ||
            c.status.toLowerCase() == "inprogress"
        );
      } else if (filterBy == "done") {
        return tempTaskList.filter((c) => c.status.toLowerCase() == "done");
      } else if (filterBy == "inreview") {
        return tempTaskList.filter((c) => c.status.toLowerCase() == "inreview");
      } else if (filterBy == "tested") {
        return tempTaskList.filter((c) => c.status.toLowerCase() == "tested");
      } else if (filterBy == "reopen") {
        return tempTaskList.filter((c) => c.status.toLowerCase() == "reopen");
      } else {
        return tempTaskList;
      }
    },
  },
  created() {
    this.id = this.$route.params.id;
    console.log("Project Id:" + this.id);
    this.userInfo = this.$store.state.userlogged;
    this.loadList(this.page, this.action);

    this.loadTasks("get");
    this.loadTeam(1, "get");
    window.addEventListener("focus", this.onWindowFocus);
    window.addEventListener("blur", this.onWindowBlur);
    //this.onWindowFocus();
  },
  beforeDestroy() {
    window.removeEventListener("focus", this.onWindowFocus);
    window.removeEventListener("blur", this.onWindowBlur);
    clearInterval(this.updateTimer);
  },
  mounted() {
    EventBus.$on("project-added", (error) => {
      console.log(error);
      this.page = 1;
      this.loadList(this.page, "get");
    });
    EventBus.$on("project-update", (error) => {
      console.log(error);
      this.loadList(this.page, "getSingle");
    });
    EventBus.$on("bug-added", (error) => {
      console.log(error);
      this.loadBugList("get", "pending");
    });
    EventBus.$on("bug-update", (error) => {
      console.log(error);
      this.loadBugList("get", this.bugsFilterBy);
    });
    EventBus.$on("task-added", (error) => {
      console.log(error);
      this.toggle_task = 1;
      this.toggle_task_by_team = 1;
      this.taskFilterBy = "pending";
      this.loadTasks("get");
    });
    EventBus.$on("task-update", (error) => {
      console.log(error);
      this.loadTasks("get");
    });
    EventBus.$on("plan-added", (error) => {
      console.log(error);
      this.loadPlan("get");
      this.loadList(this.page, "getSingle");
    });
    EventBus.$on("plan-update", (error) => {
      console.log(error);
      this.loadPlan("get");
    });
    EventBus.$on("uploaded-newfile", (error) => {
      console.log(error);
      this.loadFiles("get");
    });
    EventBus.$on("note-added", (error) => {
      console.log(error);
      this.loadNotes("getAll");
    });
    console.log("mounted");
  },
  methods: {
    filterByTeam(e) {
      this.taskFilterBy = e.id;
    },
    isNumeric: function (n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    loadTeam(page, action) {
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("managerId", this.userInfo.id);
      console.log(this.userInfo.fname);

      axios
        .post(
          BASE_URL + "manager/team.php?page=" + page + "&action=" + action,
          formData
        )
        .then(function (response) {
          vm.loading = false;
          console.log(response);
          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            vm.teamList = vm.teamList.concat(response.data.list);
          }
        })
        .catch(function (error) {
          vm.loading = false;
          console.log(error);
        });
    },
    loadList(page, action) {
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("managerId", this.userInfo.id);
      formData.append("id", this.id);
      console.log(this.userInfo.fname);
      axios
        .post(
          BASE_URL + "manager/project.php?page=" + page + "&action=" + action,
          formData
        )
        .then(function (response) {
          vm.loading = false;
          console.log(response);
          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            vm.projectInfo = response.data.list;
            // vm.title = vm.projectInfo.title;
            // vm.totalItems = parseInt(response.data.totalItems);
            //  vm.page = parseInt(response.data.page);
          }
        })
        .catch(function (error) {
          vm.loading = false;
          console.log(error);
        });
    },
    loadBugList(action, filterBy) {
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("projectId", this.id);

      axios
        .post(BASE_URL + "manager/bug.php?action=" + action, formData)
        .then(function (response) {
          vm.loading = false;
          console.log(response);
          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            vm.bugList = response.data.list;
            vm.bugsFilterBy = filterBy;
            if (vm.bugsFilterBy == "pending") {
              vm.toggle_bugs = 0;
            } else {
              vm.toggle_bugs = 1;
            }
          }
        })
        .catch(function (error) {
          vm.loading = false;
          console.log(error);
        });
    },
    loadFiles(action) {
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("projectId", this.id);
      formData.append("clientId", this.userInfo.id);

      axios
        .post(BASE_URL + "manager/file.php?action=" + action, formData)
        .then(function (response) {
          vm.loading = false;
          console.log(response);
          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            vm.filesList = response.data.list;
          }
        })
        .catch(function (error) {
          vm.loading = false;
          console.log(error);
        });
    },
    loadTasks(action) {
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("projectId", this.id);
      axios
        .post(BASE_URL + "manager/task.php?action=" + action, formData)
        .then(function (response) {
          vm.loading = false;
          console.log(response);
          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            vm.taskList = response.data.list;
          }
        })
        .catch(function (error) {
          vm.loading = false;
          console.log(error);
        });
    },
    loadPlan(action) {
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("projectId", this.id);
      axios
        .post(BASE_URL + "manager/plan.php?action=" + action, formData)
        .then(function (response) {
          vm.loading = false;
          console.log(response);
          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            vm.planList = response.data.list;
          }
        })
        .catch(function (error) {
          vm.loading = false;
          console.log(error);
        });
    },
    editPlan(plan) {
      EventBus.$emit("edit-plan", plan);
    },
    setPlanStatus(status, planId) {
      console.log(status, planId);
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("wbsId", planId);
      formData.append("status", status);
      axios
        .post(BASE_URL + "manager/plan.php?action=update", formData)
        .then(function (response) {
          vm.loading = false;
          console.log(response);
          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            vm.loadPlan("get");
          }
        })
        .catch(function (error) {
          vm.loading = false;
          console.log(error);
        });
    },
    getPageData(page) {
      console.log(page);
      this.loadList(page, this.action);
    },
    sortBy(prop) {
      this.projectInfo.sort((a, b) => (a[prop] < b[prop] ? -1 : 1));
    },
    trackAction(action, trackId) {
      console.log(action, trackId);
    },
    formattedDate(date) {
      if (date == "0000-00-00") {
        return "Not assigned yet";
      } else {
        return format(new Date(date), "dd MMM, yyyy");
      }
    },
    viewBug(data) {
      console.log(data.id);
      EventBus.$emit("view-bug", data);
    },
    viewNote(data) {
      console.log(data.id);
      EventBus.$emit("view-note", data);
    },
    viewPlan(data) {
      console.log(data.id);
      EventBus.$emit("view-plan", data);
    },
    viewTask(data) {
      console.log(data.id);
      EventBus.$emit("view-task", data);
    },
    displayAssignTo(data) {
      console.log(data.id);
      EventBus.$emit("view-assign-to", data);
    },
    updateBug(bugId, bugStatus) {
      const vm = this;
      var formData = new FormData();
      formData.append("bugId", bugId);
      formData.append("projectId", vm.id);
      formData.append("status", "3");
      var action = "update";
      if (bugStatus == "idle") {
        action = "delete";
      }
      axios
        .post(BASE_URL + "manager/bug.php?action=" + action, formData)
        .then(function (response) {
          vm.loading = false;
          console.log(response);
          vm.message = response.data.message;
          if (!response.data.error) {
            vm.loadBugList("get", vm.bugsFilterBy);
          }
          EventBus.$emit("display-message", vm.message);
        })
        .catch(function (error) {
          vm.loading = false;
          console.log(error);
        });
    },
    downloadAttachment(filePath) {
      axios({
        method: "get",
        url: "http://webapp.digitalguys.ca/source/" + filePath,
        responseType: "arraybuffer",
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const filename = filePath.split("/").pop();
          link.setAttribute("download", filename); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => console.log("error occured"));
    },
    isValidStatus(status) {
      if (status == "idle" || status == "tested") {
        return false;
      } else {
        return true;
      }
    },
    routeTo(route, param) {
      console.log("Hi");
      this.$router
        .replace({ name: route, params: { id: param } })
        .catch((err) => {
          console.log(err);
        });
    },
    routePushTo(route, param) {
      console.log("Hi");
      this.$router.push({ name: route, params: { id: param } }).catch((err) => {
        console.log(err);
      });
    },
    setTaskPriority(priority, taskId) {
      console.log(priority, taskId);
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("taskId", taskId);
      formData.append("priority", priority);
      formData.append("managerId", this.userInfo.id);
      axios
        .post(BASE_URL + "manager/task.php?action=update", formData)
        .then(function (response) {
          vm.loading = false;
          console.log(response);
          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            vm.loadTasks("get");
          }
        })
        .catch(function (error) {
          vm.loading = false;
          console.log(error);
        });
    },
    setTaskStaus(status, taskId) {
      console.log(status, taskId);
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("taskId", taskId);
      formData.append("status", status);
      formData.append("managerId", this.userInfo.id);
      axios
        .post(BASE_URL + "manager/task.php?action=update", formData)
        .then(function (response) {
          vm.loading = false;
          console.log(response);
          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            vm.loadTasks("get");
          }
        })
        .catch(function (error) {
          vm.loading = false;
          console.log(error);
        });
    },
    taskAction(action, task) {
      console.log(action, task.id);
      if (action == "delete") {
        const vm = this;
        var formData = new FormData();
        formData.append("ran", Math.random() * 10);
        formData.append("taskId", task.id);
        axios
          .post(BASE_URL + "manager/task.php?action=" + action, formData)
          .then(function (response) {
            vm.loading = false;
            console.log(response);
            if (response.data.error) {
              vm.message = response.data.message;
            } else {
              vm.loadTasks("get");
            }
          })
          .catch(function (error) {
            vm.loading = false;
            console.log(error);
          });
      } else if (action == "edit") {
        EventBus.$emit("edit-task", task);
      }
    },
    setBugStatus(status, bugId) {
      console.log(status, bugId);
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("bugId", bugId);
      formData.append("status", status);
      formData.append("managerId", this.userInfo.id);
      axios
        .post(BASE_URL + "manager/bug.php?action=update", formData)
        .then(function (response) {
          vm.loading = false;
          console.log(response);
          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            vm.loadBugList("get", vm.bugsFilterBy);
          }
        })
        .catch(function (error) {
          vm.loading = false;
          console.log(error);
        });
    },
    setProjectStatus(status, projectId) {
      console.log(status, projectId);
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("projectId", projectId);
      formData.append("status", status);
      axios
        .post(BASE_URL + "manager/project.php?action=update", formData)
        .then(function (response) {
          vm.loading = false;
          console.log(response);
          vm.message = response.data.message;
          if (!response.data.error) {
            vm.loadList(vm.page, "getSingle");
          }
          EventBus.$emit("display-message", vm.message);
        })
        .catch(function (error) {
          vm.loading = false;
          console.log(error);
        });
    },
    editProject(data) {
      EventBus.$emit("edit-project", data);
    },
    getBgColor(status) {
      var colorValue = "grey";
      switch (status) {
        case "done":
          colorValue = "green";
          break;
        case "idle":
          colorValue = "orange ";
          break;
        case "inReview":
          colorValue = "blue";
          break;
        case "inProgress":
          colorValue = "lime";
          break;

        default:
          colorValue = "grey";
          break;
      }
      return colorValue;
    },
    showBugs() {
      if (this.displayBugs) {
        this.displayBugs = false;
      } else {
        this.displayBugs = true;
        setTimeout(() => {
          this.$vuetify.goTo("#bugsSection");
        }, 500);
        this.loadBugList("get", this.bugsFilterBy);
      }
    },
    showPlaner() {
      this.displayPlaner = !this.displayPlaner;
      /* // this.loadPlan("get");
      setTimeout(() => {
        this.$vuetify.goTo("#planerSection");
      }, 500);*/
    },
    showFiles() {
      if (this.displayFiles) {
        this.displayFiles = false;
      } else {
        this.loadFiles("get");
        this.displayFiles = true;
        setTimeout(() => {
          this.$vuetify.goTo("#filesSection");
        }, 500);
      }
    },
    showNotes() {
      if (this.displayNotes) {
        this.displayNotes = false;
      } else {
        this.displayNotes = true;
        this.loadNotes("getAll");
        setTimeout(() => {
          this.$vuetify.goTo("#notesSection");
        }, 500);
      }
    },
    loadNotes(action) {
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("projectId", this.id);
      formData.append("managerId", this.userInfo.id);
      axios
        .post(BASE_URL + "manager/note.php?action=" + action, formData)
        .then(function (response) {
          vm.loading = false;
          console.log(response);
          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            vm.noteList = response.data.list;
          }
        })
        .catch(function (error) {
          vm.loading = false;
          console.log(error);
        });
    },
    deleteNote(noteId) {
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("projectId", this.id);
      formData.append("managerId", this.userInfo.id);
      formData.append("id", noteId);
      axios
        .post(BASE_URL + "manager/note.php?action=delete", formData)
        .then(function (response) {
          vm.loading = false;
          console.log(response);
          vm.message = response.data.message;
          if (!response.data.error) {
            vm.loadNotes("getAll");
          }
          EventBus.$emit("display-message", vm.message);
        })
        .catch(function (error) {
          vm.loading = false;
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.project.idle {
  border-top: 4px solid orange;
}

.project.working {
  border-top: 4px solid #3dc1c2;
}

.project.hold {
  border-top: 4px solid tomato;
}

.project.done {
  border-top: 4px solid green;
}

.task-item {
  background-color: #f1f1f1;
}

.high {
  border-left: 4px solid tomato;
}

.normal {
  border-left: 4px solid #3dc1c2;
}

.low {
  border-left: 4px solid orange;
}

.task-list .tested {
  opacity: 0.4;
}

.v-chip.Idle {
  background: orange;
}

.v-chip.active {
  background: #3dc1c2;
}

.v-chip.hold {
  background: tomato;
}

.v-chip.cancel {
  background: red;
}

.vue-bar-class {
  height: 390px;
  min-height: 100px;
  display: block;
  width: 100%;
}

.vue-bar-class .status-fixed {
  text-decoration: line-through;
  opacity: 0.8;
}

.v-chip.done {
  background: green;
  color: white;
}

.v-chip.working {
  background: #3dc1c2;
}

.v-chip.inReview {
  background: #26c6da;
}

.v-chip.inProgress {
  background: yellowgreen;
}

.v-chip.tested {
  background: #90a4ae;
}

.v-chip.idle {
  background: orange;
}

.v-chip.fixed {
  background: lightgray;
}

.v-chip.reopen {
  color: white;
  background: red;
}

.v-chip.high {
  background: red;
  color: white;
}

.v-chip.normal {
  background: lightseagreen;
}

.v-chip.low {
  background: orange;
}

.attachment {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.tile {
  background-color: #ffffd7 !important;
  border-left: 4px solid #eaec00;
  margin-bottom: 4px;
}

/*.oddClass {
  text-align: -moz-right;
  text-align: -webkit-right;
}
.oddClass .message {
  width: fit-content;
  padding: 0.8rem;
  margin-bottom: 0.4rem;
  background-color: #3dc1c2;
}

.evenClass {
  text-align: -moz-left;
  text-align: -webkit-left;
}
*/
.vb>.vb-dragger {
  z-index: 5;
  width: 12px;
  right: 0;
}

.vb>.vb-dragger>.vb-dragger-styler {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: rotate3d(0, 0, 0, 0);
  transform: rotate3d(0, 0, 0, 0);
  -webkit-transition: background-color 100ms ease-out, margin 100ms ease-out,
    height 100ms ease-out;
  transition: background-color 100ms ease-out, margin 100ms ease-out,
    height 100ms ease-out;
  background-color: rgba(48, 121, 244, 0.1);
  margin: 5px 5px 5px 0;
  border-radius: 20px;
  height: calc(100% - 10px);
  display: block;
}

.vb.vb-scrolling-phantom>.vb-dragger>.vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.3);
}

.vb>.vb-dragger:hover>.vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.5);
  margin: 0px;
  height: 100%;
}

.vb.vb-dragging>.vb-dragger>.vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.5);
  margin: 0px;
  height: 100%;
}

.vb.vb-dragging-phantom>.vb-dragger>.vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.5);
}

.highlight-item {
  border: 2px solid orange;
  animation: highlightBorder 3s linear forwards;
}

@keyframes highlightBorder {
  0% {
    border-color: orange;
  }

  100% {
    border-color: transparent;
  }
}

.task-filter-by-team {
  float: right;
  width: 200px;
}
</style>
