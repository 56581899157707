<template>
  <v-container fluid fill-height>
    <v-layout row wrap align-center justify-center>
      <v-col xs12 md6 sm12 lg6 xl4>
        <v-card class="text-center transparent-bg" flat>
          <v-img src="img/icons/icon-96x96.png" class="logo" />

          <h3 class="display-2 font-weight-bold mb-3">Welcome to PMTOOL</h3>

          <p class="subheading font-weight-regular">
            Sign in by following buttons
          </p>
          <v-btn
            outlined
            color="info"
            @click="routeTo('teamLogin')"
            class="mt-2"
            >Login as Team</v-btn
          >
          <v-btn
            outlined
            color="info"
            @click="routeTo('clientLogin')"
            class="mx-2 mt-2"
            >Login as Client</v-btn
          >
        </v-card>
      </v-col>

      <p class="powered-by">Powered by DigitalGuys.ca</p>
    </v-layout>
  </v-container>
</template>


<script>
import Auth from "@/auth";
import EventBus from "@/eventBus";
export default {
  name: "Home",
  data: () => ({
    authenticated: false,
  }),
  components: {},
  mounted() {
    this.authenticated = Auth.isLoogedIn(); //routePath, storedData
    if (this.authenticated) {
      EventBus.$emit("authenticated", true);
      // if auth success
      if (this.$store.state.userlogged.type == 3) {
        this.routeTo("clientDashboard");
      } else if (this.$store.state.userlogged.type == 1) {
        this.routeTo("managerDashboard");
      } else if (this.$store.state.userlogged.type == 2) {
        this.routeTo("teamTodayTask");
      }
    }
  },
  methods: {
    routeTo(route) {
      this.authenticated = Auth.isLoogedIn(); //routePath, storedData
      console.log("is Authenticated:", this.authenticated);
      if (this.authenticated) {
        
        EventBus.$emit("authenticated", true);
      }
      console.log("Hi");
      this.$router.replace({ name: route }).catch((err) => {
        console.log(err);
      });
    },
  },
};
</script>
<style scoped>
.transparent-bg {
  background: transparent;
}
.logo {
  width: 92px;
  height: auto;
  margin: 0px auto;
  margin-bottom: 2rem;
}
.powered-by {
  position: absolute;
  bottom: 10px;
}
</style>