<template>
  <div>
    <v-bottom-navigation fixed v-model="bottomNav" grow>
      <!-- for client // v-on:change="routeTo()" -->
      <v-btn
        data-value="clientProjects"
        v-if="userInfo.type == 3"
        class="pa-0"
        v-on:click="routeTo"
      >
        <span>Projects</span>
        <v-icon>mdi-folder-outline</v-icon>
      </v-btn>
      <v-btn
        v-if="userInfo.type == 3"
        class="pa-0"
        v-on:click="routeTo"
        data-value="clientFeedback"
      >
        <span>Feedback</span>
        <v-icon>mdi-comment-quote-outline</v-icon>
      </v-btn>
      <!-- end for client -->

      <!-- for Team -->
      <v-btn
        data-value="todaysTask"
        v-if="userInfo.type == 2"
        class="pa-0"
        v-on:click="routeTo"
      >
        <span>Today's Tasks</span>
        <v-icon>mdi-calendar-today</v-icon>
      </v-btn>
      <v-btn
        data-value="upcomingTask"
        v-if="userInfo.type == 2"
        class="pa-0"
        v-on:click="routeTo"
      >
        <span>Upcoming Task</span>
        <v-icon>mdi-calendar-multiselect</v-icon>
      </v-btn>
      <!-- end for Team -->
      <!-- for Manager -->
      <v-btn
        v-if="userInfo.type == 1"
        class="pa-0"
        v-on:click="routeTo"
        data-value="allProjects"
      >
        <span>Projects</span>
        <v-icon>mdi-folder-outline</v-icon>
      </v-btn>
      <v-btn
        data-value="mtodaysTask"
        v-if="userInfo.type == 1"
        class="pa-0"
        v-on:click="routeTo"
      >
        <span>Today's Tasks</span>
        <v-icon>mdi-calendar-today</v-icon>
      </v-btn>
      <v-btn
        v-if="userInfo.type == 1"
        class="pa-0"
        v-on:click="routeTo"
        data-value="mupcomingTask"
      >
        <span>Upcoming Task</span>
        <v-icon>mdi-calendar-multiselect</v-icon>
      </v-btn>
      <!-- end for Manager -->
    </v-bottom-navigation>
    <Feedback />
  </div>
</template>

<script>
import EventBus from "@/eventBus";
import Feedback from "@/components/client/Feedback";
export default {
  data() {
    return {
      bottomNav: "recent",
      userInfo: {},
      showFeedback: false,
    };
  },
  components: { Feedback },
  methods: {
    routeTo(e) {
      switch (e.currentTarget.getAttribute("data-value")) {
        case "stayThere":
          // do nothing
          break;
        case "clientProjects":
          this.checkRouteTo("clientDashboard");
          break;
        case "todaysTask":
          this.checkRouteTo("teamTodayTask");
          break;
        case "upcomingTask":
          this.checkRouteTo("teamUpcomingTask");
          break;
        case "clientFeedback":
          this.showClientFeedback();
          break;
        case "allProjects":
          this.checkRouteTo("manageProjects");
          break;
      }
      //reset bottom nav
      console.log(e.currentTarget.getAttribute("data-value"));
    },
    showClientFeedback() {
      EventBus.$emit("show-client-feedback", true);
    },
    checkRouteTo(routeName) {
      let currentRoute = this.$route.name;
      if (currentRoute != routeName) {
        this.$router.replace({ name: routeName }).catch(err => { console.log(err);});
      }
    },
  },
  mounted() {
    console.log("Navbar Mounted:", this.$store.state.userlogged.type);

    if (this.$store.state.userlogged != null) {
      this.userInfo = this.$store.state.userlogged;
    }
    EventBus.$on("hide-client-feedback", (error) => {
      //reset bottom bar
      // this.bottomNav = "stayThere";
      console.log(error);
    });
  },
  created() {
    if (this.$store.state.userlogged != null) {
      this.userInfo = this.$store.state.userlogged;
    }
  },
};
</script>
