<template>
  <div class="dashboard">
    <ViewClient />
    <v-container class="my-0">
      <v-row class="d-none">
        <v-col cols="12" md="12" lg="6">
          <v-card class="" elevation="0" max-width="100%">
            <v-card-text>
              <h3 class="">
                Today Tasks
                <v-chip class="" color="yellow " small> Pending </v-chip>
                <v-menu bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      outlined
                      color="grey darken-2"
                      v-on="on"
                      small
                      class="float-right"
                    >
                      {{ selectedTeam.name }}
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="team in teamList"
                      :key="team.name"
                      @click="onSelectTeam(team, 'pending')"
                    >
                      <v-list-item-title>{{ team.name }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </h3>
              <div class="text--primary">
                <v-list single-line>
                  <template v-for="(task, index) in taskList">
                    <v-list-item :key="`${index}-file-avatar`">
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="task.title"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="task.uploadedBy"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              ripple
                              v-on="on"
                              @click="downloadAttachment(task.attachment)"
                            >
                              <v-icon color="grey lighten-1"
                                >mdi-file-download-outline</v-icon
                              >
                            </v-btn>
                          </template>
                          <span>Download</span>
                        </v-tooltip>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider
                      v-if="index < taskList.length - 1"
                      :key="`${index}-file-divider`"
                    ></v-divider>
                  </template>
                </v-list>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn text color=""> </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="12" lg="6">
          <v-card class="" elevation="0" max-width="100%">
            <v-card-text>
              <h3 class="">
                Today Tasks
                <v-chip class="" color="light-green white--text" small>
                  Done
                </v-chip>
                <v-menu bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      outlined
                      color="grey darken-2"
                      v-on="on"
                      small
                      class="float-right"
                    >
                      {{ selectedTeam.name }}
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="team in teamList"
                      :key="team.name"
                      @click="onSelectTeam(team, 'done')"
                    >
                      <v-list-item-title>{{ team.name }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </h3>
              <div class="text--primary"></div>
            </v-card-text>
            <v-card-actions>
              <v-btn text color=""> </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="fill-height">
        <v-col>
          <v-sheet height="64">
            <v-toolbar flat>
              <v-btn
                outlined
                class="mr-4"
                color="grey darken-2"
                @click="setToday"
              >
                Today
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon small> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-menu bottom>
                <template v-slot:activator="{ on }">
                  <v-btn outlined color="grey darken-2" v-on="on" class="mr-2">
                    {{ selectedTeam.name }}
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="team in teamList"
                    :key="team.name"
                    @click="onClickTeamFilter(team)"
                  >
                    <v-list-item-title>{{ team.name }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right> mdi-menu-down </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>Day</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>Week</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>Month</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = '4day'">
                    <v-list-item-title>4 days</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </v-sheet>
          <v-sheet height="600">
            <v-calendar
              ref="calendar"
              event-more-text="{0} events hidden"
              v-model="focus"
              color="primary"
              :events="events"
              :event-color="getEventColor"
              :type="type"
              @click:event="showEvent"
              @click:more="viewDay"
              @click:date="viewDay"
              @change="updateRange"
            ></v-calendar>
            <v-menu
              v-model="selectedOpen"
              :close-on-content-click="false"
              :activator="selectedElement"
              offset-x
            >
              <v-card color="grey lighten-4" min-width="350px" flat>
                <v-toolbar :color="selectedEvent.color" dark>
                  <v-btn icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-toolbar-title
                    v-html="selectedEvent.name"
                  ></v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon>
                    <v-icon>mdi-heart</v-icon>
                  </v-btn>
                  <v-btn icon>
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <span v-html="selectedEvent.details"></span>
                </v-card-text>
                <v-card-actions>
                  <v-btn text color="secondary" @click="selectedOpen = false">
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>  
<script>
import axios from "axios";
import EventBus from "@/eventBus";
import ViewClient from "@/components/manager/ViewClient";
import { format } from "date-fns";
const BASE_URL = process.env.VUE_APP_API_BASE_URL;
export default {
  data() {
    return {
      loading: false,
      message: "",
      teamList: [],
      taskList: [],
      calendarTaskList: [],
      userInfo: {},
      avatarSize: 60,
      tile: false,
      page: 1,
      action: "get",
      totalteamList: 0,
      optionLinks: [
        { title: "Hide", action: "hide" },
        { title: "Show", action: "show" },
      ],
      focus: "",
      type: "day",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey darken-1",
      ],
      names: [
        "Meeting",
        "Holiday",
        "PTO",
        "Travel",
        "Event",
        "Birthday",
        "Conference",
        "Party",
      ],
      selectedTeam: {},
    };
  },
  components: { ViewClient },

  computed: {},
  created() {
    this.userInfo = this.$store.state.userlogged;
    this.loadTeam(this.page, "getActiveOnly");
  },
  mounted() {
    this.$refs.calendar.checkChange();
  },
  methods: {
    onClickTeamFilter(team) {
      this.loadTasksForCalendar(team, "pending");
    },
    loadTasksForCalendar(team, type) {
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("managerId", this.userInfo.id);
      formData.append("teamId", team.id);
      formData.append("type", type);
      console.log(this.userInfo.fname);

      axios
        .post(BASE_URL + "manager/task.php?&action=getAll", formData)
        .then(function (response) {
          vm.loading = false;
          console.log(response);
          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            vm.calendarTaskList = response.data.list;
            vm.setCalendarData();
          }
        })
        .catch(function (error) {
          vm.loading = false;
          console.log(error);
        });
    },
    setCalendarData() {
      const events = [];
      this.calendarTaskList.forEach((task) => {
        events.push({
          name:"[ "+task.projectInfo.shortName +" ] "+  task.title,
          start: new Date(task.startDate),
          end: new Date(task.dueDate),
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: false,
        });
      });

      this.events = events;
    },
    loadTeam(page, action) {
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("managerId", this.userInfo.id);
      console.log(this.userInfo.fname);

      axios
        .post(
          BASE_URL + "manager/team.php?page=" + page + "&action=" + action,
          formData
        )
        .then(function (response) {
          vm.loading = false;
          console.log(response);
          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            vm.teamList = response.data.list;
            vm.totalteamList = parseInt(response.data.totalItems);
            vm.page = parseInt(response.data.page);
            vm.selectedTeam = vm.teamList[0];
            vm.loadTasksForCalendar(vm.selectedTeam, "pending");
            vm.setCategoryData();
          }
        })
        .catch(function (error) {
          vm.loading = false;
          console.log(error);
        });
    },
    setCategoryData() {
      for (let index = 0; index < this.teamList.length; index++) {
        const team = this.teamList[index];
        this.categories.push(team.name);
      }
    },
    getPageData(page) {
      console.log(page);
      this.loadTeam(page, "getActiveOnly");
    },
    sortBy(prop) {
      this.teamList.sort((a, b) => (a[prop] < b[prop] ? -1 : 1));
    },
    onSelectTeam(team, type) {
      console.log(team.id);
      this.selectedTeam = team;
      this.loadTasks(this.page, team, type);
    },
    loadTasks(page, team, type) {
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("managerId", this.userInfo.id);
      formData.append("teamId", team.id);
      formData.append("type", type);
      console.log(this.userInfo.fname);

      axios
        .post(
          BASE_URL + "manager/task.php?page=" + page + "&action=getToday",
          formData
        )
        .then(function (response) {
          vm.loading = false;
          console.log(response);
          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            vm.taskList = response.data.list;
          }
        })
        .catch(function (error) {
          vm.loading = false;
          console.log(error);
        });
    },
    trackAction(action, trackId) {
      console.log(action, trackId);
    },
    formattedDate(date) {
      if (date == "0000-00-00") {
        return "Not assigned yet";
      } else {
        return format(new Date(date), "dd MMM, yyyy");
      }
    },
    showAddTrackDialog() {
      console.log("Show Dialog For Add Song");
    },
    update(data) {
      console.log(data.id);
      EventBus.$emit("edit-project", data);
    },
    changeStatus(id, status) {
      const vm = this;
      var formData = new FormData();
      formData.append("id", id);
      if (status == "inactive") {
        formData.append("status", "1");
      } else {
        formData.append("status", "2");
      }

      axios
        .post(
          BASE_URL +
            "appSettings/language.php?page=" +
            vm.page +
            "&action=update",
          formData
        )
        .then(function (response) {
          vm.loading = false;
          console.log(response);
          vm.message = response.data.message;
          if (!response.data.error) {
            vm.loadTeam(vm.page, "getActiveOnly");
          }
          EventBus.$emit("display-message", vm.message);
        })
        .catch(function (error) {
          vm.loading = false;
          console.log(error);
        });
    },
    routeTo(route, param) {
      console.log("Hi");
      EventBus.$emit("highlight-item", param);
      this.$router
        .replace({ name: route, params: { id: param } })
        .catch((err) => {
          console.log(err);
        });
    },
    viewClient(data) {
      EventBus.$emit("view-client", data);
    },

    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange() {
     /* const events = [];

      events.push({
        name: "New Task",
        start: new Date("2022-12-05 12:30"),
        end: new Date("2022-12-05 18:30"),
        color: this.colors[this.rnd(0, this.colors.length - 1)],
        timed: true,
        more: true,
        moreText: "Hello this is the desc",
      });

      this.events = events;*/
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },
};
</script>

<style scoped>
.item {
  transition: background-color 0.5s ease-out;
}
.item:hover {
  background: #dcdcdc;
}
.item.idle {
  border-left: 4px solid orange;
}
.item.working {
  border-left: 4px solid #3dc1c2;
}
.item.hold {
  border-left: 4px solid tomato;
}
.item.done {
  border-left: 4px solid green;
}
.v-chip.idle {
  background: orange !important;
}
.v-chip.active {
  background: #3dc1c2 !important;
}
.v-chip.working {
  background: #3dc1c2 !important;
}
.v-chip.inactive {
  background: tomato !important;
}
.clickable {
  cursor: pointer;
}
</style>
