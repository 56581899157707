<template>
    <v-dialog max-width="600px" v-model="dialog">
        <template v-slot:activator="{ on }">
            <v-btn depressed fab text small v-on="on" @click="showDialog">
                <v-icon medium color="info">mdi-plus-thick</v-icon>
            </v-btn>
        </template>

        <v-card>
            <v-card-title>
                <h2>{{ title }}</h2>
                <v-btn small text fab color="grey darken-1" @click="dialog = false" class="close-btn">
                    <v-icon medium>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-form class="px-3" ref="form" enctype="multipart/form-data">
                    <v-select :items="categoryList" v-model="defaultCategory" item-text="name" item-value="id"
                        label="Category" prepend-icon="mdi-shape" :disabled="action == 'update' ? true : false"
                        return-object></v-select>
                    <v-select :items="clientList" @change="handleChangeClient" v-model="defaultClient"
                        :disabled="action == 'update' ? true : false" item-text="name" item-value="id" label="Client"
                        prepend-icon="mdi-account-tie-outline" return-object></v-select>
                    <v-select :items="projectList" v-if="defaultCategory.id < 3" @change="handleChangeProject"
                        v-model="defaultProject" :disabled="action == 'update' ? true : false" item-text="shortName"
                        item-value="id" label="Project" prepend-icon="mdi-file-document-outline"
                        return-object></v-select>
                    <v-select :items="taskList" v-if="defaultCategory.id < 3" v-model="defaultTask"
                        :disabled="action == 'update' ? true : false" item-text="title" item-value="id" label="Task"
                        prepend-icon="mdi-file-document-outline" return-object></v-select>
                    <v-text-field label="Title" v-model="paymentTitle" prepend-icon="mdi-format-text"
                        :rules="inputRules"></v-text-field>
                    <v-text-field label="Amount" v-model="amount" prepend-icon="mdi-currency-usd"
                        :rules="inputRules"></v-text-field>
                    <v-select :items="creditDebitList" v-model="defaultCreditDabit" item-text="name" item-value="id"
                        label="Credit/Dabit" prepend-icon="mdi-file-document-outline" return-object></v-select>
                    <v-select :items="statusList" v-model="defaultStatus" item-text="name" item-value="id"
                        label="Status" prepend-icon="mdi-file-document-outline" return-object></v-select>
                    <v-menu ref="dueDateMenu" v-model="dueDateMenu" :close-on-content-click="false" :nudge-right="40"
                        :return-value.sync="dueDate" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="dueDate" label="Due Date" prepend-icon="mdi-calendar-check-outline"
                                readonly v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="dueDate" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="dueDateMenu = false">Cancel</v-btn>
                            <v-btn text color="primary" @click="$refs.dueDateMenu.save(dueDate)">OK</v-btn>
                        </v-date-picker>
                    </v-menu>
                    <v-spacer></v-spacer>
                    <v-btn text class="primary mx-0 mt-3" @click="submit()" :loading="loading">{{ btnLabel }}</v-btn>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import EventBus from "@/eventBus";
import axios from "axios";
const BASE_URL = process.env.VUE_APP_API_BASE_URL;
export default {
    props: ["projectId"],
    data() {
        return {
            amount: "",
            paymentTitle: "",
            userInfo: null,
            title: "Add New Payment",
            btnLabel: "Add Payment",
            id: 0,
            inputRules: [
                (v) => (v && v.length >= 3) || "Minimum length is 3 characters",
            ],
            phoneRules: [
                (v) => (v && v.length >= 10) || "Minimum length is 10 characters",
            ],
            emailRules: [
                (v) =>
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                        v
                    ) || "Invalid Email address",
            ],
            loading: false,
            dialog: false,
            action: "get",
            roleList: [],
            defaultRole: {},
            categoryList: [{ id: "1", "name": "Project" }, { id: "2", "name": "Task" }, { id: "3", "name": "Other" }],
            defaultCategory: { id: "3", "name": "Other" },
            projectList: [],
            defaultProject: {},
            defaultProjectId: "-1",
            clientList: [],
            defaultClient: {},
            taskList: [],
            defaultTask: {},
            defaultTaskId: "-1",
            creditDebitList: [
                { id: "1", name: "Credit" },
                { id: "2", name: "Debit" },
            ],
            defaultCreditDabit: { id: "1", name: "Credit" },
            statusList: [
                { id: "1", name: "Pending" },
                { id: "2", name: "Paid" },
            ],
            defaultStatus: { id: "1", name: "Pending" },
            dueDate: new Date().toISOString().substr(0, 10),
            dueDateMenu: false,
            defaultClientId: "-1"
        };
    },
    methods: {
        submit() {
            if (this.$refs.form.validate()) {
                const vm = this;
                console.log("Manager Id " + vm.userInfo.id);
                var formData = new FormData();
                formData.append("managerId", vm.userInfo.id);
                formData.append("title", vm.paymentTitle);
                formData.append("category", vm.defaultCategory.id);
                formData.append("type", vm.defaultCreditDabit.id);
                formData.append("status", vm.defaultStatus.id);
                formData.append("dueDate", vm.dueDate);
                formData.append("amount", vm.amount);
                formData.append("clientId", vm.defaultClient.id);

                if (vm.defaultCategory.id < 3 && vm.defaultProject != undefined) {
                    formData.append("projectId", vm.defaultProject.id);
                    formData.append("taskId", vm.defaultTask.id);
                }

                if (vm.action == "update") {
                    console.log("Language Id:" + vm.id);
                    formData.append("id", vm.id);
                }
                axios
                    .post(BASE_URL + "manager/payment.php?action=" + vm.action, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then(function (response) {
                        vm.loading = false;
                        vm.message = response.data.message;
                        console.log(response.data);
                        
                        EventBus.$emit("display-message", vm.message);
                        if (!response.data.error) {
                            if (vm.action == "update") {
                                EventBus.$emit("payment-added", vm.error);
                            } else {
                                EventBus.$emit("payment-update", vm.error);
                            }
                        }
                        vm.dialog = false;
                        // vm.name = "";
                        //vm.$refs.form.reset();
                    })
                    .catch(function (error) {
                        vm.loading = false;
                        console.log(error);
                        EventBus.$emit("display-message", "Some error occured!");
                    });
            }
        },
        uploadFiles() { },
        showDialog() {
            console.log("Project Id:" + this.projectId);
            this.btnLabel = "Add Payment";
            this.name = "";
            //this.dialog = true;
            this.action = "add";
            this.title = "Add New Payment";
            // this.$refs.form.reset();
        },
        pickFile() {
            this.$refs.insFiles.click();
        },
        onFilePicked(e) {
            const files = e.target.files;
            console.log(files[0].name);
            if (files[0] !== undefined) {
                this.attachmentName = files[0].name;
                if (this.attachmentName.lastIndexOf(".") <= 0) {
                    return;
                }
            }
        },
        loadList(action) {
            console.log("Loading List");
            const vm = this;
            var formData = new FormData();
            formData.append("ran", Math.random() * 129);
            axios
                .post(BASE_URL + "manager/payment.php?action=" + action, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(function (response) {
                    vm.loading = false;
                    console.log(response);
                    vm.message = response.data.message;
                    // EventBus.$emit("display-message", vm.message);
                    if (!response.data.error) {
                        vm.clientList = response.data.clientList;
                        if (vm.clientList.length > 0) {
                            if (vm.defaultClientId == "-1") {
                                vm.defaultClient = response.data.clientList[0];
                            } else {
                                vm.defaultClient = vm.getDefaultClient(vm.defaultClientId);
                            }
                        }
                    }
                    vm.dialog = false;
                })
                .catch(function (error) {
                    vm.loading = false;
                    console.log(error);
                });
        },
        getDefaultCategory(categoryId) {
            for (let index = 0; index < this.categoryList.length; index++) {
                const element = this.categoryList[index];
                if (element.id == categoryId) {
                    this.defaultCategory = element;
                }
            }

            return this.defaultCategory;

        },
        getDefaultCreditDebit(creditDabitId) {
            for (let index = 0; index < this.creditDebitList.length; index++) {
                const element = this.creditDebitList[index];
                if (element.id == creditDabitId) {
                    this.defaultCreditDabit = element;
                }
            }
            return this.defaultCreditDabit;
        },
        getDefaultStatus(statusId) {
            for (let index = 0; index < this.statusList.length; index++) {
                const element = this.statusList[index];
                if (element.id == statusId) {
                    this.defaultStatus = element;
                }
            }
            return this.defaultStatus;
        },
        getDefaultClient(clientId) {
            console.log("Client Id Found:" + clientId);

            for (let index = 0; index < this.clientList.length; index++) {
                const element = this.clientList[index];
                if (element.id == clientId) {
                    this.defaultClient = element;
                }
            }
            return this.defaultClient;
        },
        getDefaultProject(projectId) {
            for (let index = 0; index < this.projectList.length; index++) {
                const element = this.projectList[index];
                if (element.id == projectId) {
                    this.defaultProject = element;
                }
            }
            return this.defaultProject;
        },
        getDefaultTask(taskId) {
            for (let index = 0; index < this.taskList.length; index++) {
                const element = this.taskList[index];
                if (element.id == taskId) {
                    this.defaultTask = element;
                }
            }
            return this.defaultTask;
        },
        handleChangeClient(clientInfo) {
            this.loadProjects(clientInfo);
        },
        loadProjects(clientInfo) {
            console.log("Client Id Detail:" + clientInfo.id);
            const vm = this;
            var formData = new FormData();
            formData.append("ran", Math.random() * 129);
            formData.append("clientId", clientInfo.id);
            axios
                .post(BASE_URL + "manager/payment.php?action=getProjects", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(function (response) {
                    vm.loading = false;
                    console.log(response);
                    vm.message = response.data.message;
                    // EventBus.$emit("display-message", vm.message);
                    if (!response.data.error) {
                        vm.projectList = response.data.projectList;
                        if (vm.projectList.length > 0) {
                            if (vm.defaultProjectId == "-1") {
                                vm.defaultProject = response.data.projectList[0];
                            } else {
                                vm.defaultProject = vm.getDefaultProject(vm.defaultProjectId);
                            }
                        }
                    }

                })
                .catch(function (error) {

                    console.log(error);

                });
        },
        handleChangeProject(projectInfo) {
            this.loadTask(projectInfo);
        },
        loadTask(projectInfo) {
            console.log("ProjectInfo Detail:" + projectInfo.id);
            const vm = this;
            var formData = new FormData();
            formData.append("ran", Math.random() * 129);
            formData.append("projectId", projectInfo.id);
            axios
                .post(BASE_URL + "manager/payment.php?action=getTasks", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(function (response) {
                    vm.loading = false;
                    console.log(response);
                    vm.message = response.data.message;
                    // EventBus.$emit("display-message", vm.message);
                    if (!response.data.error) {
                        vm.taskList = response.data.taskList;
                        if (vm.taskList.length > 0) {
                            if (vm.defaultTask == "-1") {
                                vm.defaultTask = response.data.taskList[0];
                            } else {
                                vm.defaultTask = vm.getDefaultTask(vm.defaultTaskId);
                            }
                        }
                    }

                })
                .catch(function (error) {
                    console.log(error);

                });
        },
    },
    computed: {},
    mounted() {
        this.action = "add";
        EventBus.$on("edit-payment", (data) => {
            this.paymentTitle = data.title;
            this.defaultCategory = this.getDefaultCategory(data.category.id);
            this.defaultCreditDabit = this.getDefaultCreditDebit(data.type.id);
            this.defaultStatus = this.getDefaultStatus(data.status.id);
            this.dueDate = data.dueDate;
            this.amount = data.amount;
            this.defaultClientId = data.clientInfo.id;
            this.defaultProjectId = data.projectInfo.id;
            this.defaultTaskId = data.taskInfo.id;
            this.loadProjects(data.clientInfo);
            this.getDefaultTask(data.taskInfo.id);
            this.loadTask(data.projectInfo);
            this.getDefaultClient(data.clientInfo.id);
           
            this.id = data.id;
            this.btnLabel = "Update Payment";
            this.dialog = true;
            this.action = "update";
            this.title = "Update Payment";
        });
    },
    created() {
        this.userInfo = this.$store.state.userlogged;
        this.loadList("getData");

    },
};
</script>

<style scoped>
.close-btn {
    position: absolute;
    right: 10px;
}
</style>