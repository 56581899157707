<template>
  <div class="musicCategory">
    <v-container fluid class="my-0">
      <v-row no-gutters>
        <v-col cols="12" lg="4" xs="8" class="mb-2">
          <h1 class="title">
            Projects
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn text small color="primary" dark v-on="on">
                  Sort By <v-icon small class="ml-1">mdi-sort</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="sortBy('id')">
                  <v-list-item-title>By Added Date</v-list-item-title>
                </v-list-item>
                <v-list-item @click="sortBy('startDate')">
                  <v-list-item-title>By Start Date</v-list-item-title>
                </v-list-item>
                <v-list-item @click="sortBy('title')">
                  <v-list-item-title>By Title</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <AddProject  iconColor="blue"/>
          </h1>
        </v-col>

        <v-col cols="12" lg="6" xs="12">
          <div class="float-right " v-if="totalItems > 1">
            <v-pagination
              v-model="page"
              :length="totalItems"
              :total-visible="7"
              @input="getPageData"
            ></v-pagination>
          </div>
        </v-col>
      </v-row>

      <v-card class="pa-0 mb-1" flat tile v-for="item in items" :key="item.id">
        <v-row no-gutters :class="`pa-2 item ${item.status}`">
          <v-col
            cols="12"
            lg="4"
            @click="routeTo('clientProject', item.id)"
            class="clickable mb-2"
          >
            <div class="caption grey--text">Project</div>
            <div class="font-weight-medium primary--text">{{ item.title }}</div>
          </v-col>
          <v-col cols="7" lg="2" class="mb-2">
            <div class="caption grey--text">Start Date</div>
            <div>{{ formattedDate(item.startDate) }}</div>
          </v-col>

          <v-col cols="5" lg="2" class="mb-2">
            <div class="caption grey--text">Remaining Days</div>
            <div>{{ item.remainingDays }}</div>
          </v-col>
          <v-col cols="5" lg="2" class="mb-2">
            <div class="mt-1">
              <v-progress-circular
                :rotate="360"
                :size="50"
                :width="4"
                :value="item.progress"
                color="teal"
                >{{ item.progress }}<sub>%</sub></v-progress-circular
              >
            </div>
          </v-col>
          <v-col cols="5" lg="1" class="mb-2">
            <div class="mt-2">
              <v-chip
                small
                link
                :class="`${item.status} white--text caption my-2 `"
                @click="changeStatus(item.id, item.status)"
                >{{ item.status }}</v-chip
              >
            </div>
          </v-col>
         <!-- <v-col cols="3" lg="1" class="mb-2">
            <div class="float-right  mt-2">
              <v-btn text icon color="grey">
                <v-badge overlap color="orange">
                  <template v-slot:badge small>
                    <span class="small">9</span>
                  </template>
                  <v-icon color="grey darken-1" medium>mdi-bell-outline</v-icon>
                </v-badge>
              </v-btn>
            </div>
          </v-col>-->
          <v-col cols="2" lg="1" class="mb-2">
            <div class="float-right  mt-2">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn text icon v-on="on" color="grey">
                    <v-icon color="grey darken-1">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="link in optionLinks"
                    :key="link.title"
                    @click="trackAction(link.action, item.id)"
                  >
                    <v-list-item-title>{{ link.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-col>
        </v-row>
      </v-card>
      <div class="text-xs-center mt-4" v-if="totalItems > 1">
        <v-pagination
          v-model="page"
          :length="totalItems"
          :total-visible="7"
          @input="getPageData"
        ></v-pagination>
      </div>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";
import EventBus from "@/eventBus";
import { format } from "date-fns";
import AddProject from "@/components/client/AddProject";
const BASE_URL = process.env.VUE_APP_API_BASE_URL;
export default {
  data() {
    return {
      loading: false,
      message: "",
      items: [],
      itemOrder: "asc",
      userInfo: {},
      avatarSize: 60,
      tile: false,
      page: 1,
      action: "get",
      totalItems: 0,
      optionLinks: [{ title: "Coming Soon", action: "comingSoon" }],
    };
  },
  components: { AddProject },

  computed: {},
  created() {
    this.userInfo = this.$store.state.userlogged;
    if (this.userInfo != null) {
      this.loadList(this.page, this.action);
    }
  },
  mounted() {
    EventBus.$on("project-added", (error) => {
      console.log(error);
      this.page = 1;
      this.loadList(this.page, "get");
    });
    EventBus.$on("project-update", (error) => {
      console.log(error);
      this.loadList(this.page, "get");
    });
  },
  methods: {
    loadList(page, action) {
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("clientId", this.userInfo.id);
      console.log(this.userInfo.fname);

      axios
        .post(
          BASE_URL + "client/project.php?page=" + page + "&action=" + action,
          formData
        )
        .then(function(response) {
          vm.loading = false;
          console.log(response);
          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            vm.items = response.data.list;
            vm.totalItems = parseInt(response.data.totalItems);
            vm.page = parseInt(response.data.page);
          }
        })
        .catch(function(error) {
          vm.loading = false;
          console.log(error);
        });
    },
    getPageData(page) {
      console.log(page);
      this.loadList(page, this.action);
    },
    sortBy(prop) {
      if (this.itemOrder == "asc") {
        this.items.sort((a, b) => (a[prop] < b[prop] ? -1 : 1));
        this.itemOrder = "desc";
      } else {
        this.items.sort((a, b) => (b[prop] < a[prop] ? -1 : 1));
        this.itemOrder = "asc";
      }
    },

    trackAction(action, trackId) {
      console.log(action, trackId);
    },
    formattedDate(date) {
      if (date == "0000-00-00") {
        return "Not assigned yet";
      } else {
        return format(new Date(date), "dd MMM, yyyy");
      }
    },
    showAddTrackDialog() {
      console.log("Show Dialog For Add Song");
    },
    update(data) {
      console.log(data.id);
      EventBus.$emit("edit-project", data);
    },
    changeStatus(id, status) {
      const vm = this;
      var formData = new FormData();
      formData.append("id", id);
      if (status == "inactive") {
        formData.append("status", "1");
      } else {
        formData.append("status", "2");
      }

      axios
        .post(
          BASE_URL +
            "appSettings/language.php?page=" +
            vm.page +
            "&action=update",
          formData
        )
        .then(function(response) {
          vm.loading = false;
          console.log(response);
          vm.message = response.data.message;
          if (!response.data.error) {
            vm.loadList(vm.page, "get");
          }
          EventBus.$emit("display-message", vm.message);
        })
        .catch(function(error) {
          vm.loading = false;
          console.log(error);
        });
    },
    routeTo(route, param) {
      console.log("Hi");
      EventBus.$emit("highlight-item", param);
      this.$router.replace({ name: route, params: { id: param } }).catch(err => {console.log(err)});
    },
  },
};
</script>

<style scoped>
.item {
  transition: background-color 0.5s ease-out;
  border-bottom: #ccc 1px solid;
}
.item:hover {
  background: #dcdcdc;
}
.item.idle {
  border-left: 4px solid orange;
}
.item.working {
  border-left: 4px solid #3dc1c2;
}
.item.done {
  border-left: 4px solid grey;
}
.item.hold {
  border-left: 4px solid tomato;
}
.item.cancel {
  border-left: 4px solid red;
}
.v-chip.idle {
  background: orange !important;
}
.v-chip.active {
  background: #3dc1c2 !important;
}
.v-chip.working {
  background: #3dc1c2 !important;
}
.v-chip.done {
  background: grey !important;
}
.v-chip.hold {
  background: tomato !important;
}
.v-chip.cancel {
  background: red !important;
}
.clickable {
  cursor: pointer;
}
</style>
