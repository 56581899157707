<template>
  <v-app class="custom-bg">
    <v-snackbar v-model="updateExists" top color="warning">
      <span> New version available! Click to update</span>
      <v-btn text color="white" @click="refreshApp">Refresh</v-btn>
    </v-snackbar>
    <Navbar v-if="authenticated" />
    <v-main class="mx-lg-4 mx-md-auto content-class">
      <transition name="fade">
        <router-view :key="$route.fullPath"></router-view>
      </transition>
    </v-main>
    <BottomNavBar v-if="authenticated" />
    <AddToHomeBtn />
  </v-app>
</template>

<script>
import Navbar from "@/components/Navbar";
import AddToHomeBtn from "@/components/AddToHomeBtn";
import BottomNavBar from "@/components/BottomNavBar";
import EventBus from "@/eventBus";
import Auth from "@/auth";

export default {
  name: "App",

  components: {
    Navbar,
    BottomNavBar,
    AddToHomeBtn,
  },

  data: () => ({
    authenticated: false,
    refreshing: false,
    registration: null,
    updateExists: false,
  }),
  mounted() {
    // check current route name
    const routePath = this.$router.currentRoute.path;
    //  const storedData = this.$store.state;
    this.authenticated = Auth.isLoogedIn(); //routePath, storedData
    if (!this.authenticated) {
      if (!Auth.isLoogedIn()) {
        //routePath, storedData
        if (routePath.includes("/client")) {
          this.$router
            .replace({
              name: "clientLogin",
              query: { redirect: routePath },
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (routePath.includes("/manager")) {
          this.$router
            .replace({
              name: "managerLogin",
              query: { redirect: routePath },
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (routePath.includes("/team")) {
          this.$router
            .replace({
              name: "teamLogin",
              query: { redirect: routePath },
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    } else {
      // if auth success
      if (this.$store.state.userlogged.type == 3) {
        this.$router.replace({ name: "clientDashboard" }).catch((err) => {
          console.log(err);
        });
      } else if (this.$store.state.userlogged.type == 1) {
        this.$router.replace({ name: "managerDashboard" }).catch((err) => {
          console.log(err);
        });
      } else if (this.$store.state.userlogged.type == 2) {
        this.$router.replace({ name: "teamTodayTask" }).catch((err) => {
          console.log(err);
        });
      }
    }
    this.$router.beforeEach((to, from, next) => {
      if (to.matched.some((record) => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!Auth.isLoogedIn()) {
          if (routePath.includes("/client")) {
            next({
              name: "clientLogin",
              query: { redirect: to.fullPath },
            }).catch((err) => {
              console.log(err);
            });
          } else if (routePath.includes("/manager")) {
            next({
              name: "managerLogin",
              query: { redirect: to.fullPath },
            }).catch((err) => {
              console.log(err);
            });
          } else if (routePath.includes("/team")) {
            next({
              name: "teamLogin",
              query: { redirect: to.fullPath },
            }).catch((err) => {
              console.log(err);
            });
          }
        } else {
          next();
        }
      } else {
        next(); // make sure to always call next()!
      }
    });
  },
  methods: {
    showRefreshUI(e) {
      this.registration = e.detail;
      this.updateExists = true;
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage("skipWaiting");
    },
    grantPermission() {
      if (!("Notification" in window)) {
        console.log("This browser does not support system notifications");
        return;
      }

      if (Notification.permission === "granted") {
        // new Notification("You are already subscribed to web notifications");
        return;
      }

      if (
        Notification.permission !== "denied" ||
        Notification.permission === "default"
      ) {
        Notification.requestPermission().then((result) => {
          if (result === "granted") {
            const notification = new Notification(
              "Awesome! You will start receiving notifications shortly"
            );
            console.log(notification);
          }
        });
      }
    },
  },
  created() {
    this.grantPermission();
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });
    }

    EventBus.$on("authenticated", (status) => {
      this.authenticated = status;
      console.log(
        "Is Authticated 2: ",
        status,
        ", account Type:",
        this.$store.state.userlogged.type
      );
      if (this.$store.state.userlogged.type == 3) {
        this.$router.replace({ name: "clientLogin" }).catch((err) => {
          console.log(err);
        });
      } else if (this.$store.state.userlogged.type == 1) {
        this.$router.replace({ name: "managerLogin" }).catch((err) => {
          console.log(err);
        });
      } else if (this.$store.state.userlogged.type == 2) {
        this.$router.replace({ name: "teamLogin" }).catch((err) => {
          console.log(err);
        });
      }
      if (!status) {
        this.$store.dispatch("saveUserLogged", null);
      }
    });
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.25s;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.custom-bg {
  background-color: #f5f5f5 !important;
}
.content-class {
  margin-bottom: 80px;
}
</style>
