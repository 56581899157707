<template>
    <div>
        <ViewTeam />
        <v-container fluid class="my-0">
            <v-row no-gutters class="mb-0 pb-0">
                <v-col cols="12">
                    <h1 class="title">Manage Payment</h1>
                </v-col>
                <v-col cols="7" md="2" sm="8">
                    <v-text-field flat label="Search by title" v-model="searchText" clearable hide-details autocomplete
                        :loading="loading" v-on:keyup="searchQuery(page)" @click:clear="loadList(1, 'get')"
                        prepend-inner-icon="mdi-account-search-outline"></v-text-field>
                </v-col>
                <v-col cols="5" md="2" sm="4" class="mt-4">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                            <v-btn text small color="primary" dark v-on="on">
                                Sort By <v-icon small class="ml-1">mdi-sort</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="sortBy('addedOn')">
                                <v-list-item-title>By Start Date</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="sortBy('amount')">
                                <v-list-item-title>By Amount</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <AddPayment />
                </v-col>
                <v-col cols="12" md="2" class="mt-2">
                    <div class="d-inline task-filter-by-team">
                        <v-select :items="clientList" label="Client" item-text="name" item-value="id"
                            @change="filterByClient" flat rounded return-object single-line dense hide-details="true"
                            outlined></v-select>
                    </div>
                </v-col>
                <v-col cols="12" md="2" class="mt-2">
                    <div class="d-inline task-filter-by-team">
                        <v-menu ref="dateFilterMenu" v-model="dateFilterMenu" :close-on-content-click="false"
                            :nudge-right="40" :return-value.sync="dateFilter" transition="scale-transition"
                            min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field class="max-width" v-model="dateFilterTextField" label="Date Filter"
                                    outlined rounded dense clearable @click:clear="reloadAll()" v-on="on"
                                    v-on:click:append="dateFilterMenu = true" readonly></v-text-field>
                            </template>
                            <v-date-picker v-model="dateFilter" no-title scrollable multiple range>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="dateFilterMenu = false">Cancel</v-btn>
                                <v-btn text color="primary" @click="filterByDate()">OK</v-btn>
                            </v-date-picker>
                        </v-menu>
                    </div>
                </v-col>
                <v-col cols="12" md="2" class="mt-2">
                    <div class="d-inline task-filter-by-team">
                        <v-select :items="creditDebitList" label="Credit/Debit" item-text="name" item-value="id"
                            @change="filterByCreditDabit" flat rounded return-object single-line dense
                            hide-details="true" outlined></v-select>
                    </div>
                </v-col>
                <v-col cols="12" md="2" class="mt-2">
                    <div class="d-inline task-filter-by-team">
                        <v-select :items="statusList" label="Status" item-text="name" item-value="id"
                            @change="filterByStatus" flat rounded return-object single-line dense hide-details="true"
                            outlined></v-select>
                    </div>
                </v-col>
                <v-col cols="12" md="12">
                    <div class="right" v-if="totalItems > 1">
                        <v-pagination v-model="page" :length="totalItems" :total-visible="7"
                            @input="getPageData"></v-pagination>
                    </div>
                </v-col>
            </v-row>

            <v-card flat tile v-for="item in filteredPayment" :key="item.id" class="mb-1">
                <v-row no-gutters :class="`pa-2 item  ${item.status}`">

                    <v-col cols="9" md="4" class="clickable mb-2">
                        <div class="caption grey--text">Title - Id: {{ item.id }}</div>
                        <div>{{ item.projectInfo.shortName + " - " + item.title }}</div>
                    </v-col>
                    <v-col cols="6" sm="6" md="2" class="mb-2">
                        <div class="caption grey--text">Client</div>
                        <div>{{ item.clientInfo.fname + " " + item.clientInfo.lname }}</div>
                    </v-col>
                    <v-col cols="6" md="1" sm="6" class="clickable mb-2">
                        <div class="caption grey--text">Amount</div>
                        <div :class="`${item.type.title == 'Credit' ? '' : 'red--text'}`">{{ item.amount }}
                        </div>
                    </v-col>
                    <v-col cols="6" sm="6" md="1" class="mb-2">
                        <div class="caption grey--text">Due Date</div>
                        <div>{{ item.dueDate }}</div>
                    </v-col>

                    <v-col cols="6" sm="6" md="1" class="mb-2">
                        <div class="caption grey--text">Category</div>
                        <div>{{ item.category.title }}</div>
                    </v-col>
                    <v-col cols="4" sm="3" md="1">
                        <div class="caption grey--text">Credit/Debit</div>

                        <div>
                            <v-chip small :class="`${item.type.title} white--text`" class="ma-0">{{
                                item.type.title
                                }}</v-chip>
                        </div>
                    </v-col>


                    <v-col cols="4" sm="3" md="1">
                        <div class="caption grey--text text-center">Status</div>
                        <div class="text-center">
                            <v-chip small :class="`${item.status.title} white--text caption`"
                                @click="changeStatus(item.id, item.status.title)">{{ item.status.title }}</v-chip>
                        </div>
                    </v-col>
                    <v-col cols="4" sm="3" md="1">
                        <div class="float-right mt-3">
                            <v-btn fab text small @click="update(item)">
                                <v-icon>mdi-pencil-outline</v-icon>
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
            </v-card>
            <div class="text-right pa-3">
                Total Pending Amount : <span class="amount"> {{ getTotalEarning() }} INR</span>
            </div>
            <div class="text-xs-center mt-4" v-if="totalItems > 1">
                <v-pagination v-model="page" :length="totalItems" :total-visible="7"
                    @input="getPageData"></v-pagination>
            </div>
        </v-container>
    </div>
</template>
<script>
import axios from "axios";
import EventBus from "@/eventBus";
import AddPayment from "@/components/manager/AddPayment";
import ViewTeam from "@/components/manager/ViewTeam";
import { format, parseISO,subDays } from "date-fns";
const BASE_URL = process.env.VUE_APP_API_BASE_URL;
export default {
    data() {
        return {
            loading: false,
            message: "",
            items: [],
            clientList: [{ id: "-1", name: "All" }],
            userInfo: {},
            avatarSize: 60,
            tile: false,
            page: 1,
            action: "getAll",
            totalItems: 0,
            optionLinks: [
                { title: "Hide", action: "hide" },
                { title: "Show", action: "show" },
            ],
            searchText: "",
            filterDataByClient: "-1",
            filterDataByCreditDebit: "",
            filterDataByStatus: "",
            creditDebitList: [
                { id: "-1", name: "All" },
                { id: "1", name: "Credit" },
                { id: "2", name: "Debit" },
            ],

            statusList: [
                { id: "-1", name: "All" },
                { id: "1", name: "Pending" },
                { id: "2", name: "Paid" },
            ],
            dateFilter: [
                format(subDays(new Date(), 7), "yyyy-MM-dd"),
                format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
            ],
            dateFilterTextField: [],
            dateFilterMenu: false,
        };
    },
    components: { AddPayment, ViewTeam },

    computed: {
        //subDays(new Date().toISOString(), 7)
        filteredPayment() {
            var tempPaymentList = this.items;
            const filterBy = this.filterDataByClient.toLowerCase().trim();
            if (!filterBy) return this.tempPaymentList;
            if (this.isNumeric(filterBy)) {
                if (filterBy == -1) {
                    if (this.filterDataByCreditDebit && !this.filterDataByStatus) {
                        if (this.filterDataByCreditDebit == "all") {
                            return tempPaymentList;
                        } else {
                            return tempPaymentList.filter(
                                (c) => c.type.title.toLowerCase() == this.filterDataByCreditDebit);
                        }
                    } else if (!this.filterDataByCreditDebit && this.filterDataByStatus) {
                        if (this.filterDataByCreditDebit == "all") {
                            return tempPaymentList;
                        } else {
                            return tempPaymentList.filter(
                                (c) => c.status.title.toLowerCase() == this.filterDataByStatus);
                        }
                    } else if (this.filterDataByCreditDebit && this.filterDataByStatus) {
                        console.log(this.filterDataByCreditDebit + " / " + this.filterDataByStatus);
                        if (this.filterDataByCreditDebit != "all" && this.filterDataByStatus == "all") {
                            return tempPaymentList.filter(
                                (c) => c.type.title.toLowerCase() == this.filterDataByCreditDebit);
                        } else if (this.filterDataByCreditDebit == "all" && this.filterDataByStatus != "all") {
                            return tempPaymentList.filter(
                                (c) => c.status.title.toLowerCase() == this.filterDataByStatus);
                        } else if (this.filterDataByCreditDebit == "all" && this.filterDataByStatus == "all") {
                            return tempPaymentList;
                        } else {
                            return tempPaymentList.filter(
                                (c) => c.type.title.toLowerCase() == this.filterDataByCreditDebit && c.status.title.toLowerCase() == this.filterDataByStatus);
                        }

                    } else {
                        return tempPaymentList;
                    }
                } else {


                    if (!this.filterDataByCreditDebit && !this.filterDataByStatus) {
                        return tempPaymentList = this.items.filter((c) => c.clientInfo.id == filterBy);
                    } else if (this.filterDataByCreditDebit && !this.filterDataByStatus) {
                        if (this.filterDataByCreditDebit == "all") {
                            return tempPaymentList = this.items.filter((c) => c.clientInfo.id == filterBy);
                        } else {
                            return tempPaymentList.filter(
                                (c) => c.type.title.toLowerCase() == this.filterDataByCreditDebit && c.clientInfo.id == filterBy);
                        }
                    } else if (!this.filterDataByCreditDebit && this.filterDataByStatus) {
                        if (this.filterDataByCreditDebit == "all") {
                            return tempPaymentList = this.items.filter((c) => c.clientInfo.id == filterBy);
                        } else {
                            return tempPaymentList.filter(
                                (c) => c.status.title.toLowerCase() == this.filterDataByStatus && c.clientInfo.id == filterBy);
                        }
                    } else if (this.filterDataByCreditDebit && this.filterDataByStatus) {
                        console.log(this.filterDataByCreditDebit + " / " + this.filterDataByStatus);
                        if (this.filterDataByCreditDebit != "all" && this.filterDataByStatus == "all") {
                            return tempPaymentList.filter(
                                (c) => c.type.title.toLowerCase() == this.filterDataByCreditDebit && c.clientInfo.id == filterBy);
                        } else if (this.filterDataByCreditDebit == "all" && this.filterDataByStatus != "all") {
                            return tempPaymentList.filter(
                                (c) => c.status.title.toLowerCase() == this.filterDataByStatus && c.clientInfo.id == filterBy);
                        } else if (this.filterDataByCreditDebit == "all" && this.filterDataByStatus == "all") {
                            return tempPaymentList = this.items.filter((c) => c.clientInfo.id == filterBy);
                        } else {
                            return tempPaymentList.filter(
                                (c) => c.type.title.toLowerCase() == this.filterDataByCreditDebit && c.status.title.toLowerCase() == this.filterDataByStatus && c.clientInfo.id == filterBy);
                        }

                    } else {
                        return tempPaymentList;
                    }
                }
            }

            if (filterBy == "pending") {
                return tempPaymentList.filter(
                    (c) => c.status.title.toLowerCase() == "pending");
            } else if (filterBy == "paid") {
                return tempPaymentList.filter((c) => c.status.title.toLowerCase() == "paid");
            } else {
                return tempPaymentList;
            }
        },


    },
    created() {
        console.log(subDays(new Date(), 7));
        this.userInfo = this.$store.state.userlogged;
        this.dateFilterTextField = this.getFormatedDateFilter();
        this.loadList(this.page, this.action);
    },
    mounted() {
        EventBus.$on("payment-added", (error) => {
            console.log(error);
            this.page = 1;
            this.loadList(this.page, "getAll");
        });
        EventBus.$on("payment-update", (error) => {
            console.log(error);
            this.loadList(this.page, "getAll");
        });
    },
    methods: {
        getFormatedDateFilter() {
            return [
                format(parseISO(this.dateFilter[0]), "d MMM yyyy"),
                format(parseISO(this.dateFilter[1]), "d MMM yyyy"),
            ].join(" - ");
        },
        getFormatedDate(date) {
            return format(parseISO(date), "dd MMM yyyy");
        },
        filterByClient(e) {
            this.filterDataByClient = e.id;
        },
        filterByCreditDabit(e) {
            this.filterDataByCreditDebit = e.name.toLowerCase();
        },
        filterByStatus(e) {
            this.filterDataByStatus = e.name.toLowerCase();
        },
        isNumeric: function (n) {
            return !isNaN(parseFloat(n)) && isFinite(n);
        },
        getTotalEarning() {
            var total = 0;
            for (let index = 0; index < this.filteredPayment.length; index++) {
                const item = this.filteredPayment[index];
                if (item.type.title == "Credit") {
                    total += parseInt(item.amount);
                } else {
                    total -= parseInt(item.amount);
                }

            }
            return total;
        },
        filterByDate() {
            console.log(
                "start Date:" + this.dateFilter[0] + ", End Date: " + this.dateFilter[1]
            );
            this.dateFilterTextField = this.getFormatedDateFilter();
            this.$refs.dateFilterMenu.save(this.dateFilter);
            const vm = this;
            this.action = "searchByDate";
            var formData = new FormData();
            formData.append("ran", Math.random() * 10);
            let startDate = this.dateFilter[0];
            let endDate = this.dateFilter[1];
            if (startDate > endDate) {
                startDate = this.dateFilter[1];
                endDate = this.dateFilter[0];
            }

            formData.append("managerId", this.userInfo.id);
            if (this.filterDataByClient.id > 0) {
                formData.append("clientId", this.filterDataByClient.id);
            }

            formData.append("startDate", startDate);
            if (this.dateFilter[1] != undefined) {
                formData.append("endDate", endDate);
            }
            axios
                .post(BASE_URL + "manager/payment.php?action=" + this.action, formData)
                .then(function (response) {
                    vm.loading = false;
                    console.log(response);
                    if (response.data.error) {
                        vm.message = response.data.message;
                        vm.items = [];
                    } else {
                        vm.items = response.data.list;
                    }
                })
                .catch(function (error) {
                    vm.loading = false;
                    console.log(error);
                });
        },

        loadList(page, action) {
            const vm = this;
            var formData = new FormData();
            formData.append("ran", Math.random() * 10);
            formData.append("managerId", this.userInfo.id);
            console.log(this.userInfo.fname);

            axios
                .post(
                    BASE_URL + "manager/payment.php?page=" + page + "&action=" + action,
                    formData
                )
                .then(function (response) {
                    vm.loading = false;
                    console.log(response);
                    if (response.data.error) {
                        vm.message = response.data.message;
                    } else {
                        vm.items = response.data.list;
                        vm.clientList = vm.clientList.concat(response.data.clientList);
                        vm.totalItems = parseInt(response.data.totalItems);
                        //vm.page = parseInt(response.data.page);
                    }
                })
                .catch(function (error) {
                    vm.loading = false;
                    console.log(error);
                });
        },
        getPageData(page) {
            console.log(page);
            this.loadList(page, this.action);
        },
        sortBy(prop) {
            this.items.sort((a, b) => (a[prop] < b[prop] ? -1 : 1));
        },

        trackAction(action, trackId) {
            console.log(action, trackId);
        },
        formattedDate(date) {
            if (date == "0000-00-00") {
                return "Not assigned yet";
            } else {
                return format(new Date(date), "dd MMM, yyyy");
            }
        },
        showAddTrackDialog() {
            console.log("Show Dialog For Add Song");
        },
        update(data) {
            console.log(data.id);
            EventBus.$emit("edit-payment", data);
        },
        changeStatus(id, status) {
            const vm = this;
            var formData = new FormData();
            formData.append("id", id);
            formData.append("managerId", this.userInfo.id);
            if (status.toLowerCase() == "paid") {
                formData.append("status", "1");
            } else {
                formData.append("status", "2");
            }

            axios
                .post(
                    BASE_URL + "manager/payment.php?page=" + vm.page + "&action=update",
                    formData
                )
                .then(function (response) {
                    vm.loading = false;
                    console.log(response);
                    vm.message = response.data.message;
                    if (!response.data.error) {
                        vm.loadList(vm.page, "getAll");
                    }
                    EventBus.$emit("display-message", vm.message);
                })
                .catch(function (error) {
                    vm.loading = false;
                    console.log(error);
                });
        },
        routeTo(route, param) {
            console.log("Hi");
            EventBus.$emit("highlight-item", param);
            this.$router.replace({ name: route, params: { id: param } }).catch(err => { console.log(err) });
        },
        viewTeam(data) {
            EventBus.$emit("view-team", data);
        },
        searchQuery(page) {
            console.log(this.searchText.length);
            if (this.searchText.length > 2) {
                this.loading = true;
                const vm = this;
                var formData = new FormData();
                formData.append("keyword", vm.searchText);
                formData.append("managerId", vm.userInfo.id);
                axios
                    .post(
                        BASE_URL + "manager/team.php?page=" + page + "&action=search",
                        formData
                    )
                    .then(function (response) {
                        vm.loading = false;
                        console.log(response);
                        if (response.data.error) {
                            vm.message = response.data.message;
                        } else {
                            vm.items = response.data.list;
                            vm.totalItems = parseInt(response.data.totalItems);
                            vm.page = parseInt(response.data.page);
                        }
                    })
                    .catch(function (error) {
                        vm.loading = false;
                        console.log(error);
                    });
            } else if (this.searchText.length == 0) {
                this.page = 1;
                this.loadList(this.page, this.action);
            }
        },
    },
};
</script>

<style scoped>
.item {
    transition: background-color 0.5s ease-out;
}

.item:hover {
    background: #dcdcdc;
}

.item.Idle {
    border-left: 4px solid orange;
}

.item.active {
    border-left: 4px solid #3dc1c2;
}

.item.inactive {
    border-left: 4px solid tomato;
}

.v-chip.Idle {
    background: orange;
}

.v-chip.active {
    background: #3dc1c2;
}

.v-chip.inactive {
    background: tomato;
}

.clickable {
    cursor: pointer;
}

.v-chip.Debit {
    background: tomato;
}

.v-chip.Credit {
    background: rgb(0, 107, 27);
}

.v-chip.Pending {
    background: tomato;
}

.v-chip.Paid {
    background: rgb(0, 107, 27);
}

.amount {
    font-weight: 900;
}
</style>