<template>
  <div class="musicCategory">
    <ViewClient />
    <v-container class="my-0">
      <v-row no-gutters class="mb-3">
        <v-col cols="12"><h1 class="title">Manage Clients</h1></v-col>
        <v-col cols="12" md="4">
          <v-text-field
            flat
            label="Search by name, email, contact"
            v-model="searchText"
            clearable
            hide-details
            browser-autocomplete
            :loading="loading"
            v-on:keyup="searchQuery(page)"
            @click:clear="loadList(1, 'get')"
            prepend-inner-icon="mdi-account-search-outline"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2" class="mt-4">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn text small color="primary" dark v-on="on">
                Sort By <v-icon small class="ml-1">mdi-sort</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="sortBy('startDate')">
                <v-list-item-title>By Start Date</v-list-item-title>
              </v-list-item>
              <v-list-item @click="sortBy('name')">
                <v-list-item-title>By Name</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <AddClient />
        </v-col>
        <v-col cols="12" md="6">
          <div class="right" v-if="totalItems > 1">
            <v-pagination
              v-model="page"
              :length="totalItems"
              :total-visible="7"
              @input="getPageData"
            ></v-pagination>
          </div>
        </v-col>
      </v-row>

      <v-card flat tile v-for="item in items" :key="item.id" class="mb-1">
        <v-row no-gutters :class="`pa-2 item ${item.status}`">
          <v-col cols="3" md="1" class="clickable mb-2">
            <div>
              <v-avatar size="48" color="grey" @click="viewClient(item)">
                <img
                  :src="item.image"
                  v-if="item.image != ''"
                  :alt="item.name"
                />
                <span v-else class="white--text headline">{{
                  item.name.substring(0, 1)
                }}</span>
              </v-avatar>
            </div>
          </v-col>
          <v-col cols="9" md="2" class="clickable mb-2">
            <div class="caption grey--text">Name</div>
            <div>{{ item.name }}</div>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="mb-2">
            <div class="caption grey--text">Email</div>
            <div>{{ item.email }}</div>
          </v-col>

          <v-col cols="6" sm="6" md="2" class="mb-2">
            <div class="caption grey--text">Company</div>
            <div>{{ item.company }}</div>
          </v-col>
          <v-col cols="6" sm="6" md="2" class="mb-2">
            <div class="caption grey--text">Client Of</div>
            <div>{{ item.clientOf.name }}</div>
          </v-col>
          <v-col class="3" sm="3" md="1">
            <div class="mt-2">
              <v-chip
                small
                :class="`${item.status} white--text caption my-3`"
                @click="changeStatus(item.id, item.status)"
                >{{ item.status }}</v-chip
              >
            </div>
          </v-col>
          <v-col cols="3" sm="3" md="1">
            <div class="float-right mt-2">
              <v-btn fab text small @click="update(item)">
                <v-icon>mdi-account-edit-outline</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </v-card>
      <div class="text-xs-center mt-4" v-if="totalItems > 1">
        <v-pagination
          v-model="page"
          :length="totalItems"
          :total-visible="7"
          @input="getPageData"
        ></v-pagination>
      </div>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";
import EventBus from "@/eventBus";
import AddClient from "@/components/manager/AddClient";
import ViewClient from "@/components/manager/ViewClient";
import { format } from "date-fns";
const BASE_URL = process.env.VUE_APP_API_BASE_URL;
export default {
  data() {
    return {
      loading: false,
      message: "",
      items: [],
      userInfo: {},
      avatarSize: 60,
      tile: false,
      page: 1,
      action: "get",
      totalItems: 0,
      optionLinks: [
        { title: "Hide", action: "hide" },
        { title: "Show", action: "show" },
      ],
      searchText: "",
    };
  },
  components: { AddClient, ViewClient },

  computed: {},
  created() {
    this.userInfo = this.$store.state.userlogged;
    this.loadList(this.page, this.action);
  },
  mounted() {
    EventBus.$on("client-added", (error) => {
      console.log(error);
      this.page = 1;
      this.loadList(this.page, "get");
    });
    EventBus.$on("client-update", (error) => {
      console.log(error);
      this.loadList(this.page, "get");
    });
  },
  methods: {
    loadList(page, action) {
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("managerId", this.userInfo.id);
      console.log(this.userInfo.fname);

      axios
        .post(
          BASE_URL + "manager/client.php?page=" + page + "&action=" + action,
          formData
        )
        .then(function(response) {
          vm.loading = false;
          console.log(response);
          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            vm.items = response.data.list;
            vm.totalItems = parseInt(response.data.totalItems);
            //vm.page = parseInt(response.data.page);
          }
        })
        .catch(function(error) {
          vm.loading = false;
          console.log(error);
        });
    },
    getPageData(page) {
      console.log(page);
      this.loadList(page, this.action);
    },
    sortBy(prop) {
      this.items.sort((a, b) => (a[prop] < b[prop] ? -1 : 1));
    },

    trackAction(action, trackId) {
      console.log(action, trackId);
    },
    formattedDate(date) {
      if (date == "0000-00-00") {
        return "Not assigned yet";
      } else {
        return format(new Date(date), "dd MMM, yyyy");
      }
    },
    showAddTrackDialog() {
      console.log("Show Dialog For Add Song");
    },
    update(data) {
      console.log(data.id);
      EventBus.$emit("edit-client", data);
    },
    changeStatus(id, status) {
      const vm = this;
      var formData = new FormData();
      formData.append("id", id);
      if (status == "inactive") {
        formData.append("status", "1");
      } else {
        formData.append("status", "2");
      }

      axios
        .post(
          BASE_URL + "manager/client.php?page=" + vm.page + "&action=update",
          formData
        )
        .then(function(response) {
          vm.loading = false;
          console.log(response);
          vm.message = response.data.message;
          if (!response.data.error) {
            vm.loadList(vm.page, "get");
          }
          EventBus.$emit("display-message", vm.message);
        })
        .catch(function(error) {
          vm.loading = false;
          console.log(error);
        });
    },
    routeTo(route, param) {
      console.log("Hi");
      EventBus.$emit("highlight-item", param);
      this.$router.replace({ name: route, params: { id: param } }).catch(err => {console.log(err)});
    },
    viewClient(data) {
      EventBus.$emit("view-client", data);
    },
    searchQuery(page) {
      console.log(this.searchText.length);
      if (this.searchText.length > 2) {
        this.loading = true;
        const vm = this;
        var formData = new FormData();
        formData.append("keyword", vm.searchText);
        formData.append("managerId", vm.userInfo.id);
        axios
          .post(
            BASE_URL + "manager/client.php?page=" + page + "&action=search",
            formData
          )
          .then(function(response) {
            vm.loading = false;
            console.log(response);
            if (response.data.error) {
              vm.message = response.data.message;
            } else {
              vm.items = response.data.list;
              vm.totalItems = parseInt(response.data.totalItems);
              vm.page = parseInt(response.data.page);
            }
          })
          .catch(function(error) {
            vm.loading = false;
            console.log(error);
          });
      } else if (this.searchText.length == 0) {
        this.page = 1;
        this.loadList(this.page, this.action);
      }
    },
  },
};
</script>

<style scoped>
.item {
  transition: background-color 0.5s ease-out;
}
.item:hover {
  background: #dcdcdc;
}
.item.Idle {
  border-left: 4px solid orange;
}
.item.active {
  border-left: 4px solid #3dc1c2;
}
.item.inactive {
  border-left: 4px solid tomato;
}
.v-chip.Idle {
  background: orange;
}
.v-chip.active {
  background: #3dc1c2;
}
.v-chip.inactive {
  background: tomato;
}
.clickable {
  cursor: pointer;
}
</style>
