<template>
  <v-dialog max-width="600px" v-model="dialog">
    <v-card>
      <v-card-title>
        <h2>{{ title }}</h2>
      </v-card-title>
      <v-card-text>
        <p>{{ desc }}</p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import EventBus from "@/eventBus";
//import axios from "axios";
//const BASE_URL = process.env.VUE_APP_API_BASE_URL;
export default {
  data() {
    return {
      desc: "",
      title: "Add New Bug",
      attachmentName: "",
      id: 0,
      loading: false,
      dialog: false,
      action: "get"
    };
  },
  methods: {
    showDialog() {
      console.log("Project Id:" + this.projectId);

      this.btnLabel = "Add Bug";
      this.name = "";
      //this.dialog = true;
      this.action = "add";
      this.title = "Add New Bug";
    }
  },
  computed: {},
  mounted() {
    this.action = "add";
    EventBus.$on("view-bug", data => {
      this.dialog = true;
      this.action = "update";
      this.title = data.title;
      this.desc = data.desc;
    });
  },
  created() {}
};
</script>