<template>
  <div class="musicCategory">
    <ViewClient />
    <v-container fluid class="my-0">
      <v-row no-gutters class="mb-3">
        <v-col cols="12" lg="6">
          <h1 class="title">
            All Projects
            <v-menu offset-y v-if="false">
              <template v-slot:activator="{ on }">
                <v-btn text small color="primary" dark v-on="on">
                  Sort By <v-icon small class="ml-1">mdi-sort</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="sortBy('id')">
                  <v-list-item-title>By Added Date</v-list-item-title>
                </v-list-item>
                <v-list-item @click="sortBy('startDate')">
                  <v-list-item-title>By Start Date</v-list-item-title>
                </v-list-item>
                <v-list-item @click="sortBy('title')">
                  <v-list-item-title>By Title</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <AddProjectMngr iconColor="blue" />
          </h1>
        </v-col>
        <v-col cols="12" lg="6">
          <div class="right" v-if="totalItems > 1">
            <v-pagination
              v-model="page"
              :length="totalItems"
              :total-visible="7"
              @input="getPageData"
            ></v-pagination>
          </div>
        </v-col>
      </v-row>
      <div v-for="(projectStatus, index) in projectsStatusList" :key="projectStatus.id">
        <v-row>
          <v-col cols="12" :class="`${index > 0 ? 'mt-3' : 'mt-0'} `">
            <h3 :class="`${index > 0 ? 'mt-5' : 'mt-0'} mb-2` ">{{ projectStatus.title }}</h3>
          </v-col>
        </v-row>
        <v-card flat tile v-if="getProjects(projectStatus.id).length < 1">
          No record in list
        </v-card>
        <v-card
          flat
          tile
          v-for="item in getProjects(projectStatus.id)"
          :key="item.id"
        >
          <v-row no-gutters :class="`pa-2 item ${item.status}`">
            <v-col
              cols="12"
              lg="4"
              @click="routeTo('manageProject', item.id)"
              class="clickable mb-2"
            >
              <div class="caption grey--text">Project</div>
              <div>{{ item.title }}</div>
            </v-col>
            <v-col
              cols="12"
              lg="2"
              @click="viewClient(item.client)"
              class="clickable mb-2"
            >
              <div class="caption grey--text">Client</div>
              <div>
                <v-chip>
                  <v-avatar color="primary" left>
                    <img
                      :src="item.client.image"
                      v-if="item.client.image"
                      :alt="item.client.name"
                    />
                    <span v-else class="headline white--text">{{
                      item.client.name.substring(0, 1)
                    }}</span>
                  </v-avatar>
                  {{ item.client.name }}
                </v-chip>
              </div>
            </v-col>
            <v-col cols="12" sm="6" lg="2" class="mb-2">
              <div class="caption grey--text">Start Date</div>
              <div>{{ formattedDate(item.startDate) }}</div>
            </v-col>

            <v-col cols="12" sm="6" lg="1" class="mb-2">
              <div class="caption grey--text">Remaining Days</div>
              <div>{{ item.remainingDays }}</div>
            </v-col>
            <v-col cols="3" sm="3" lg="1">
              <div class="mt-2">
                <v-progress-circular
                  :rotate="360"
                  :size="40"
                  :width="4"
                  :value="item.progress"
                  color="teal"
                  >{{ item.progress }}%</v-progress-circular
                >
              </div>
            </v-col>
            <v-col cols="3" sm="3" lg="1">
              <div class="mt-2">
                <v-chip
                  small
                  :class="`${item.status} white--text caption my-2 `"
                  >{{ item.status }}</v-chip
                >
              </div>
            </v-col>

            <v-col cols="3" sm="3" lg="1">
              <div class="float-right mt-2">
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn text icon v-on="on" color="grey">
                      <v-icon color="grey darken-1">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="link in optionLinks"
                      :key="link.title"
                      @click="trackAction(link.action, item.id)"
                    >
                      <v-list-item-title>{{ link.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-card>
      </div>
      <div class="text-xs-center mt-4" v-if="totalItems > 1">
        <v-pagination
          v-model="page"
          :length="totalItems"
          :total-visible="7"
          @input="getPageData"
        ></v-pagination>
      </div>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";
import EventBus from "@/eventBus";
import ViewClient from "@/components/manager/ViewClient";
import { format } from "date-fns";
import AddProjectMngr from "@/components/manager/AddProjectMngr";
import _ from "lodash";
const BASE_URL = process.env.VUE_APP_API_BASE_URL;
export default {
  data() {
    return {
      loading: false,
      message: "",
      items: [],
      projectsStatusList: [
        { title: "Running Projects", id: "2" },
        { title: "Upcoming Projects", id: "1" },
        { title: "On Hold Projects", id: "5" },
        { title: "Done Projects", id: "3" },
        { title: "Canceled Projects", id: "4" },
      ],
      runningProjects: [],
      upcomingProjects: [],
      doneProjects: [],
      onHoldProjects: [],
      cancledProjects: [],
      itemOrder: "asc",
      userInfo: {},
      avatarSize: 60,
      tile: false,
      page: 1,
      action: "getAll",
      totalItems: 0,
      optionLinks: [{ title: "Coming Soon", action: "comming" }],
    };
  },
  components: { ViewClient, AddProjectMngr },

  computed: {},
  created() {
    this.userInfo = this.$store.state.userlogged;
    this.loadList(this.page, this.action);
  },
  mounted() {
    EventBus.$on("project-added", (error) => {
      console.log(error);
      this.page = 1;
      this.loadList(this.page, "getAll");
    });
    EventBus.$on("project-update", (error) => {
      console.log(error);
      this.loadList(this.page, "getAll");
    });
  },
  methods: {
    getProjects(id) {
      var list = [];
      if (id == "1") {
        list = this.upcomingProjects;
      } else if (id == "2") {
        list = this.runningProjects;
      } else if (id == "3") {
        list = this.doneProjects;
      } else if (id == "4") {
        list = this.cancledProjects;
      } else if (id == "5") {
        list = this.onHoldProjects;
      }
      return list;
    },
    loadList(page, action) {
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("managerId", this.userInfo.id);
      console.log(this.userInfo.fname);

      axios
        .post(
          BASE_URL + "manager/project.php?page=" + page + "&action=" + action,
          formData
        )
        .then(function (response) {
          vm.loading = false;
          console.log(response);
          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            vm.items = response.data.list;
            vm.doneProjects = _.filter(vm.items, function (o) {
              return o.status == "done";
            });
            vm.runningProjects = _.filter(vm.items, function (o) {
              return o.status == "working";
            });
            console.log(vm.runningProjects.length);
            vm.upcomingProjects = _.filter(vm.items, function (o) {
              return o.status == "idle";
            });
            vm.cancledProjects = _.filter(vm.items, function (o) {
              return o.status == "cancel";
            });
            vm.onHoldProjects = _.filter(vm.items, function (o) {
              return o.status == "hold";
            });
            vm.totalItems = parseInt(response.data.totalItems);
            vm.page = parseInt(response.data.page);
          }
        })
        .catch(function (error) {
          vm.loading = false;
          console.log(error);
        });
    },
    getPageData(page) {
      console.log(page);
      this.loadList(page, this.action);
    },
    sortBy(prop) {
      if (this.itemOrder == "asc") {
        this.items.sort((a, b) => (a[prop] < b[prop] ? -1 : 1));
        this.itemOrder = "desc";
      } else {
        this.items.sort((a, b) => (b[prop] < a[prop] ? -1 : 1));
        this.itemOrder = "asc";
      }
    },

    trackAction(action, trackId) {
      console.log(action, trackId);
    },
    formattedDate(date) {
      if (date == "0000-00-00") {
        return "Not assigned yet";
      } else {
       return format(new Date(date), "dd MMM, yyyy");
       
      }
    },
    showAddTrackDialog() {
      console.log("Show Dialog For Add Song");
    },
    update(data) {
      console.log(data.id);
      EventBus.$emit("edit-project", data);
    },
    routeTo(route, param) {
      console.log("Hi");
      EventBus.$emit("highlight-item", param);
      this.$router
        .replace({ name: route, params: { id: param } })
        .catch((err) => {
          console.log(err);
        });
    },
    viewClient(data) {
      EventBus.$emit("view-client", data);
    },
  },
};
</script>

<style scoped>
.item {
  transition: background-color 0.5s ease-out;
}
.item:hover {
  background: #dcdcdc;
}
.item.idle {
  border-left: 4px solid orange;
}
.item.working {
  border-left: 4px solid #3dc1c2;
}
.item.hold {
  border-left: 4px solid tomato;
}
.item.done {
  border-left: 4px solid green;
}
.item.cancel {
  border-left: 4px solid red;
}
.v-chip.idle {
  background: orange !important;
}
.v-chip.active {
  background: #3dc1c2 !important;
}
.v-chip.working {
  background: #3dc1c2 !important;
}
.v-chip.inactive {
  background: tomato !important;
}
.v-chip.hold {
  background: tomato;
}
.v-chip.cancel {
  background: red;
}
.v-chip.done {
  background:  green;
}
.clickable {
  cursor: pointer;
}
</style>
