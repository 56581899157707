<template>
  <v-dialog max-width="600px" v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn depressed fab text small v-on="on" @click="showDialog">
        <v-icon medium color="info">mdi-plus-thick</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <h2>{{ title }}</h2>
          <v-btn
          small
          text
          fab
          color="grey darken-1"
          @click="dialog = false"
          class="close-btn"
        >
          <v-icon medium>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form class="px-3" ref="form" enctype="multipart/form-data">
          <v-text-field
            label="First Name"
            v-model="fname"
            prepend-icon="mdi-format-title"
            :rules="inputRules"
          ></v-text-field>
          <v-text-field
            label="Last Name"
            v-model="lname"
            prepend-icon="mdi-format-title"
            :rules="inputRules"
          ></v-text-field>
          <v-text-field
            label="Email"
            v-model="email"
            type="email"
            required
            prepend-icon="mdi-email-outline"
            :rules="emailRules"
          ></v-text-field>
          <v-text-field
            label="Phone"
            v-model="phone"
            type="tel"
            required
            prepend-icon="mdi-card-account-phone-outline"
            :rules="phoneRules"
          ></v-text-field>
          <v-select
            :items="clientOfList"
            v-model="defaultClientOf"
            item-text="name"
            item-value="id"
            label="Client Of"
            prepend-icon="mdi-account-tie-outline"
            return-object
          ></v-select>
          <v-spacer></v-spacer>
          <v-btn
            text
            class="primary mx-0 mt-3"
            @click="submit()"
            :loading="loading"
            >{{ btnLabel }}</v-btn
          >
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import EventBus from "@/eventBus";
import axios from "axios";
const BASE_URL = process.env.VUE_APP_API_BASE_URL;
export default {
  props: ["projectId"],
  data() {
    return {
      fname: "",
      lname: "",
      email: "",
      phone: "",
      userInfo: null,
      title: "Add New Client",
      btnLabel: "Add Client",
      id: 0,
      inputRules: [
        (v) => (v && v.length >= 3) || "Minimum length is 3 characters",
      ],
      phoneRules: [
        (v) => (v && v.length >= 10) || "Minimum length is 10 characters",
      ],
      emailRules: [
        (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Invalid Email address",
      ],
      loading: false,
      dialog: false,
      action: "get",
      clientOfList: [],
      defaultClientOf: {},
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        const vm = this;
        console.log("Manager Id " + vm.userInfo.id);
        var formData = new FormData();
        formData.append("managerId", vm.userInfo.id);
        formData.append("firstName", vm.fname);
        formData.append("lastName", vm.lname);
        formData.append("role", vm.defaultClientOf.id);
        formData.append("email", vm.email);
        formData.append("phone", vm.phone);
        if (vm.action == "update") {
          console.log("Language Id:" + vm.id);
          formData.append("id", vm.id);
        }
        axios
          .post(BASE_URL + "manager/client.php?action=" + vm.action, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function(response) {
            vm.loading = false;
            console.log(response);
            vm.message = response.data.message;
            EventBus.$emit("display-message", vm.message);
            if (!response.data.error) {
              if (vm.action == "update") {
                EventBus.$emit("client-update", vm.error);
              } else {
                EventBus.$emit("client-added", vm.error);
              }
            }
            vm.dialog = false;
            // vm.name = "";
            vm.$refs.form.reset();
          })
          .catch(function(error) {
            vm.loading = false;
            console.log(error);
            EventBus.$emit("display-message", "Some error occured!");
          });
      }
    },
    uploadFiles() {},
    showDialog() {
      console.log("Project Id:" + this.projectId);
      this.btnLabel = "Add Client";
      this.name = "";
      //this.dialog = true;
      this.action = "add";
      this.title = "Add New Client";
      this.$refs.form.reset();
    },
    pickFile() {
      this.$refs.insFiles.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      console.log(files[0].name);
      if (files[0] !== undefined) {
        this.attachmentName = files[0].name;
        if (this.attachmentName.lastIndexOf(".") <= 0) {
          return;
        }
      }
    },
    loadClientOf(action) {
      console.log("Loading Client of list");

      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 129);
      if (action == "update") {
        formData.append("id", vm.id);
      }
      axios
        .post(BASE_URL + "manager/client.php?action=" + action, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function(response) {
          vm.loading = false;
          console.log(response);
          vm.message = response.data.message;
          // EventBus.$emit("display-message", vm.message);
          if (!response.data.error) {
            vm.clientOfList = response.data.list;
            if (vm.clientOfList.length > 0) {
              vm.defaultClientOf = response.data.list[0];
            }
          }
          vm.dialog = false;
        })
        .catch(function(error) {
          vm.loading = false;
          console.log(error);
          EventBus.$emit("display-message", "Some error occured 2!");
        });
    },
  },
  computed: {},
  mounted() {
    this.action = "add";
    EventBus.$on("edit-client", (data) => {
      this.fname = data.firstName;
      this.lname = data.lastName;
      this.email = data.email;
      this.phone = data.contact;
      this.defaultClientOf = data.clientOf;
      this.id = data.id;
      this.btnLabel = "Update Client";
      this.dialog = true;
      this.action = "update";
      this.title = "Update Client";
    });
  },
  created() {
    this.userInfo = this.$store.state.userlogged;
    this.loadClientOf("getClientOf");
    console.log("Project Id:" + this.projectId);
  },
};
</script>

<style scoped>
.close-btn {
  position: absolute;
  right: 10px;
}
</style>
