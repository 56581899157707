<template>
  <v-dialog max-width="600px" v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn
        small
        class="elevation-0 custom-fab"
        color="success"
        v-on="on"
        @click="showDialog"
        >Change Password</v-btn
      >
    </template>
    <v-card>
      <v-card-title>
        <h2>
          {{ title }}
        </h2>
        <v-btn
          small
          text
          fab
          color="grey darken-1"
          @click="dialog = false"
          class="close-btn"
        >
          <v-icon medium>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form class="px-3" ref="form" enctype="multipart/form-data">
          <v-text-field
            label="Old Password"
            v-model="oldPass"
            prepend-icon="mdi-form-textbox-password"
            :append-icon="e1 ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
            @click:append="() => (e1 = !e1)"
            :type="e1 ? 'password' : 'text'"
            :rules="inputRules"
          ></v-text-field>
          <v-text-field
            label="New Password"
            v-model="newPass"
            prepend-icon="mdi-form-textbox-password"
            :append-icon="e2 ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
            @click:append="() => (e2 = !e2)"
            :type="e2 ? 'password' : 'text'"
            :rules="inputRules"
          ></v-text-field>
          <v-text-field
            label="Confirm New Password"
            :append-icon="e3 ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
            @click:append="() => (e3 = !e3)"
            :type="e3 ? 'password' : 'text'"
            v-model="newPassConfirm"
            prepend-icon="mdi-form-textbox-password"
            :rules="inputRules"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            text
            class="primary mx-0 mt-3"
            @click="submit()"
            :loading="loading"
            >{{ btnLabel }}</v-btn
          >
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import EventBus from "@/eventBus";
import axios from "axios";
const BASE_URL = process.env.VUE_APP_API_BASE_URL;
export default {
  props: ["projectId"],
  data() {
    return {
      oldPass: "",
      newPass: "",
      newPassConfirm: "",
      userInfo: {},
      title: "Change Password",
      btnLabel: "Change",
      attachmentName: "",
      id: 0,
      inputRules: [
        (v) => (v && v.length >= 3) || "Minimum length is 3 characters",
      ],
      loading: false,
      dialog: false,
      action: "get",
      e1: true,
      e2: true,
      e3: true,
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        const vm = this;
        console.log("Manager Email" + vm.userInfo.email);
        var formData = new FormData();
        formData.append("oPassword", vm.oldPass);
        formData.append("nPassword", vm.newPass);
        formData.append("cPassword", vm.newPassConfirm);
        formData.append("email", vm.userInfo.email);
        axios
          .post(BASE_URL + "manager/profile.php?action=changePass", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function(response) {
            vm.loading = false;
            console.log(response);
            vm.message = response.data.message;
            EventBus.$emit("display-message", vm.message);
            vm.dialog = false;
            // vm.name = "";
            vm.$refs.form.reset();
          })
          .catch(function(error) {
            vm.loading = false;
            console.log(error);
            EventBus.$emit("display-message", "Some error occured!");
          });
      }
    },
    uploadFiles() {},
    showDialog() {
      this.btnLabel = "Change";
      this.name = "";
      //this.dialog = true;
      this.action = "chnage";
      this.title = "Change Password";
      this.$refs.form.reset();
    },
  },
  computed: {},
  mounted() {},
  created() {
    this.userInfo = this.$store.state.userlogged;
  },
};
</script>
<style scoped>
.close-btn {
  position: absolute;
  right: 10px;
}
</style>
