<template>
  <div class="musicCategory">
    <v-container fluid class="my-0">
      <v-row no-gutters class="mb-3">
        <v-col cols="12" sm="8">
          <h1 class="title">
            Upcoming Task
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn text small color="primary" dark v-on="on">
                  Sort By <v-icon small class="ml-1">mdi-sort</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="sortBy('title')">
                  <v-list-item-title>By Title</v-list-item-title>
                </v-list-item>
                <v-list-item @click="sortBy('priority')">
                  <v-list-item-title>By Priority</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn text small color="primary" dark v-on="on">
                  Sort By Projects <v-icon small class="ml-1">mdi-sort</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="filterByProjects('')">
                  <v-list-item-title>All</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-for="project in uniqueProjects"
                  :key="project.id"
                  @click="filterByProjects(project.id)"
                >
                  <v-list-item-title>{{ project.shortName }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </h1>
        </v-col>
        <v-col cols="12" sm="4">
          <div class="right" v-if="totalItems > 1">
            <v-pagination
              v-model="page"
              :length="totalItems"
              :total-visible="7"
              @input="getPageData"
            ></v-pagination>
          </div>
        </v-col>
      </v-row>
      <v-card flat tile v-if="items.length == 0" class="pa-5">
        No task for upcoming day(s).
      </v-card>
      <v-card
        flat
        tile
        v-for="task in filteredTasks"
        :key="task.id"
        class="mb-1"
      >
        <v-row no-gutters :class="`pa-2 item ${task.priority}`">
          <v-col cols="12" sm="5" @click="routeTo('teamTask', task.id)">
            <div class="caption grey--text">Task</div>
            <div class="pointer subheading mb-2 task-title">
              {{ task.title }}
            </div>
          </v-col>
          <v-col cols="10" sm="10" md="4" lg="1">
            <div class="caption grey--text">Project</div>
            <div class="pointer subheading my-1">
              {{ task.projectInfo.shortName }}
            </div>
          </v-col>
          <v-col cols="2" sm="2" md="2" lg="1" class="text-xs-center">
            <div class="caption grey--text">Files</div>
            <div v-if="task.attachment != ''">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    ripple
                    small
                    v-on="on"
                    @click="downloadAttachment(task.attachment)"
                    class="mt-0"
                  >
                    <v-icon color="black lighten-2"
                      >mdi-file-download-outline</v-icon
                    >
                  </v-btn>
                </template>
                <span>Download</span>
              </v-tooltip>
            </div>
            <div v-else class="mt-1">None</div>
          </v-col>
          <v-col cols="8" sm="12" md="2" lg="1" class="text-xs-left">
            <div class="caption grey--text">Due Date</div>
            <div class="mt-1 subheading mb-3">
              {{ formattedDate(task.dueDate) }}
            </div>
          </v-col>
          <v-col cols="4" sm="4" md="1" lg="1" class="text-xs-center">
            <div class="caption grey--text">Day(s) left</div>
            <div class="my-1">
              <v-chip
                small
                label
                :color="
                  task.daysLeft < 5
                    ? 'red lighten-1 white--text'
                    : 'grey black--text'
                "
                >{{ task.daysLeft }} day(s)</v-chip
              >
            </div>
          </v-col>
          <v-col cols="4" sm="2" md="2" lg="1" class="text-xs-center">
            <div class="caption grey--text">Bugs</div>
            <div class="py-2">{{ task.bugs }}</div>
          </v-col>

          <v-col cols="4" sm="4" md="2" lg="1" class="text-xs-center">
            <div class="caption grey--text">Priority</div>
            <div class="my-1">
              <v-chip small :class="`${task.priority} white--text`">{{
                task.priority
              }}</v-chip>
            </div>
          </v-col>
          <v-col cols="4" sm="4" md="1" lg="1" class="text-xs-center">
            <div class="caption grey--text">Status</div>
            <div class="my-1">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-chip
                    small
                    v-on="on"
                    :class="`${task.status} white--text`"
                    >{{ task.status }}</v-chip
                  >
                </template>
                <v-list>
                  <v-list-item
                    v-for="link in taskStatusOptions"
                    :key="link.title"
                    @click="setTaskStaus(task.id, link.action)"
                  >
                    <v-list-item-title>{{ link.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-col>
        </v-row>

        <v-divider></v-divider>
      </v-card>
      <div class="text-xs-center mt-4" v-if="totalItems > 1">
        <v-pagination
          v-model="page"
          :length="totalItems"
          :total-visible="7"
          @input="getPageData"
        ></v-pagination>
      </div>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";
import EventBus from "@/eventBus";
import { format } from "date-fns";
const BASE_URL = process.env.VUE_APP_API_BASE_URL;
export default {
  data() {
    return {
      filterBy: "",
      loading: false,
      message: "",
      items: [],
      projects:[],
      userInfo: {},
      avatarSize: 60,
      tile: false,
      page: 1,
      action: "getUpcoming",
      totalItems: 0,
      optionLinks: [
        { title: "Hide", action: "hide" },
        { title: "Show", action: "show" },
      ],
      taskStatusOptions: [
        { title: "In Progress", action: "2" },
        { title: "Done", action: "3" },
      ],
    };
  },
  components: {},

  computed: {
    uniqueProjects() {
      return this.projects.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.id]: current,
        });
      }, {});
    },
    filteredTasks() {
      if (!this.filterBy) return this.items;
      else return this.items.filter((c) => c.projectInfo.id == this.filterBy);
    },
  },
  created() {
    this.userInfo = this.$store.state.userlogged;
    this.loadList(this.page, this.action);
  },
  mounted() {
    EventBus.$on("project-added", (error) => {
      console.log(error);
      this.page = 1;
      this.loadList(this.page, this.action);
    });
    EventBus.$on("project-update", (error) => {
      console.log(error);
      this.loadList(this.page, this.action);
    });
  },
  methods: {
    loadList(page, action) {
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("teamId", this.userInfo.id);
      console.log(this.userInfo.fname);
      axios
        .post(
          BASE_URL + "team/task.php?page=" + page + "&action=" + action,
          formData
        )
        .then(function (response) {
          vm.loading = false;
          console.log(response);
          vm.items = response.data.list;
          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            vm.totalItems = parseInt(response.data.totalItems);
            // vm.page = parseInt(response.data.page);
            vm.setProjectList();
          }
        })
        .catch(function (error) {
          vm.loading = false;
          console.log(error);
        });
    },
    getPageData(page) {
      console.log(page);
      this.loadList(page, this.action);
    },
    sortBy(prop) {
      this.items.sort((a, b) => (a[prop] < b[prop] ? -1 : 1));
    },
    filterByProjects(projectId) {
      console.log(projectId);
      this.filterBy = projectId;
    },
    setProjectList() {
      for (let index = 0; index < this.items.length; index++) {
        const project = this.items[index].projectInfo;
        this.projects.push(project);
      }
      this.projects = [...new Set(this.projects)];
    },
    trackAction(action, trackId) {
      console.log(action, trackId);
    },
    formattedDate(date) {
      if (date == "0000-00-00") {
        return "Not assigned yet";
      } else {
        return format(new Date(date), "dd MMM, yyyy");
      }
    },
    showAddTrackDialog() {
      console.log("Show Dialog For Add Song");
    },
    update(data) {
      console.log(data.id);
      EventBus.$emit("edit-project", data);
    },
    setTaskStaus(taskId, status) {
      const vm = this;
      var formData = new FormData();
      formData.append("taskId", taskId);
      formData.append("status", status);
      formData.append("teamId", this.userInfo.id);
      axios
        .post(BASE_URL + "team/task.php?action=update", formData)
        .then(function (response) {
          vm.loading = false;
          console.log(response);
          vm.message = response.data.message;
          if (!response.data.error) {
            vm.loadList(vm.page, vm.action);
          }
          EventBus.$emit("display-message", vm.message);
        })
        .catch(function (error) {
          vm.loading = false;
          console.log(error);
        });
    },
    routeTo(route, param) {
      console.log("Hi");
      this.$router.push({ name: route, params: { id: param } });
    },
    downloadAttachment(filePath) {
      /*window.location =
        BASE_URL + "team/download.php?action=download&file=" + filePath;*/
      /*window.open(
        BASE_URL + "client/download.php?action=download&file=" + filePath
      );*/

      axios({
        method: "get",
        url: "http://webapp.digitalguys.ca/source/" + filePath,
        responseType: "arraybuffer",
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const filename = filePath.split("/").pop();
          link.setAttribute("download", filename); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => console.log("error occured"));
    },
  },
};
</script>

<style scoped>
.item {
  transition: background-color 0.5s ease-out;
}
.item:hover {
  background: #dcdcdc;
}
.item.high {
  border-left: 4px solid red !important;
}
.item.normal {
  border-left: 4px solid lightseagreen !important;
}
.item.low {
  border-left: 4px solid orange !important;
}
.v-chip.Idle {
  background: orange !important;
}
.v-chip.active {
  background: #3dc1c2 !important;
}
.v-chip.inactive {
  background: tomato !important;
}

.v-chip.done {
  background: green;
  color: white;
}
.v-chip.working {
  background: #3dc1c2;
}
.v-chip.inReview {
  background: #26c6da;
}
.v-chip.inProgress {
  background: yellowgreen;
}
.v-chip.tested {
  background: #90a4ae;
}
.v-chip.idle {
  background: orange;
}
.v-chip.fixed {
  background: lightgray;
}
.v-chip.reopen {
  background: red;
}
.v-chip.high {
  background: red;
  color: white;
}
.v-chip.normal {
  background: lightseagreen;
}
.v-chip.low {
  background: orange;
}
.clickable {
  cursor: pointer;
}
.task-title {
  font-weight: 400;
  font-size: 1.2rem;
}
</style>
