<template>
  <div class="musicCategory">
    <v-container class="my-5">
      <v-row row class="mb-3">
        <h1 class="title">Dashboard</h1>
        <v-col cols="12" xs="12" md="6">
          <v-tooltip top>
            <v-btn
              small
              flat
              color="grey"
              @click="sortBy('title')"
              slot="activator"
            >
              <v-icon left small>music_note</v-icon>
              <span class="caption text-lowercase">By Title</span>
            </v-btn>
            <span>Sort by Title</span>
          </v-tooltip>
          <v-tooltip top>
            <v-btn
              small
              flat
              color="grey"
              @click="sortBy('startDate')"
              slot="activator"
            >
              <v-icon left small>person</v-icon>
              <span class="caption text-lowercase">By Start Date</span>
            </v-btn>
            <span>Sort By Start Date</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" xs="12" md="6">
          <div class="right" v-if="totalItems > 1">
            <v-pagination
              v-model="page"
              :length="totalItems"
              :total-visible="7"
              @input="getPageData"
            ></v-pagination>
          </div>
        </v-col>
      </v-row>

      <v-card flat v-for="item in items" :key="item.id">
        <v-row row wrap :class="`pa-3 item ${item.status}`">
          <v-col
            xs12
            md4
            mb-2
            @click="routeTo('clientProject', item.id)"
            class="clickable"
          >
            <div class="caption grey--text">Project</div>
            <div class="clickable">{{ item.title }}</div>
          </v-col>
          <v-col xs12 sm6 md2 mb-2>
            <div class="caption grey--text">Start Date</div>
            <div>{{ formattedDate(item.startDate) }}</div>
          </v-col>

          <v-col xs12 sm6 md2 mb-2>
            <div class="caption grey--text">Remaining Days</div>
            <div>{{ item.remainingDays }}</div>
          </v-col>
          <v-col xs3 sm3 md1>
            <div>
              <v-progress-circular
                :rotate="360"
                :size="40"
                :width="4"
                :value="item.progress"
                color="teal"
                >{{ item.progress }}%</v-progress-circular
              >
            </div>
          </v-col>
          <v-col xs3 sm3 md1>
            <div class>
              <v-chip
                small
                :class="`${item.status} white--text caption my-2 `"
                @click="changeStatus(item.id, item.status)"
                >{{ item.status }}</v-chip
              >
            </div>
          </v-col>
          <v-col xs3 sm3 md1>
            <div class="right">
              <v-btn flat icon slot="activator" color="grey">
                <v-badge overlap color="orange">
                  <template v-slot:badge small>
                    <span class="small">10</span>
                  </template>
                  <v-icon color="grey darken-1" medium>notifications</v-icon>
                </v-badge>
              </v-btn>
            </div>
          </v-col>
          <v-col xs3 sm3 md1>
            <div class="right">
              <v-menu offset-y>
                <v-btn flat icon slot="activator" color="grey">
                  <v-icon color="grey darken-1">more_vert</v-icon>
                </v-btn>
                <v-list>
                  <v-list-tile
                    v-for="link in optionLinks"
                    :key="link.title"
                    @click="trackAction(link.action, item.id)"
                  >
                    <v-list-tile-title>{{ link.title }}</v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-menu>
            </div>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </v-card>
      <div class="text-xs-center mt-4" v-if="totalItems > 1">
        <v-pagination
          v-model="page"
          :length="totalItems"
          :total-visible="7"
          @input="getPageData"
        ></v-pagination>
      </div>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";
import EventBus from "@/eventBus";
import { format } from "date-fns";
const BASE_URL = process.env.VUE_APP_API_BASE_URL;
export default {
  data() {
    return {
      loading: false,
      message: "",
      items: [],
      userInfo: {},
      avatarSize: 60,
      tile: false,
      page: 1,
      action: "get",
      totalItems: 0,
      optionLinks: [
        { title: "Hide", action: "hide" },
        { title: "Show", action: "show" },
      ],
    };
  },
  components: {},

  computed: {},
  created() {
    this.userInfo = this.$store.state.userlogged;
    this.loadList(this.page, this.action);
  },
  mounted() {
    EventBus.$on("project-added", (error) => {
      console.log(error);
      this.page = 1;
      this.loadList(this.page, "get");
    });
    EventBus.$on("project-update", (error) => {
      console.log(error);
      this.loadList(this.page, "get");
    });
  },
  methods: {
    loadList(page, action) {
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("clientId", this.userInfo.id);
      console.log(this.userInfo.fname);

      axios
        .post(
          BASE_URL + "client/project.php?page=" + page + "&action=" + action,
          formData
        )
        .then(function(response) {
          vm.loading = false;
          console.log(response);
          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            vm.items = response.data.list;
            vm.totalItems = parseInt(response.data.totalItems);
            vm.page = parseInt(response.data.page);
          }
        })
        .catch(function(error) {
          vm.loading = false;
          console.log(error);
        });
    },
    getPageData(page) {
      console.log(page);
      this.loadList(page, this.action);
    },
    sortBy(prop) {
      this.items.sort((a, b) => (a[prop] < b[prop] ? -1 : 1));
    },

    trackAction(action, trackId) {
      console.log(action, trackId);
    },
    formattedDate(date) {
      if (date == "0000-00-00") {
        return "Not assigned yet";
      } else {
        return format(new Date(date), "dd MMM, yyyy");
      }
    },
    showAddTrackDialog() {
      console.log("Show Dialog For Add Song");
    },
    update(data) {
      console.log(data.id);
      EventBus.$emit("edit-project", data);
    },
    changeStatus(id, status) {
      const vm = this;
      var formData = new FormData();
      formData.append("id", id);
      if (status == "inactive") {
        formData.append("status", "1");
      } else {
        formData.append("status", "2");
      }

      axios
        .post(
          BASE_URL +
            "appSettings/language.php?page=" +
            vm.page +
            "&action=update",
          formData
        )
        .then(function(response) {
          vm.loading = false;
          console.log(response);
          vm.message = response.data.message;
          if (!response.data.error) {
            vm.loadList(vm.page, "get");
          }
          EventBus.$emit("display-message", vm.message);
        })
        .catch(function(error) {
          vm.loading = false;
          console.log(error);
        });
    },
    routeTo(route, param) {
      console.log("Hi");
      EventBus.$emit("highlight-item", param);
      this.$router.replace({ name: route, params: { id: param } }).catch(err => {console.log(err)});
    },
  },
};
</script>

<style>
.item {
  transition: background-color 0.5s ease-out;
}
.item:hover {
  background: #dcdcdc;
}
.item.Idle {
  border-left: 4px solid orange;
}
.item.active {
  border-left: 4px solid #3dc1c2;
}
.item.inactive {
  border-left: 4px solid tomato;
}
.v-chip.Idle {
  background: orange;
}
.v-chip.active {
  background: #3dc1c2;
}
.v-chip.inactive {
  background: tomato;
}
.clickable {
  cursor: pointer;
}
</style>
