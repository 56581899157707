import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import vuetify from "./plugins/vuetify";
import Vuebar from "vuebar";
import VBodyScrollLock from 'v-body-scroll-lock'

Vue.config.productionTip = false;
Vue.use(Vuebar);
Vue.use(VBodyScrollLock);
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
