<template>
  <div>
    <v-container fluid class="my-1">
      <ViewBug />
      <v-row no-gutters class="mb-3">
        <v-col cols="12" lg="12" xs="12" class="pa-1">
          <!-- for Project Detial -->
          <v-card flat>
            <v-row no-gutters :class="`pa-3 project ${projectInfo.status}`">
              <v-col cols="10" lg="11" xs="12">
                <div class="headline">
                  {{ projectInfo.title }}
                  <!-- @click="changeStatus(projectInfo.id, projectInfo.status)"-->
                  <v-chip
                    :class="`${projectInfo.status} white--text caption my-2 `"
                    small
                    >{{ projectInfo.status }}</v-chip
                  >
                </div>
              </v-col>
              <v-col cols="2" lg="1" xs="2">
                <div class="text-right">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn text icon v-on="on" color="grey">
                        <v-icon color="grey darken-1">mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item
                        v-for="link in optionLinks"
                        :key="link.title"
                        @click="trackAction(link.action, projectInfo.id)"
                      >
                        <v-list-item-title>{{ link.title }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </v-col>
              <v-col cols="6" xs="6" sm="6" md="2" class="mb-2">
                <div class="caption grey--text">Start Date</div>
                <div class="subheading">
                  {{ formattedDate(projectInfo.startDate) }}
                </div>
              </v-col>

              <v-col cols="6" xs="6" lg="2" class="mb-2">
                <div class="caption grey--text">Remaining Days</div>
                <div class="subheading">{{ projectInfo.remainingDays }}</div>
              </v-col>
              <v-col cols="4" xs="4" md="2">
                <div>
                  <v-progress-circular
                    :rotate="360"
                    :size="56"
                    :width="4"
                    :value="projectInfo.progress"
                    color="teal"
                    >{{ projectInfo.progress }}%</v-progress-circular
                  >
                </div>
              </v-col>
              <v-col cols="4" xs="4" md="2" class="text-center">
                <div class="caption grey--text">Bugs</div>
                <div class="subheading">
                  <v-btn text fab small>
                    {{ projectInfo.bugs }}
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="4" xs="4" md="2">
                <div class="caption grey--text">File(s)</div>
                <div>
                  <v-btn
                    text
                    fab
                    small
                    @click="downloadAttachment(projectInfo.attachment)"
                  >
                    <v-icon>mdi-file-download-outline</v-icon>
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="12" xs="12" md="12" class="mb-2">
                <div class="caption grey--text">Detail</div>
                <div v-html="projectInfo.detail"></div>
              </v-col>
            </v-row>
          </v-card>

          <v-row no-gutters>
            <v-col cols="12" xs="12" sm="12" md="12" lg="6">
              <v-card flat class="pa-2 mt-1">
                <div class="title pl-2">
                  <div class="d-inline">
                    Bugs
                    <AddBug :projectId="id" />
                  </div>
                  <div class="d-inline float-right pa-2">
                    <v-btn-toggle v-model="toggle_bugs">
                      <v-btn text small @click="bugsFilterBy = 'pending'"
                        >Pending</v-btn
                      >
                      <v-btn text small @click="bugsFilterBy = 'fixed'"
                        >Fixed</v-btn
                      >
                    </v-btn-toggle>
                  </div>
                </div>
                <div class="bug-list" v-bar>
                  <div class="el2">
                    <v-row v-if="filteredBugs.length < 1">
                      <v-col cols="12" class="text-center">
                        <v-card flat tile color="grey--text">
                          There is not item in list.</v-card
                        >
                      </v-col>
                    </v-row>
                    <v-list two-line>
                      <template v-for="(bug, index) in filteredBugs">
                        <v-subheader :key="`bug-${bug.id}`">
                          {{ bug.dateLabel }}
                          <v-chip small :class="`${bug.status}  ml-1`">{{
                            bug.status
                          }}</v-chip>
                          <v-chip
                            small
                            v-if="bug.attachment != ''"
                            class="attachment"
                            @click="downloadAttachment(bug.attachment)"
                          >
                            <v-icon>attachment</v-icon>
                          </v-chip>
                        </v-subheader>
                        <v-list-item :key="`bug-title-${bug.id}`">
                          <v-list-item-content
                            @click="viewBug(bug)"
                            class="pointer"
                          >
                            <div :class="`status-${bug.status}`">
                              <v-list-item-title
                                v-html="bug.title"
                              ></v-list-item-title>
                              <v-list-item-subtitle
                                v-html="bug.desc"
                              ></v-list-item-subtitle>
                            </div>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  icon
                                  ripple
                                  v-on="on"
                                  @click="updateBug(bug.id, bug.status)"
                                  :disabled="isValidStatus(bug.status)"
                                >
                                  <v-icon
                                    v-if="bug.status == 'fixed'"
                                    color="grey lighten-1"
                                    >mdi-check-all</v-icon
                                  >
                                  <v-icon
                                    v-else-if="bug.status == 'idle'"
                                    color="grey lighten-1"
                                    >mdi-delete-outline</v-icon
                                  >
                                  <v-icon
                                    v-else-if="bug.status == 'tested'"
                                    color="grey lighten-1"
                                    >mdi-check</v-icon
                                  >
                                </v-btn>
                              </template>
                              <span v-if="bug.status == 'idle'">Delete</span>
                              <span v-else>Mark as Fixed</span>
                            </v-tooltip>
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider
                          v-if="index < filteredBugs.length - 1"
                          :key="`bug-divider-${bug.id}`"
                        ></v-divider>
                      </template>
                    </v-list>
                  </div>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" lg="6" class="pl-lg-1">
              <v-card flat class="ml-lg-1 pa-2 mt-1">
                <div class="title pl-2 my-3">Attachments</div>
                <div class="bug-list" v-bar>
                  <div class="el2">
                    <v-row v-if="filesList.length < 1">
                      <v-col cols="12" class="text-center">
                        <v-card flat tile color="grey--text">
                          There is not item in list.</v-card
                        >
                      </v-col>
                    </v-row>
                    <v-list two-line>
                      <template v-for="(file, index) in filesList">
                        <v-subheader :key="`file-${index}`">{{
                          file.date
                        }}</v-subheader>

                        <v-list-item :key="`file-id-${index}`">
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="file.title"
                            ></v-list-item-title>
                            <v-list-item-subtitle
                              v-html="file.uploadedBy"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  fab
                                  small
                                  text
                                  v-on="on"
                                  @click="downloadAttachment(file.attachment)"
                                >
                                  <v-icon color="grey lighten-1"
                                    >mdi-file-download-outline</v-icon
                                  >
                                </v-btn>
                              </template>
                              <span>Download</span>
                            </v-tooltip>
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider
                          v-if="index < filesList.length - 1"
                          :key="`file-divider-${file.date}`"
                        ></v-divider>
                      </template>
                    </v-list>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <ChatBox :projectId="id" />
    </v-container>
  </div>
</template>
<script>
import axios from "axios";
import EventBus from "@/eventBus";
import AddBug from "@/components/client/AddBug";
import ViewBug from "@/components/client/ViewBug";
import ChatBox from "@/components/client/ChatBox";
import { format } from "date-fns";
const BASE_URL = process.env.VUE_APP_API_BASE_URL;
const SOURCE_URL = process.env.VUE_APP_API_SOURCE_URL;
export default {
  data() {
    return {
      id: 0,
      title: "Project Title",
      loading: false,
      message: "",
      projectInfo: {},
      userInfo: {},
      avatarSize: 60,
      tile: false,
      page: 1,
      bugList: [],
      action: "getSingle",
      totalItems: 0,
      optionLinks: [{ title: "Coming Soon", action: "coming" }],
      toggle_bugs: 0,
      bugsFilterBy: "pending",
      filesList: [],
      attachmentName: "Upload Files",
      isUploadedNewFile: false,
    };
  },
  components: { AddBug, ViewBug, ChatBox },

  computed: {
    filteredBugs() {
      const filterBy = this.bugsFilterBy.toLowerCase().trim();

      if (!filterBy) return this.bugList;
      if (filterBy == "pending") {
        return this.bugList.filter((c) => c.status.toLowerCase() != "fixed");
      } else {
        return this.bugList.filter((c) => c.status.toLowerCase() == "fixed");
      }
    },
  },
  created() {
    this.id = this.$route.params.id;
    console.log("Project Id:" + this.id);
    this.userInfo = this.$store.state.userlogged;
    this.loadProjectInfo(this.action);
    this.loadBugList("get");
    this.loadFiles("get");
    // this.loadComments("get");
    // window.addEventListener("focus", this.onWindowFocus);
    //window.addEventListener("blur", this.onWindowBlur);
    //this.onWindowFocus();
  },
  beforeDestroy() {
    //window.removeEventListener("focus", this.onWindowFocus);
    //window.removeEventListener("blur", this.onWindowBlur);
    //   clearInterval(this.updateTimer);
  },
  mounted() {
    EventBus.$on("project-update", (error) => {
      console.log(error);
      this.loadProjectInfo("getSingle");
    });
    EventBus.$on("bug-added", (error) => {
      console.log(error);
      this.loadBugList("get");
      this.loadProjectInfo("getSingle");
    });
    EventBus.$on("uploaded-newfile", (error) => {
      console.log(error);
      this.loadFiles("get");
    });
    console.log("mounted");
  },
  methods: {
    loadProjectInfo(action) {
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("clientId", this.userInfo.id);
      formData.append("id", this.id);
      console.log(this.userInfo.fname);
      axios
        .post(BASE_URL + "client/project.php?action=" + action, formData)
        .then(function (response) {
          vm.loading = false;
          console.log(response);
          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            vm.projectInfo = response.data.list;
            vm.title = vm.projectInfo.title;
            vm.totalItems = parseInt(response.data.totalItems);
          }
        })
        .catch(function (error) {
          vm.loading = false;
          console.log(error);
        });
    },
    loadBugList(action) {
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("projectId", this.id);

      axios
        .post(BASE_URL + "client/bug.php?action=" + action, formData)
        .then(function (response) {
          vm.loading = false;
          console.log(response);
          if (response.data.error) {
            vm.message = response.data.message;
            vm.bugList = [];
          } else {
            vm.bugList = response.data.list;
            vm.bugsFilterBy = "pending";
            vm.toggle_bugs = 0;
            // reload Project Info
            vm.loadProjectInfo(vm.action);
          }
        })
        .catch(function (error) {
          vm.loading = false;
          console.log(error);
        });
    },
    loadFiles(action) {
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("projectId", this.id);
      formData.append("clientId", this.userInfo.id);

      axios
        .post(BASE_URL + "client/file.php?action=" + action, formData)
        .then(function (response) {
          vm.loading = false;
          console.log(response);
          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            vm.filesList = response.data.list;
          }
        })
        .catch(function (error) {
          vm.loading = false;
          console.log(error);
        });
    },
    sortBy(prop) {
      this.projectInfo.sort((a, b) => (a[prop] < b[prop] ? -1 : 1));
    },
    trackAction(action, trackId) {
      console.log(action, trackId);
    },
    formattedDate(date) {
      if (date == "0000-00-00") {
        return "Not assigned yet";
      } else {
        return format(new Date(date), "dd MMM, yyyy");
      }
    },
    viewBug(data) {
      console.log(data.id);
      EventBus.$emit("view-bug", data);
    },
    updateBug(bugId, bugStatus) {
      const vm = this;
      var formData = new FormData();
      formData.append("bugId", bugId);
      formData.append("projectId", vm.id);
      formData.append("status", "3");
      var action = "update";
      if (bugStatus == "idle") {
        action = "delete";
      }
      axios
        .post(BASE_URL + "client/bug.php?action=" + action, formData)
        .then(function (response) {
          vm.loading = false;
          console.log(response);
          vm.message = response.data.message;
          if (!response.data.error) {
            vm.loadBugList("get");
          }
          EventBus.$emit("display-message", vm.message);
        })
        .catch(function (error) {
          vm.loading = false;
          console.log(error);
        });
    },
    downloadAttachment(filePath) {
      axios({
        method: "get",
        url: SOURCE_URL + filePath,
        responseType: "arraybuffer",
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const filename = filePath.split("/").pop();
          link.setAttribute("download", filename); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => console.log("error occured"));
    },
    isValidStatus(status) {
      if (status == "idle" || status == "tested") {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped>
.project {
  border-bottom: 1px solid #ccc;
}
.project.Idle {
  border-top: 4px solid orange;
}
.project.active {
  border-top: 4px solid #3dc1c2;
}
.project.inactive {
  border-top: 4px solid tomato;
}
.v-chip.Idle {
  background: orange;
}
.v-chip.active {
  background: #3dc1c2;
}
.v-chip.inactive {
  background: tomato;
}
.msg-con {
  height: 100ovh;
  min-height: 300px;
  display: block;
}
.bug-list {
  max-height: 500px;
  min-height: 100px;
  width: 100%;
}
.bug-list .status-fixed {
  text-decoration: line-through;
  opacity: 0.8;
}
.v-chip.working {
  background: #3dc1c2 !important;
}
.v-chip.idle {
  background: orange !important;
}
.v-chip.fixed {
  background: lightgray !important;
}
.msg-con {
  height: 500px;
  width: 100%;
}
.attachment {
  cursor: pointer;
}
.pointer {
  cursor: pointer;
}
/*.oddClass {
  text-align: -moz-right;
  text-align: -webkit-right;
}
.oddClass .message {
  width: fit-content;
  padding: 0.8rem;
  margin-bottom: 0.4rem;
  background-color: #3dc1c2;
}

.evenClass {
  text-align: -moz-left;
  text-align: -webkit-left;
}
*/

#chat-messages {
  display: block;
  transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
}
#chat-messages .label {
  clear: both;
}
#chat-messages div.message {
  padding: 0 0 30px 58px;
  clear: both;
  margin-bottom: 20px;
}
#chat-messages .message .img {
  float: left;
  margin-left: -38px;
  border-radius: 50%;
  width: 30px !important;
  height: 30px !important;
  margin-top: 12px;
}
.message .bubble {
  background: #f0f4f7;
  font-size: 13px;
  font-weight: 600;
  padding: 12px 13px;
  border-radius: 5px 5px 5px 0px;
  color: #8495a3;
  position: relative;
  float: left;
}
.message .date-time {
  font-size: 12px;
  display: block;
  clear: both;
  float: left;
}

#chat-messages div.message.right {
  padding: 0 58px 30px 0;
  margin-right: -19px;
  margin-left: 19px;
}
#chat-messages div.message.right .img {
  float: right;
  margin-left: 0;
  margin-right: -38px;
}
#chat-messages div.message.right .bubble {
  float: right;
  border-radius: 5px 5px 0px 5px;
  background: #dcffff;
}
#chat-messages div.message.right .date-time {
  float: right;
}

.vb > .vb-dragger {
  z-index: 5;
  width: 12px;
  right: 0;
}

.vb > .vb-dragger > .vb-dragger-styler {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: rotate3d(0, 0, 0, 0);
  transform: rotate3d(0, 0, 0, 0);
  -webkit-transition: background-color 100ms ease-out, margin 100ms ease-out,
    height 100ms ease-out;
  transition: background-color 100ms ease-out, margin 100ms ease-out,
    height 100ms ease-out;
  background-color: rgba(48, 121, 244, 0.1);
  margin: 5px 5px 5px 0;
  border-radius: 20px;
  height: calc(100% - 10px);
  display: block;
}

.vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.3);
}

.vb > .vb-dragger:hover > .vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.5);
  margin: 0px;
  height: 100%;
}

.vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.5);
  margin: 0px;
  height: 100%;
}

.vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.5);
}
</style>
