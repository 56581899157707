<template>
  <v-container fluid fill-height>
    <v-layout row wrap align-center justify-center>
      <v-col md="5" sm="12" lg="5" xl="4" xs="12">
        <v-card class="form-card">
          <v-card-title class="primary white--text">Welcome Back</v-card-title>
          <v-card-subtitle class="caption primary white--text"
            >Team login page</v-card-subtitle
          >
          <v-card-text class="pt-4">
            <div>
              <v-form v-model="valid" ref="form">
                <v-text-field
                  label="Enter your email"
                  v-model="username"
                  :rules="usernameRules"
                  v-on:keyup.enter="focusOnPassword"
                  required
                ></v-text-field>
                <v-text-field
                  label="Enter your password"
                  v-model="password"
                  min="8"
                  ref="password"
                  :append-icon="e1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="() => (e1 = !e1)"
                  :type="e1 ? 'password' : 'text'"
                  :rules="passwordRules"
                  counter
                  v-on:keyup.enter="submit"
                  required
                ></v-text-field>
                <v-layout justify-space-between>
                  <v-btn
                    @click="submit"
                    :class="{
                      'primary darken-2 white--text': valid,
                      disabled: !valid,
                    }"
                    :loading="loading"
                    >Login</v-btn
                  >
                  <span class="my-2 red--text">{{ message }}</span>
                </v-layout>
              </v-form>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-layout>
  </v-container>
</template>
<script>
import axios from "axios";
import EventBus from "@/eventBus";
const BASE_URL = process.env.VUE_APP_API_BASE_URL;
export default {
  data() {
    return {
      password: "",
      username: "",
      message: "",
      valid: false,
      usernameRules: [(v) => v.length >= 5 || "Minimum length is 5 characters"],
      passwordRules: [(v) => v.length >= 5 || "Minimum length is 5 characters"],
      loading: false,
      e1: true,
      authenticated: false,
      timezoneOffset: 0,
    };
  },
  methods: {
    focusOnPassword() {
      console.log("Enter presed");
      this.$refs.password.focus();
    },
    submit() {
      const vm = this;
      vm.loading = true;
      //  console.log(vm.username, vm.password, vm.timezoneOffset);
      var formData = new FormData();
      formData.append("email", vm.username);
      formData.append("password", vm.password);
      formData.append("timezone_offset", vm.timezoneOffset);
      axios
        .post(BASE_URL + "team/login.php", formData)
        .then(function (response) {
          vm.loading = false;
          console.log(response);
          if (!response.data.error) {
            EventBus.$emit("authenticated", true);
            vm.$store.dispatch("saveUserLogged", response.data.auth);
            vm.$router.replace({ name: "teamTodayTask" }).catch((err) => {
              console.log(err);
            });
          } else if (response.data.error) {
            vm.message = response.data.message;
          }
        })
        .catch(function (error) {
          vm.loading = false;
          console.log(error);
        });
    },
    setAuthenticated(status) {
      this.authenticated = status;
    },
    logout() {
      this.authenticated = false;
    },
  },
  mounted() {
    if (this.$store.state.userlogged != null) {
      if (this.$store.state.userlogged.id > 0) {
        this.authenticated = true;
        this.$router.replace({ name: "teamTodayTask" }).catch((err) => {
          console.log(err);
        });
      } else {
        this.authenticated = false;
      }
    }
  },
  created() {
    var timezone_offset_minutes = new Date().getTimezoneOffset();
    this.timezoneOffset =
      timezone_offset_minutes === 0 ? 0 : -timezone_offset_minutes;
  },
};
</script>
<style scoped>
.form-card {
  min-width: 400px;
}
</style>