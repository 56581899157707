<template>
  <nav>
    <v-snackbar v-model="snackbar" :timeout="4000" top color="warning">
      <span>{{ snackbarMessage }}</span>
      <v-btn text color="white" @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-app-bar app text class="custom-app-bar">
      <v-app-bar-nav-icon class="grey--text" @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="text-uppercase grey--text darken-2 pointer" @click="routeTo(dashboardLink, null)">
        <span>PM</span>
        <span class="font-weight-light">TOOL</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn text v-for="subLink in subLinks" :key="subLink.route" router :to="subLink.route">{{ subLink.text
          }}</v-btn>
      </v-toolbar-items>

      <v-spacer></v-spacer>

      <v-menu bottom offset-y origin="top center" transition="scale-transition">
        <template v-slot:activator="{ on }">
          <v-btn text icon v-on="on" color="grey">
            <v-badge overlap color="orange" v-model="hasNotificaitons">
              <template v-slot:badge small>
                <span class="small">{{ notificatonCount }}</span>
              </template>
              <v-icon color="grey darken-1">mdi-bell-outline</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <div>
          <div class="notification-list-title text-center">
            All Notifications
          </div>
          <div class="vue-scroll-bar-class" v-bar>
            <div class="notification-wrapper">
              <v-list three-line>
                <template v-for="(notification, index) in notificationList">
                  <v-list-item :key="index" class="pointer divider-class">
                    <v-list-item-avatar v-if="notification.userProfile" small color="orange">
                      <img :src="notification.userProfile.profileImage" v-if="notification.userProfile.profileImage"
                        :alt="notification.userProfile.name.substring(0, 1)" />
                      <span v-else class="white--text headline">{{
                        notification.userProfile.name.substring(0, 1)
                      }}</span>
                    </v-list-item-avatar>

                    <v-list-item-content @click="
                      onClickNotification(notification.url, notification.id)
                      " class="pointer">
                      <v-list-item-title v-html="notification.title"></v-list-item-title>
                      <v-list-item-subtitle v-text="notification.message"></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text v-text="notification.addedOn"></v-list-item-action-text>

                      <v-icon @click="
                        onClickNotification(notification.url, notification.id)
                        " color="grey lighten-1">
                        mdi-chevron-right
                      </v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-list>
            </div>
          </div>
          <div class="notification-list-footer">
            <v-btn block color="orange" :disabled="(notificatonCount < 1)" tile @click="onClickMarkAllRead()">Mark All
              As
              Read</v-btn>
          </div>
        </div>
      </v-menu>
      <v-divider vertical class="mx-3"></v-divider>

      <v-menu bottom offset-y transition="slide-y-transition">
        <template v-slot:activator="{ on }">
          <v-btn small fab icon class="blue-grey lighten-5" v-on="on">
            <v-avatar size="42">
              <img :src="userInfo.profileImage" alt v-if="userInfo.profileImage" />
              <span v-else class="title pa-2 blue--text font-weight-bold">{{
                userInfo.name.substring(0, 1)
              }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-list>
          <v-list-item key="ac-1" @click="editProfile()">
            <v-list-item-title>My Profile</v-list-item-title>
          </v-list-item>
          <v-list-item @click="signout()" key="ac-2">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer :clipped="false" v-model="drawer" app dark class="primary" enable-resize-watcher>
      <v-list dense nav class="py-0">
        <v-list-item two-line :class="'px-0'">
          <v-list-item-avatar color="white" class="my-0">
            <img :src="userInfo.profileImage" alt v-if="userInfo.profileImage" />
            <span v-else class="display-1 pa-2 blue--text font-weight-bold">{{
              userInfo.name.substring(0, 1)
            }}</span>
          </v-list-item-avatar>

          <v-list-item-content class="white--text">
            <v-list-item-title class="font-weight-bold subtitle-1">{{
              userInfo.name
            }}</v-list-item-title>
            <v-list-item-subtitle class="white--text pointer underline" @click="editProfile()">Manage
              Account</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>

      <!-- content for client -->
      <div class="white--text d-block primary darken-1" v-if="isClientOrManager()">
        <v-row no-gutters>
          <v-col cols="9" class="pa-2">
            <p class="mt-2 mb-0 font-weight-bold d-block pointer" @click="moveToDashboard()">
              Projects
            </p>
          </v-col>
          <v-col cols="3" class="pa-2">
            <AddProject v-if="userInfo.type == 3" iconColor="white" />
            <AddProjectMngr v-if="userInfo.type == 1" iconColor="white" />
          </v-col>
        </v-row>
      </div>
      <v-list dense v-if="userInfo.type == 3">
        <v-list-item v-for="project in projects" :key="project.id" @click="routeTo('clientProject', project.id)"
          active-class="highlighted" :class="project.id === currentProjectId ? 'highlighted' : ''">
          <v-list-item-icon class="mr-2">
            <v-icon>mdi-folder</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="white--text subtitle-2">{{
              project.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <!-- end content for client -->

      <!-- manager left side nav links --->
      <v-list v-if="userInfo.type == 1">
        <v-list-item v-for="(link, index) in managerLeftNav" :key="index" @click="routeTo(link.route, 0)"
          active-class="highlighted" :class="link.index === currentProjectId ? 'highlighted' : ''">
          <v-list-item-avatar>
            <v-icon color="white">{{ link.icon }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="white--text">{{
              link.text
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <!-- end manager left side nav link -->

      <!-- manager left side nav links --->
      <v-list v-if="userInfo.type == 2">
        <v-list-item v-for="(link, index) in teamLeftNav" :key="index" @click="routeTo(link.route, 0)"
          active-class="highlighted" :class="link.index === currentProjectId ? 'highlighted' : ''">
          <v-list-item-avatar>
            <v-icon color="white">{{ link.icon }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="white--text">{{
              link.text
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <!-- end manager left side nav link -->
    </v-navigation-drawer>
  </nav>
</template>

<script>
import axios from "axios";
import EventBus from "@/eventBus";
import AddProject from "@/components/client/AddProject";
import AddProjectMngr from "@/components/manager/AddProjectMngr";
import Pusher from "pusher-js";
const BASE_URL = process.env.VUE_APP_API_BASE_URL;
//const IMAGE_URL = process.env.VUE_APP_API_IMAGE_URL;
const PUSHER_KEY = process.env.VUE_APP_PUSHER_API_KEY;
const PUSHER_CLUSTER = process.env.VUE_APP_PUSHER_API_CLUSTER;
const PUSHER_NOTIFICATION_CHNL = process.env.VUE_APP_PUSHER_NOTIFICATION_CHNL;
const PUSHER_NOTIFICATION_EVENT = process.env.VUE_APP_PUSHER_NOTIFICATION_EVENT;
export default {
  data() {
    return {
      drawer: true,
      subLinks: [],
      userInfo: {},
      projects: [],
      managerLeftNav: [],
      snackbar: false,
      snackbarMessage: "",
      hasNotificaitons: true,
      notificatonCount: 0,
      notificationList: [],
      currentProjectId: 0,
      dashboardLink: "",
      pusherMessage: null,
      userProfile: {
        name: "none",
        profileImage: "",
      },
    };
  },
  components: { AddProject, AddProjectMngr },
  computed: {},
  created() {
    console.log(PUSHER_KEY);

    if (this.$store.state.userlogged != null) {
      this.userInfo = this.$store.state.userlogged;
      console.log("ProfileImage", this.userInfo.profileImage);

      if (this.userInfo.type == 3) {
        this.loadList(1, "getAll");
        this.dashboardLink = "clientDashboard";
      } else if (this.userInfo.type == 1) {
        this.dashboardLink = "managerDashboard";
      } else if (this.userInfo.type == 2) {
        this.dashboardLink = "teamDashboard";
      }

      this.managerLeftNav = [
        {
          icon: "mdi-view-dashboard-outline",
          text: "Dashboard",
          route: "managerDashboard",
        },
        {
          icon: "mdi-folder-outline",
          text: "Projects",
          route: "manageProjects",
        },
        {
          icon: "mdi-account-group-outline",
          text: "Team",
          route: "manageTeam",
        },
        { icon: "mdi-account-tie", text: "Client", route: "manageClient" },
        { icon: "mdi-currency-usd", text: "Payment", route: "managePayment" },
      ];
      this.teamLeftNav = [
        {
          icon: "mdi-calendar-today",
          text: "Today's Task",
          route: "teamTodayTask",
        },
        {
          icon: "mdi-calendar-multiselect",
          text: "Upcoming Task",
          route: "teamUpcomingTask",
        },
      ];

      this.loadNotifications();
    }
    this.initPuserApi();
  },
  mounted() {
    console.log("Navbar Mounted:", this.$store.state.userlogged.type);

    if (this.$store.state.userlogged != null) {
      this.userInfo = this.$store.state.userlogged;
      this.profileImage = this.userInfo.profileImage;
    }
    EventBus.$on("display-message", (message) => {
      this.displayMessage(message);
    });
    EventBus.$on("new-notification", (message) => {
      console.log(message);
      //  this.loadNotifications();
    });
    EventBus.$on("project-added", (message) => {
      console.log(message);
      if (this.userInfo.type == 3) {
        this.loadList(1, "getAll");
      }
    });
    EventBus.$on("highlight-item", (projectId) => {
      this.currentProjectId = projectId;
    });
    EventBus.$on("pofileUpdated", (userinfo) => {
      console.log("useInfo:" + JSON.stringify(userinfo));
      this.userInfo = userinfo;
      this.profileImage = this.userInfo.image;
      console.log("useInfo:" + this.userInfo.profileImage);
    });
  },
  methods: {
    getTodayDate() {
      return new Date().toUTCString().slice(5, 16);
    },
    isClientOrManager() {
      if (this.userInfo.type == 3 || this.userInfo.type == 1) {
        return true;
      } else {
        return false;
      }
    },
    routeTo(route, param) {
      this.currentProjectId = param;
      /* this.subLinks = [
        { text: "Dashboard", route: "/client/dashboard" },
        { text: "Bugs", route: "/musicAds" },
        { text: "Discussion", route: "/radioAds" },
        { text: "Attachments", route: "/newsAds" }
      ];*/
      console.log(route);
      this.$router
        .replace({ name: route, params: { id: param } })
        .catch((err) => {
          console.log(err);
        });
    },
    onClickMarkAllRead() {
      const vm = this;
      var url = "client/notification.php?action=markAllRead";
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("userId", this.userInfo.id);
      if (this.userInfo.type == 3) {
        url = "client/notification.php?action=markAllRead";
      } else if (this.userInfo.type == 1) {
        url = "manager/notification.php?action=markAllRead";
      } else if (this.userInfo.type == 2) {
        url = "team/notification.php?action=markAllRead";
      }

      console.log(this.userInfo.fname);

      axios
        .post(BASE_URL + url, formData)
        .then(function (response) {
          vm.loading = false;
          console.log("Notification status update:" + response.data.message + ", userId: " + vm.userInfo.id + ", url:" + url);
          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            vm.notificatonCount = response.data.count;
            vm.notificationList = response.data.list;
          }
        })
        .catch(function (error) {
          vm.loading = false;
          console.log(error);
        });
    },
    onClickNotification(routePath, notificationId) {
      const vm = this;
      var url = "client/notification.php?action=update";
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("userId", this.userInfo.id);
      formData.append("id", notificationId);
      if (this.userInfo.type == 3) {
        url = "client/notification.php?action=update";
      } else if (this.userInfo.type == 1) {
        url = "manager/notification.php?action=update";
      } else if (this.userInfo.type == 2) {
        url = "team/notification.php?action=update";
      }

      console.log(this.userInfo.fname);

      axios
        .post(BASE_URL + url, formData)
        .then(function (response) {
          vm.loading = false;
          console.log("Notification status update:" + response.data.message);
          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            vm.notificatonCount = response.data.count;
            vm.notificationList = response.data.list;
          }
        })
        .catch(function (error) {
          vm.loading = false;
          console.log(error);
        });
      console.log(routePath);
      this.$router.replace(routePath).catch((err) => {
        console.log(err);
      });
    },
    moveToDashboard() {
      console.log("MoveToDashboard");

      this.routeTo("clientDashboard", null);
    },
    displayMessage(message) {
      this.snackbar = true;
      this.snackbarMessage = message;
    },
    signout() {
      EventBus.$emit("authenticated", false);
    },
    loadList(page, action) {
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("clientId", this.userInfo.id);
      console.log(this.userInfo.fname);

      axios
        .post(
          BASE_URL + "client/project.php?page=" + page + "&action=" + action,
          formData
        )
        .then(function (response) {
          vm.loading = false;
          console.log(response);
          vm.notificationList = [
            {
              title: "No Notification",
              message: "There is no notification in list.",
              userProfile: vm.userProfile,
              projectId: "",
              addedOn: vm.getTodayDate(),
            },
          ];
          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            vm.projects = response.data.list;
            // vm.currentProjectId = vm.projects[0].id;

            if (response.data.notiCount > 0) {
              vm.notificatonCount = response.data.notiCount;
              vm.notificationList = response.data.notiList;
            }
          }
        })
        .catch(function (error) {
          vm.loading = false;
          console.log(error);
        });
    },
    editProfile() {
      if (this.userInfo.type == 3) {
        this.routeTo("clientProfile", null);
      } else if (this.userInfo.type == 1) {
        this.routeTo("managerProfile", null);
      } else if (this.userInfo.type == 2) {
        this.routeTo("teamProfile", null);
      }
    },
    loadNotifications() {
      const vm = this;
      var url = "client/notification.php?action=get";
      var formData = new FormData();

      formData.append("ran", Math.random() * 10);
      formData.append("userId", this.userInfo.id);
      if (this.userInfo.type == 3) {
        url = "client/notification.php?action=get";
      } else if (this.userInfo.type == 1) {
        url = "manager/notification.php?action=get";
      } else if (this.userInfo.type == 2) {
        url = "team/notification.php?action=get";
      }

      console.log(this.userInfo.fname);

      axios
        .post(BASE_URL + url, formData)
        .then(function (response) {
          vm.loading = false;
          console.log(response);
          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            vm.notificatonCount = response.data.count;
            vm.notificationList = response.data.list;
          }
        })
        .catch(function (error) {
          vm.loading = false;
          console.log(error);
        });
    },
    notifyData(message) {
      /*  if (!("Notification" in window)) {
        alert("This browser does not support desktop notification");
      }

      Notification.requestPermission().then(function(result) {
        if (result === "granted") {
          let notification = new Notification(message);
          console.log(notification);

        }
      });*/

      console.log(message);
    },
    initPuserApi() {
      console.log("PUSHER_API: ", PUSHER_KEY);

      const vm = this;
      // Enable pusher logging - don't include this in production
      Pusher.logToConsole = true;
      var pusher = new Pusher(PUSHER_KEY, {
        cluster: PUSHER_CLUSTER,
      });
      var channel = pusher.subscribe(PUSHER_NOTIFICATION_CHNL);
      channel.bind(PUSHER_NOTIFICATION_EVENT, function (data) {
        vm.pusherMessage = data;
        //const routeName = vm.$router.currentRoute.name;
        console.log(data);
        if (vm.pusherMessage.notification.for == vm.userInfo.type) {
          if (vm.pusherMessage.notification.assignTo == vm.userInfo.id) {
            vm.loadNotifications();
            //vm.notificatonCount++;
            if (vm.pusherMessage.notification.type == "task") {
              if (vm.pusherMessage.notification.for == "1") {
                if (vm.pusherMessage.notification.action == "update") {
                  // for manager
                  EventBus.$emit("task-update", true);
                }
              } else if (vm.pusherMessage.notification.for == "2") {
                // for team
                if (vm.pusherMessage.notification.action == "new") {
                  EventBus.$emit("new-task", true);
                } else {
                  EventBus.$emit("task-update", true);
                }
              }
              //  vm.loadNotifications();
              /*,
                vm.pusherMessage.notification.type,
                vm.pusherMessage.notification.action*/
              vm.showNotification(
                vm.pusherMessage.notification.title,
                vm.pusherMessage.notification.message,
                vm.pusherMessage.notification.icon
              );
            } else if (vm.pusherMessage.notification.type == "bug") {
              EventBus.$emit("new-bug", true);

              if (vm.pusherMessage.notification.for == "1") {
                if (vm.pusherMessage.notification.action == "update") {
                  // for manager
                  EventBus.$emit("bug-update", true);
                }
              } else if (vm.pusherMessage.notification.for == "2") {
                // for team
                if (vm.pusherMessage.notification.action == "new") {
                  EventBus.$emit("new-bug", true);
                } else {
                  EventBus.$emit("bug-update", true);
                }
              }
              vm.showNotification(
                vm.pusherMessage.notification.title,
                vm.pusherMessage.notification.message,
                vm.pusherMessage.notification.icon
              );
            } else if (vm.pusherMessage.notification.type == "project") {
              EventBus.$emit("project-added", true);
              vm.showNotification(
                vm.pusherMessage.notification.title,
                vm.pusherMessage.notification.message,
                vm.pusherMessage.notification.icon
              );
            }
          }
          // vm.notifyData(vm.pusherMessage);
        }
        console.log(vm.pusherMessage);
      });
    },
    showNotification(title, message, profileImage) {
      const notification = new Notification(title, {
        body: message,
        icon: profileImage,
      });
      console.log(notification);
    },
  },
};
</script>
<style scoped>
.v-toolbar__extension {
  height: 5px !important;
}

.highlighted {
  background: #005aaf;
}

.underline {
  text-decoration: underline;
}

.custom-app-bar {
  background: #ffffff !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) !important;
  max-height: 60px;
}

.pointer {
  cursor: pointer;
}

.notification-wrapper {
  background-color: white;
}

.vue-scroll-bar-class {
  height: 362px;
  min-height: 100px;
  display: block;
  width: 104%;
  max-width: 400px;
}

.vue-scroll-bar-class .status-fixed {
  text-decoration: line-through;
  opacity: 0.8;
}

.vb>.vb-dragger {
  z-index: 5;
  width: 12px;
  right: 0;
}

.vb>.vb-dragger>.vb-dragger-styler {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: rotate3d(0, 0, 0, 0);
  transform: rotate3d(0, 0, 0, 0);
  -webkit-transition: background-color 100ms ease-out, margin 100ms ease-out,
    height 100ms ease-out;
  transition: background-color 100ms ease-out, margin 100ms ease-out,
    height 100ms ease-out;
  background-color: rgba(48, 121, 244, 0.1);
  margin: 5px 5px 5px 0;
  border-radius: 20px;
  height: calc(100% - 10px);
  display: block;
}

.vb.vb-scrolling-phantom>.vb-dragger>.vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.3);
}

.vb>.vb-dragger:hover>.vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.5);
  margin: 0px;
  height: 100%;
}

.vb.vb-dragging>.vb-dragger>.vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.5);
  margin: 0px;
  height: 100%;
}

.vb.vb-dragging-phantom>.vb-dragger>.vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.5);
}

.divider-class {
  border-bottom: 1px solid lightgrey;
}

.notification-list-footer {
  background: #ffffff;
  width: 100%;
}

.notification-list-title {
  height: 34px;
  width: 100%;
  background: #ffe0b2;
  display: block;
  font-weight: 500;
  font-size: 1rem;
  color: #444;
  text-transform: uppercase;
  padding: 5px;
}
</style>
